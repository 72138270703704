import React, { useState } from "react";
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import ContentArea from "../../../shared/ContentArea";
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from "primereact/button";
import { addSubscription, editDescription, getSubscriptionById } from "../../../utils/action";
import { toast } from "react-toastify";
import { useNavigate, useParams,useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Loading } from "../../../components/Loader/Loading";
import { useEffect } from "react";
toast.configure()
export default function AddSubscriptionPlan(props) {
    const navigate = useNavigate()
    const location = useLocation() 
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState({
        program_name: "",
        description: "",
        price_per_person: ""
    })
    const [error, setError] = useState({
        program_name: "",
        description: "",
        price_per_person: ""
    })
    const handleSubmit = async (e) => {
        setIsLoading(true)
        try {
            setIsLoading(false)
            if (data.program_name === "" || data.description === "" || data.price_per_person === "") {
                setError({
                    ...error,
                    program_name: data.program_name === "" ? "Please Enter Program Name" : error.program_name,
                    description: data.description === "" ? "PLease Enter Description" : error.description,
                    price_per_person: data.price_per_person === "" ? "Please Enter Price" : error.price_per_person
                })
                return false;
            }
            let response = await addSubscription({ payload: data })
            if (response.status === false) {
                setIsLoading(false)
                toast.error(response.message || "Something went wrong")
            } else if (response.status === true) {
                setIsLoading(false)
                toast.success(response.message ||"Subscription Plan created Successfully")
                setData({
                    program_name: "",
                    description: "",
                    price_per_person: ""
                })
                navigate("/subscription")
                return response;
            }
        } catch (err) {
            setIsLoading(false)
            console.log(err)
            let message = err && err.message ? err.message : "Something Went Wrong during login"
            toast.error(err?.response?.data?.message);
            return false;
        }
    }
    const handleCancel = () => {
        navigate("/subscription")
    }
    const { id } = useParams()
    useEffect(() => {
        async function fetchMyAPI() {
            setIsLoading(true)
            let response = await getSubscriptionById(id);
            setIsLoading(false);
            setData(response.data[0])
        }
        fetchMyAPI();
    }, [])
    console.log(data,"==data===")
    const handleEditSubmit = async (e) => {
        setIsLoading(true)
        try {
            setIsLoading(false)
            if (data.program_name === "" || data.description === "" || data.price_per_person === null) {
                setError({
                    ...error,
                    program_name: data.program_name === "" ? "Please Enter Program Name" : error.program_name,
                    description: data.description === "" ? "PLease Enter Description" : error.description,
                    price_per_person: data.price_per_person === null ? "Please Enter Price" : error.price_per_person
                })
                return false;
            }
            let response = await editDescription({ payload: data, id: id })
            if (response.status === false) {
                setIsLoading(false)
                toast.error(response.message || "Something went wrong")
            } else if (response.status === true) {
                setIsLoading(false)
                toast.success(response.message||"Subscription Plan update Successfully")
                setData({
                    program_name: "",
                    description: "",
                    price_per_person: ""
                })
                navigate("/subscription")
                return response;
            }
        } catch (err) {
            setIsLoading(false)
            console.log(err)
            let message = err && err.message ? err.message : "Something Went Wrong during login"
            toast.error(err?.response?.data?.message);
            return false;
        }
    }
    const handleAddEdit = ()=>{
        let value =  location.pathname.includes("/edit-subscription")
        if(value ===  true){
            return handleEditSubmit();
        }else{
            return handleSubmit()
        }
    }
    return (
        <ContentArea>
            {isLoading ? <Loading /> :
                <div className="p-3">
                    <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                        <h2 className="text-main">
                            {location?.pathname?.includes("add-subscription") ? "Add Subscription Plan ": "Edit Subscription Plan"}
                        </h2>
                    </div>
                    <div className="shadow-2 p-5 border-round-lg">
                        <div className="p-fluid grid mb-4">
                            <div className="col-12 md:col-6">
                                <label className="font block mb-2">Program Name</label>
                                <InputText
                                    inputClassName="border-round-md"
                                    placeholder=""
                                    name="program_name"
                                    value={data?.program_name}
                                    onChange={(e) => {
                                        setError({
                                            ...error,
                                            program_name: null,
                                        });
                                      setData({
                                        ...data,
                                        program_name:e.target.value
                                      })                     
                                    }}
                                />
                                {error.program_name && <span className="error">{error.program_name}</span>}
                            </div>
                            <div className=" col-12 md:col-6">
                                <label className="font block mb-2">Price Per Person</label>
                                <InputNumber
                                    className="border-round-md"
                                    placeholder=""
                                    inputId="currency-us"
                                    name="price_per_person"
                                    value={data?.price_per_person}
                                    useGrouping={false}
                                    onValueChange={(e) => {
                                        setError({
                                            ...error,
                                            price_per_person: null,
                                        });
                                      setData({
                                        ...data,
                                        price_per_person:e.value
                                      })                     
                                    }}
                                />
                                {error.price_per_person && <span className="error">{error.price_per_person}</span>}
                            </div>
                        </div>
                        <div className="p-fluid grid mb-4">
                            <div className="col-12 md:col-6">
                                <label className="font block mb-2">Description</label>
                                <InputTextarea
                                    className="border-round-md"
                                    placeholder=""
                                    name="description"
                                    value={data?.description}
                                    rows={4}
                                    onChange={(e) => {
                                        setError({
                                            ...error,
                                            description: null,
                                        });
                                      setData({
                                        ...data,
                                        description:e.target.value
                                      })                     
                                    }}

                                />
                                {error.description && <span className="error">{error.description}</span>}
                            </div>
                        </div>
                        <div className=" btn flex">
                            <div className="text-center">
                                <Button
                                    label="Save"
                                    className="w-full p-3 px-5  border-0 border-round-md bg-main"
                                    onClick={handleAddEdit}
                                />
                            </div>
                            <div className="text-center">
                                <Button
                                    label="Cancel"
                                    className="w-full surface-500 p-3  ml-5 border-0 border-round-md "
                                    onClick={handleCancel}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </ContentArea>
    )
}
