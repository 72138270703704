import { React, useEffect, useState } from "react";
import ContentArea from "../../../shared/ContentArea";
import SearchIcon from "../../../assets/icons/search.png";
import EyeIcon from "../../../assets/icons/eyeicon.png";
import Table from "../../../components/TableList/Table";
import { Button } from "primereact/button";
import { getSubscription, getSubscriptionReport } from "../../../utils/action";
import Index from "../Index";
import ViewReports from "./ViewReports";
import { Calendar } from "primereact/calendar";
import FilterIcon from "../../../assets/icons/filter.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loading } from "../../../components/Loader/Loading";
import Totalclient from "../../../assets/images/totalclient.png";
import Totalrevenge from "../../../assets/images/totalrevenge.png";
import SalesPerson from "../../../assets/images/salesperson.png";
import MonthRevenue from "../../../assets/images/revenumonth.png";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
toast.configure();
export default function SubscriptionReport() {
  const [isShowFilter, setIsShowFilter] = useState(false);
  const { isNextPage, setIsNextPage } = Index();
  const [viewButton, setViewButton] = useState(false);
  const [editButton, setEditButton] = useState(false);
  const [subscription, setSubscription] = useState()
  const [tableData, setTableData] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const[programName,setProgramName] = useState()
  const [filterData, setFilterData] = useState({
    start_date: "",
    end_date: "",
    plan_name:""
  })
  const[products,setProducts] = useState([])
  const [isFiltered, setIsFiltered] = useState(false);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    is_active: { value: null, matchMode: FilterMatchMode.EQUALS },
    dob: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
  });
  const handleSubmit = async (data) => {
    setFilterData({
      ...filterData,
      plan_name:data
    })
    setIsLoading(true)
    setProgramName(data)
    let response = await getSubscriptionReport({plan_name:data})
    if (response.status === false) {
      setIsLoading(false)
      toast.error(response.message || "Something went Wrong");
    }
    if (response.status === true) {
      setIsLoading(false)
      response.data.map((item) => {
        let date = new Date(item.created_at)
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let dt = date.getDate();
        if (dt < 10) {
          dt = '0' + dt;
        }
        if (month < 10) {
          month = '0' + month;
        }
        item.created_at = dt + '-' + month + '-' + year;
      })
      setTableData(response.data)
    }
  }
  const applyfilter = async () => {
    
    setFilterData({
      ...filterData,
      plan_name:programName
    })
    setIsLoading(true)
    setIsShowFilter(!isShowFilter)
    const formData = new FormData();
    formData.append("plan_name",programName);
    formData.append("start_date",filterData.start_date);
    formData.append("end_date",filterData.end_date);
    let response = await getSubscriptionReport( formData )
    console.log(response,"==response====")
    setIsFiltered(true)
    setIsLoading(false)
    response.data.map((item) => {
      let date = new Date(item.created_at)
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();
      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
      item.created_at = dt + '-' + month + '-' + year;
    })
    setTableData(response.data.reverse())
  }
  const Clearfilter = async () => {
    setIsLoading(true)
    setIsFiltered(false)
    setIsShowFilter(false)
    const formData = new FormData()
    formData.append("plan_name",programName)
    let res = await getSubscriptionReport(formData)
    setIsLoading(false)
    res.data.map((item) => {
      let date = new Date(item.created_at)
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();
      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
      item.created_at = dt + '-' + month + '-' + year;
    })
    setTableData(res.data.reverse())
  }
  const tableHeading = [
    { field: "srno", header: "Sr. No.", body: "srnoTemplates" },
    { field: "created_at", header: "Date" },
    { field: "client.profile_id", header: "Client Id" },
    { field: "client.name", header: "Client Name " },
    { field: "client.company_name", header: "Company Name" },
    { field: "subscription_plan.program_name", header: "Program Name" },
    { field: "subscription_plan.price_per_person", header: "Price" },
    { field: "status", header: "Status" },
    { field: "icons", header: "Action", body: "eyeClientBodyTemplate" },
  ];
  useEffect(() => {
    async function fetchMyAPI() {
      let response = await getSubscription()
      setSubscription(response.data)
    }
    fetchMyAPI();
  }, [])
  useEffect(() => {
    setIsLoading(true)
    if(subscription)
      setProgramName(subscription[0].program_name )
    async function fetchMyAPI() {
      let response = await getSubscriptionReport({ plan_name: subscription[0].program_name })
      response.data.map((item) => {
        let date = new Date(item.created_at)
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let dt = date.getDate();
        if (dt < 10) {
          dt = '0' + dt;
        }
        if (month < 10) {
          month = '0' + month;
        }
        item.created_at = dt + '-' + month + '-' + year;
      })
      setTableData(response.data)
      setIsLoading(false)
    }
    fetchMyAPI()
  }, [subscription])
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const handleChange = (e) => {
    let date = new Date(e.target.value)
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();
    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }
    let dob = year + '-' + month + '-' + dt;
    setFilterData({
      ...filterData,
      [e?.target?.name]: dob,
    });
  };
  useEffect(() => {
    let object 
    let array = []
    tableData?.length>0&& tableData.map((item,index)=>{
      object ={
        SrNo:index+1,
        Date:item.created_at,
        ClientId:item?.client?.profile_id,
        ClientName:item?.client?.name,
        CompanyName:item?.client?.company_name,
        ProgramName:programName,
        Price:item?.subscription_plan?.price_per_person,
        Status:item?.status
      }
      array.push(object)
    })
    setProducts(array)
}, [tableData]); 

const exportExcel = () => {
  import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(products);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
          bookType: 'xlsx',
          type: 'array'
      });

      saveAsExcelFile(excelBuffer, 'products');
  });
};
const saveAsExcelFile = (buffer, fileName) => {
  import('file-saver').then((module) => {
      if (module && module.default) {
          let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          let EXCEL_EXTENSION = '.xlsx';
          const data = new Blob([buffer], {
              type: EXCEL_TYPE
          });

          module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      }
  });
};
  return (
    <>
      {isLoading === true ? <Loading /> :
        <>
          {!isNextPage & !editButton & !viewButton ? (
            <ContentArea>
              <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-evenly ">
                <div className="md:col-7 ">
                  <h1 className="text-main   Karla" style={{ fontSize: "24px" }}>
                    Subscription Report
                  </h1>
                </div>
                <div className="md:col-4">
                </div>
              </div>
              <div className="grid mb-3">
                {subscription?.length > 0 && subscription?.map((item) => {
                  return (
                    <div className="md:col-3 ">
                      <Button className={programName === item.program_name ?"bg-main shadow-2 w-full border-0  p-3 border-round-lg":"shadow-2 border-0  bg-white text-color w-full  p-3 border-round-lg"} onClick={(e) => handleSubmit(item.program_name)}>
                        {item.program_name}
                      </Button>
                    </div>
                  )
                })}

              </div>
              <div className="grid mb-3 ">
                <div className="col-3">
                  <div className="border-round-2xl border-none shadow-none bg-seagreen p-3 h-full ">
                    <div className="relative">
                      <h3 className="font-normal mb-0 text-heading karla">
                        Total Clients
                      </h3>
                      <h1 className="text-4xl font-bold mb-0">75</h1>
                      <img
                        src={Totalclient}
                        className="absolute right-0 bottom-0"
                        alt=""
                        width={60}
                        height={60}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="border-round-2xl border-none shadow-none p-3 bg-lightred h-full">
                    <div className="relative">
                      <h3 className="font-normal mb-0 text-heading karla">
                        Total Sales Person
                      </h3>
                      <h1 className="text-4xl font-bold mb-0">25</h1>
                      <img
                        alt=""
                        src={SalesPerson}
                        className="absolute right-0 bottom-0"
                        width={62.94}
                        height={55}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="border-round-2xl border-none shadow-none p-3 bg-lightpirple h-full">
                    <div className="relative">
                      <h3 className="font-normal mb-0 text-heading karla">
                        Current Month Revenue
                      </h3>
                      <h1 className="text-4xl font-bold mb-0"> $250 </h1>
                      <img
                        alt=""
                        src={MonthRevenue}
                        className="absolute right-0 bottom-0"
                        width={45}
                        height={65}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="border-round-2xl border-none shadow-none p-3 bg-lightorange h-full">
                    <div className="relative">
                      <h3 className="font-normal mb-0 text-heading karla">
                        Total Revenue
                      </h3>
                      <h1 className="text-4xl font-bold mb-0">$1,250</h1>
                      <img
                        alt=""
                        src={Totalrevenge}
                        className="absolute right-0 bottom-0"
                        width={63.29}
                        height={60}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid align-items-center justify-content-end">
                <div className="md:col-6 font-bold text-2xl text-main ">
                Client List
                </div>
             
                <div className="md:col-4">
                  <div className="search-box w-full ">
                    <img
                      src={SearchIcon}
                      alt="Search Icon"
                      width="15px"
                      height="15px"
                    />
                    <input type="search"  placeholder="Search" onChange={onGlobalFilterChange} onClick={()=>setIsShowFilter(false)} />
                  </div>
                </div>
                <div className="md:col-1 relative bg-main px-3 border-round-md w-max ">
                  {
                    isFiltered ?
                      <div onClick={() => Clearfilter()} className="bg-main border-round-md text-white text-center text-base relative karla">
                        Clear &nbsp;
                        <i className="pi pi-filter-slash"></i></div>
                      :
                      <div onClick={() => setIsShowFilter(prev => !prev)} className="border-round-md text-white text-center text-base relative karla">
                        Filter &nbsp;
                        <img src={FilterIcon} alt="" width={15} /></div>
                  }
                  {
                    isShowFilter ?
                      <div className="filter-popup">
                        <label className="font block mb-2">
                          Start Date<code className="text-red"></code>
                        </label>
                        <Calendar
                          className="border-round-md calender_picker "
                          inputClassName="calendar p-2 border-round-md "
                          placeholder="Select Date"
                          name="start_date"
                          onChange={(e) => handleChange(e)}
                        />
                        <label className="font block mb-2 mt-2">
                          End Date<code className="text-red"></code>
                        </label>
                        <Calendar
                          className="border-round-md calender_picker "
                          inputClassName="calendar p-2 border-round-md "
                          placeholder="Select Date"
                          name="end_date"
                          onChange={(e) => handleChange(e)}
                        />
                        <div className="mt-3 flex justify-content-between">
                        <Button onClick={(e) => applyfilter(e)} label="Apply" className="bg-main w-full" />
                      </div>
                      </div>
                      : null
                  }
                </div>
                <div className="md:col-1">
                <Button type="button" label="Export" className="bg-main karla px-4 border-round-md" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
                </div>
              </div>
              <div className="grid mt-2">
                <div className="md:col-12">
                  <Table
                    tableHeading={tableHeading}
                    tableData={tableData}
                    setViewButton={setViewButton}
                    title="subscriptionReport"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </div>
              </div>
            </ContentArea>
          ) : null}
          {viewButton ? (
            <ViewReports
              title="Subscription Details"
              header="Subscription Report"
            />
          ) : null}
        </>
      }

    </>
  );
}
