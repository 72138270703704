import jwtDecode from "jwt-decode";
import { useNavigate, Outlet } from "react-router-dom";

export const ProtectedRoute = () => {
    const navigate =  useNavigate()
    if (localStorage.getItem("token")) {
        setTimeout(()=> navigate("/admin-dashboard"),500)    
    }
  
};

