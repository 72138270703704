import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import ContentArea from "../../../shared/ContentArea";
import Table from "../../../components/TableList/Table";
import FileIcon from "../../../assets/icons/fileicon.png";
import CloseIcon from "../../../assets/icons/close.png";
import { Card } from "primereact/card";
import { InputSwitch } from "primereact/inputswitch";
import { editSalesPerson, viewSalesPerson, getCarrier, ViewDiscount, deletePdf } from "../../../utils/action";
import { Loading } from "../../../components/Loader/Loading";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  validatePhoneNumber,
  validateZipcodeNumber,
  validEmail
} from "../../../shared/Validation";
import { Dropdown } from "primereact/dropdown";
toast.configure();
export default function EditSalesPerson(props) {
  const [isloading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [carrier, setCarrier] = useState("")
  const [selectedCarrier, setSelectedCarrier] = useState(null)
  const [selectedDiscount, setSelectedDiscount] = useState("")
  const [tableData, setTableData] = useState("")
  const [data, setData] = useState({
    sales_person_id: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    carrier: "",
    mobile: "",
    dob: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    zipcode: "",
    member_since: "",
    discount: "",
    is_active: "",
    files: []
  });
  const [error, setError] = useState({
    sales_person_id: "",
    first_name: "",
    last_name: "",
    email: "",
    carrier: "",
    mobile: "",
    dob: "",
    address_1: "",
    city: "",
    state: "",
    zipcode: "",
    member_since: "",
    discount: "",
  });
  const [discount, setDiscount] = useState("")
  const { id } = useParams();
  const [IsId, setId] = useState({
    sales_person_id: "",
  });
  const onUpload = () => {
    toast.current.show({
      severity: "info",
      summary: "Success",
      detail: "File Uploaded",
    });
  };
  const handleViewData = async () => {
    setIsLoading(true)
    let response = await viewSalesPerson({ payload: IsId });
    if (response.data[0].is_active) {
      setChecked(true);
    }
    response.data[0].middle_name =
      response.data[0].middle_name === null ? "" : response.data[0].middle_name;
    response.data[0].dob = new Date(response.data[0].dob.slice(0, 10));
    response.data[0].member_since = new Date(
      response.data[0].member_since.slice(0, 10)
    );
    setData(response.data[0]);
    setIsLoading(false)
  };
  useEffect(() => {
    handleViewData();
  }, []);
  useEffect(() => {
    async function fetchMyAPI() {
      let response = await getCarrier();
      setCarrier(response.data)
    }
    fetchMyAPI();
  }, [])
  useEffect(() => {
    async function fetchMyAPI() {
      let response = await ViewDiscount();
      setDiscount(response.data)
    }
    fetchMyAPI();
  }, [])
  useEffect(() => {
    carrier?.length > 0 && carrier.map((item) => {
      if (item.id == data.carrier) {
        setSelectedCarrier(item)
      }
    })


  }, [carrier])
  useEffect(() => {
    discount.length > 0 && discount.map((item) => {
      if (item.id == data.discount_id) {
        setSelectedDiscount(item)
      }
    })
  }, [discount])
  useState(() => {
    setId({
      sales_person_id: id,
    });
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setChecked(e);
    setData({
      ...data,
      [e?.target?.name]: e?.target?.value,
    });
    setError({
      ...error,
      [e?.target?.name]: e?.value,
    });
  };
  let date = new Date(data.dob)
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();
  if (dt < 10) {
    dt = '0' + dt;
  }
  if (month < 10) {
    month = '0' + month;
  }
  let dob = year + '-' + month + '-' + dt;
  let dates = new Date(data.member_since)
  let years = dates.getFullYear();
  let months = dates.getMonth() + 1;
  let dts = dates.getDate();
  if (dts < 10) {
    dts = '0' + dts;
  }
  if (months < 10) {
    months = '0' + months;
  }
  let member = years + '-' + months + '-' + dts;
  const handleSubmit = async (e) => {
    let file = data.files
    setIsLoading(true);
    try {
      setIsLoading(false);
      e.preventDefault();
      if (
        data.first_name === "" ||
        data.last_name === "" ||
        data.email === "" ||
        data.carrier === "" ||
        data.mobile === "" ||
        data.address_1 === "" ||
        data.city === "" ||
        data.state === "" ||
        data.zipcode === "" ||
        data.dob === null ||
        data.member_since === null ||
        data.discount === "" ||
        !validatePhoneNumber(data.mobile) ||
        !validateZipcodeNumber(data.zipcode) ||
        data.commission === null || !validEmail(data.email) ||tableData?.length === 0
      ) {
        setError({
          ...error,
          first_name:
            data.first_name === ""
              ? "Please Enter First Name"
              : error.first_name,
          last_name:
            data.last_name === "" ? "Please Enter Last Name" : error.last_name,
          email: data.email === "" ? "Please Enter Email" : !validEmail(data.email)?"Please Enter Valid Email": error.email,
          carrier: data.carrier === "" ? "Please Enter Carrier" : error.carrier,
          mobile:
            data.mobile === ""
              ? "Please Enter Mobile"
              : !validatePhoneNumber(data.mobile)
                ? "Please Enter Valid Phone Number"
                : error.mobile,
          address_1:
            data.address_1 === "" ? "Please Enter Address" : error.address_1,
          city: data.city === "" ? "Please Enter City" : error.city,
          state: data.state === "" ? "Please Enter State" : error.state,
          zipcode:
            data.zipcode === ""
              ? "Please Enter ZipCode"
              : !validateZipcodeNumber(data.zipcode)
                ? "Please Enter Valid ZipCode"
                : error.zipcode,
          member_since:
            data.member_since === ""
              ? "Please Enter Member_since"
              : error.member_since,
          discount:
            data.discount === "" ? "Please Enter Discount" : error.discount,
          dob: data.dob === null ? "Please Enter Date" : error.dob,
            commission:data.commission ===null?"Please Enter Commision":error.commission,
            files:tableData?.length === 0?"Please Select File":error.files
        });
        return false;
      }
      const formData = new FormData();
      tableData.map((item) => {
        formData.append("files[]", item.binaryKey)
      })
      formData.append("sales_person_id", data.id);
      formData.append("first_name", data.first_name);
      formData.append("middle_name", data.middle_name);
      formData.append("last_name", data.last_name);
      formData.append("email", data.email);
      formData.append("carrier", data.carrier);
      formData.append("mobile", data.mobile);
      formData.append("dob", dob);
      formData.append("address_1", data.address_1);
      formData.append("address_2", data.address_2);
      formData.append("city", data.city);
      formData.append("state", data.state);
      formData.append("zipcode", data.zipcode);
      formData.append("member_since", member);
      formData.append("discount", data.discount);
      formData.append("is_active", data.is_active);
      formData.append("commission",data.commission)
      let response = await editSalesPerson({ payload: formData, id: id });
      if (response.status === false) {
        setIsLoading(false);
        toast.error(response.message || "Something went Wrong");
      } else if (response.status === true) {
        setIsLoading(false);
        toast.success(response?.message || "Client Created Successfully");
        setData({
          sales_person_id: "",
          first_name: "",
          middle_name: "",
          last_name: "",
          email: "",
          carrier: "",
          mobile: "",
          dob: "",
          address_1: "",
          address_2: "",
          city: "",
          state: "",
          zipcode: "",
          member_since: "",
          discount: "",
          file: "",
        });
        navigate("/sales-person");
        return true;
      }
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err.message ? err.message : "Something Went Wrong during login";
      toast.error(err?.response?.data?.message);
      return false;
    }
  };

  const tableHeading = [
    { field: "fileicon", header: "", body: "fileIconBodyTemplate" },
    { field: "name", header: "File Name", body: "pdfFileopen" },
    { field: "size", header: "File size" },
    { field: "lastModifiedDate", header: "Date modified" },
    { field: "closeicon", header: "", body: "closeIconBodyTemplate" },
  ];

  const SubscriptionPlanTableHeading = [
    { field: "Date", header: "Date" },
    { field: "Clients", header: "Clients" },
    { field: "NoofClients", header: "No. of Clients" },
    { field: "TotalRevenue", header: "Total Revenue" },
    { field: "Payout", header: "Payout" },

    { field: "Total", header: "Total" },
  ];

  const SubscriptionPlanTableData = [
    {
      Date: "Mar-02-23",
      Clients: "Austin",
      NoofClients: "20",
      TotalRevenue: "$250",
      Payout: "$50",
      Total: "$300",
    },

    {
      Date: "Mar-02-23",
      Clients: "Austin",
      NoofClients: "15",
      TotalRevenue: "$250",
      Payout: "$50",
      Total: "$300",
    },

    {
      Date: "Mar-02-23",
      Clients: "Austin",
      NoofClients: "34",
      TotalRevenue: "$250",
      Payout: "$50",
      Total: "$300",
    },

    {
      Date: "Mar-02-23",
      Clients: "Austin",
      NoofClients: "04",
      TotalRevenue: "$250",
      Payout: "$50",
      Total: "$300",
    },


  ];
  const handleToogle = () => {
    setChecked((prev) => !prev);
    if (!checked) {
      setData({
        ...data,
        is_active: 1,
      });
    } else {
      setData({
        ...data,
        is_active: 0,
      });
    }
  };
  const handleCarrier = (e) => {
    setSelectedCarrier(e.value)
    setData((prev) => {
      return {
        ...prev,
        carrier: e.value.id
      }
    })
  }
  const handleDiscount = (e) => {
    setSelectedDiscount(e.value)
    setData((prev) => {
      return {
        ...prev,
        discount: e?.value.id
      }
    })
  }
  const handleDelete = async (nam, id) => {
    if (id) {
      let response = await deletePdf(id)
      let newTable = tableData.filter((item) => { return item.id !== id; })
      setTableData(newTable)
    } else {
      let newTableData = tableData.filter((item) => { return item.key !== nam })
      setTableData(newTableData)
    }

  }
  useEffect(() => {
    let object
    let array = []
    let pdf = data.pdf_sales_person
    pdf?.length > 0 && pdf.map((item) => {
      let date = new Date(item.created_at)
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();
      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
      let dob =   dt + '/' +month +'/'+year
      object = {
        name: item.file_name,
        size: item.file_size,
        lastModifiedDate: dob,
        id: item.id
      }
      array.push(object)
    })
    let file = data.files
    let filePdf = Object.values(file || {})
    filePdf.length > 0 && filePdf.map((item, index) => {
      object = {
        name: item.name,
        size: item.size,
        lastModifiedDate: item.lastModifiedDate?.toLocaleDateString(),
        key: index + 1,
        binaryKey: item
      }
      array.push(object)
    })
    setTableData(array)
  }, [data])
  return (
    <ContentArea>
      {isloading ? (
        <Loading />
      ) : (
        <div className="p-3">
          <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg flex justify-content-between">
            <h2 className="text-main ">
              {props.title ? props.title : "Edit Sales Person"}
            </h2>
            <span className="flex text-lg text-700 ">
              Active &nbsp;&nbsp;
              <InputSwitch
                checked={checked}
                onChange={(e) => handleToogle(e)}
                name="is_active"
              />
            </span>
          </div>
          <div className="shadow-2 p-5 border-round-lg">
            <div className="p-fluid grid mb-4">
              <div className="col-12 md:col-4">
                <label className="font block mb-2">
                  Salesperson Id<code className="text-red">*</code>
                </label>
                <InputText
                  placeholder=""
                  useGrouping={false}
                  inputClassName="border-round-md disable-input"
                  value={data?.profile_id}
                />
                {error.sales_person_id && (
                  <span className="error">{error.sales_person_id}</span>
                )}
              </div>
              <div className="col-12 md:col-4 ">
                <label className="font block mb-2">
                  First Name<code className="text-red">*</code>
                </label>
                <InputText
                  id="inputtext"
                  placeholder=""
                  className="border-round-md "
                  name="first_name"
                  value={data.first_name}
                  onChange={(e) => handleChange(e)}
                />
                {error.first_name && (
                  <span className="error">{error.first_name}</span>
                )}
              </div>
              <div className=" col-12 md:col-4">
                <label className="font block mb-2"> Middle Name </label>
                <InputText
                  id="inputtext"
                  placeholder=""
                  className="border-round-md "
                  name="middle_name"
                  value={data.middle_name}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="col-12 md:col-4 ">
                <label className="font block mb-2">
                  Last Name<code className="text-red">*</code>
                </label>
                <InputText
                  id="inputtext"
                  placeholder=""
                  className="border-round-md "
                  name="last_name"
                  value={data.last_name}
                  onChange={(e) => handleChange(e)}
                />
                {error.last_name && (
                  <span className="error">{error.last_name}</span>
                )}
              </div>
              <div className="col-12 md:col-4 ">
                <label className="font block mb-2">
                  Email<code className="text-red">*</code>
                </label>
                <InputText
                  id="inputtext"
                  placeholder=""
                  className="border-round-md "
                  name="email"
                  value={data.email}
                  onChange={(e) => handleChange(e)}
                />
                {error.email && <span className="error">{error.email}</span>}
              </div>
              <div className="col-12 md:col-4 ">
                <label className="font block mb-2">
                  Carrier<code className="text-red">*</code>
                </label>
                <Dropdown className="border-round-md " name="carrier" options={carrier} optionLabel="carrier_name" value={selectedCarrier} onChange={(e) => handleCarrier(e)} />
                {error.carrier && (
                  <span className="error">{error.carrier}</span>
                )}
              </div>
              <div className=" col-12 md:col-4">
                <label className="font block mb-2">
                  Phone No.<code className="text-red">*</code>
                </label>
                <InputNumber
                  id="inputnumber"
                  placeholder=""
                  className="border-round-md "
                  inputClassName="border-round-md"
                  name="mobile"
                  value={data.mobile}
                  maxLength={10}
                  useGrouping={false}
                  onValueChange={(e) => {
                    setError({
                      ...error,
                      mobile: null,
                    });
                    setData({ ...data, mobile: e.value });
                  }}
                />
                {error.mobile && <span className="error">{error.mobile}</span>}
              </div>
              <div className="col-12 md:col-4 ">
                <label className="font block mb-2">
                  DOB<code className="text-red">*</code>
                </label>
                <Calendar
                  className="border-round-md calender_picker "
                  inputClassName="calendar p-2 border-round-md "
                  placeholder=""
                  name="dob"
                  value={data.dob}
                  onChange={(e) => {
                    setError({
                      ...error,
                      dob: null,
                    });
                    setData({ ...data, dob: e.value });
                  }}
                />
                {error.dob && <span className="error">{error.dob}</span>}
              </div>
              <div className="col-12 md:col-4 ">
                <label className="font block mb-2">
                  Address 1<code className="text-red">*</code>
                </label>
                <InputText
                  id="inputtext"
                  placeholder=""
                  className="border-round-md "
                  name="address_1"
                  value={data.address_1}
                  onChange={(e) => handleChange(e)}
                />
                {error.address_1 && (
                  <span className="error">{error.address_1}</span>
                )}
              </div>
              <div className=" col-12 md:col-4">
                <label className="font block mb-2"> Address 2 </label>
                <InputText
                  id="inputtext"
                  placeholder=""
                  className="border-round-md "
                  name="address_2"
                  value={data.address_2}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="col-12 md:col-4 ">
                <label className="font block mb-2">
                  City<code className="text-red">*</code>
                </label>
                <InputText
                  id="inputtext"
                  placeholder=""
                  className="border-round-md "
                  name="city"
                  value={data.city}
                  onChange={(e) => handleChange(e)}
                />
                {error.city && <span className="error">{error.city}</span>}
              </div>
              <div className="col-12 md:col-4 ">
                <label className="font block mb-2">
                  State<code className="text-red">*</code>
                </label>
                <InputText
                  id="inputtext"
                  placeholder=""
                  className="border-round-md "
                  name="state"
                  value={data.state}
                  onChange={(e) => handleChange(e)}
                />
                {error.state && <span className="error">{error.state}</span>}
              </div>
              <div className="col-12 md:col-6 ">
                <label className="font block mb-2">
                  Zip Code<code className="text-red">*</code>
                </label>
                <InputNumber
                  id="inputnumber"
                  placeholder=""
                  className="border-round-md "
                  inputClassName="border-round-md"
                  name="zipcode"
                  value={data.zipcode}
                  maxLength={5}
                  useGrouping={false}
                  onValueChange={(e) => {
                    setError({
                      ...error,
                      zipcode: null,
                    });
                    setData({ ...data, zipcode: e.value });
                  }}
                />
                {error.zipcode && (
                  <span className="error">{error.zipcode}</span>
                )}
              </div>
              <div className="col-12 md:col-6 ">
                <label className="font block mb-2">
                  Member Since*<code className="text-red">*</code>
                </label>
                <Calendar
                  className="border-round-md calender_picker "
                  inputClassName="calendar p-2 border-round-md "
                  placeholder=""
                  value={data.member_since}
                  name="member_since"
                  onChange={(e) => {
                    setError({
                      ...error,
                      member_since: null,
                    });
                    setData({ ...data, member_since: e.value });
                  }}
                />
                {error.member_since && (
                  <span className="error">{error.member_since}</span>
                )}
              </div>
              <div className="col-12 md:col-6 ">
                <label className="font block mb-2">
                  Commission<code className="text-red">*</code>
                </label>
                <InputNumber
                  id="inputnumber"
                  placeholder=""
                  className="border-round-md "
                  inputClassName="border-round-md"
                  useGrouping={false}
                  name="commission"
                  value={data.commission}
                  maxLength={4}
                  onValueChange={(e) => {
                    setError({
                      ...error,
                      commission: null,
                    });
                    setData({ ...data, commission: e.value });
                  }}
                />
                {error.commission && <span className="error">{error.commission}</span>}
              </div>
              <div className="col-12 md:col-6 ">
                <label className="font block mb-2">
                  Discount<code className="text-red">*</code>
                </label>
                <Dropdown
                  className="border-round-md md:col-3"
                  placeholder="Apply Discount"
                  options={discount}
                  optionLabel="discount_code"
                  value={selectedDiscount || data?.discount} onChange={(e) => handleDiscount(e)}
                />
                {error.discount && (
                  <span className="error">{error.discount}</span>
                )}
              </div>
            </div>
            <div className="p-fluid grid mb-4 p-0 m-0">
              <label className="font block mb-2">Upload Files</label>
              <div className="col-12 md:col-12 text-center border-1 border-400 border-round-md border-dashed ">
                <div className="upload_image p-5">
                  <label
                    htmlFor="profile-img"
                    className="bg-primary cursor-pointer w-3 px-5 border-0 p-2 border-round-md"
                  >
                    <i className="pi pi-plus mr-3"></i>
                    <span>Choose a File</span>
                  </label>
                  <input
                    type="file"
                    mode="basic"
                    hidden
                    className="file-upload text-white border-round-lg"
                    id="profile-img"
                    chooseLabel="Choose a file"
                    name="profile-img"
                    accept="pdf/*"
                    maxFileSize={1000000}
                    onChange={(e) => {
                      setError({
                        ...error,
                        files: null,
                      });
                      setData({ ...data, files: e.target.files });
                    }}
                    multiple
                  />
                  <p>Drag your Photo here or Browser</p>
                </div>
              </div>
              {error.files&& <span className="error">{error.files}</span>}
            </div>
            <div className="grid  mb-5">
              <div className="md:col-12">
                <Table tableHeading={tableHeading} tableData={tableData} handleDelete={handleDelete} />
              </div>
            </div>
            <Card className="shadow-2 text-main border-round-lg mb-5">
              <h2 className="text-main mb-3 p-0 m-0 Karla ">
                Commission Report
              </h2>
              <div className="grid program_table">
                <div className="md:col-12">
                  <Table
                    tableHeading={SubscriptionPlanTableHeading}
                    tableData={SubscriptionPlanTableData}

                  />
                  <div className="grid justify-content-between mt-2">
                    <div className="md:col-2 ">
                      <small className="karla">Grand Total</small>
                    </div>
                    <div className="md:col-3 pl-8"></div>
                    <div className="md:col-2 pl-8">
                    </div>
                    <div className="md:col-2 pl-8">
                      <small>$950</small>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
            <div className=" btn flex">
              <div className="text-center">
                <Button
                  label="Save"
                  className="w-full p-3 px-5  border-0 border-round-md bg-main"
                  onClick={handleSubmit}
                />
              </div>
              <div className="text-center">
                <Button
                  label="Cancel"
                  className="w-full surface-500 p-3  ml-5 border-0 border-round-md"
                  onClick={() => navigate("/sales-person")}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </ContentArea>
  );
}
