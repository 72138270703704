import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import ContentArea from "../../../shared/ContentArea";
import EmployeeImage from "../../../assets/images/Employeeimage.png";
import { addEmployee, getPermission, getCarrier } from "../../../utils/action";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Permissions from "./Permission";
import { Loading } from "../../../components/Loader/Loading";
import {
  validatePhoneNumber,
  validateZipcodeNumber,
  validEmail
} from "../../../shared/Validation";
import { Dropdown } from "primereact/dropdown";
import EmployeeIcon from "../../../assets/images/Employeeicon.png"
toast.configure();
export default function AddEmployees(props) {
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedNodes, setSelectedNodes] = useState({});
  const [carrier, setCarrier] = useState("")
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [value, setValues] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    password: "",
    mobile: "",
    dob: "",
    social_security_no: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    zipcode: "",
    hourly_rate: "",
    carrier: "",
    image: "",
    username : "",
    permissions: [],
    selected_nodes: {},
    is_active: "1",
    is_sms_notify: "0",
    is_email_notify: "0",
  });
  const [error, setError] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    password: "",
    mobile: "",
    dob: "",
    social_security_no: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    zipcode: "",
    hourly_rate: "",
    carrier: "",
    image: "",
    name: "",
    emp_id: "",
    permissions: [],
  });

  const cancelButton = () => {
    navigate("/manage-employees");
  };
  let date = new Date(value.dob)
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();
  if (dt < 10) {
    dt = '0' + dt;
  }
  if (month < 10) {
    month = '0' + month;
  }
  let dob = year + '-' + month + '-' + dt;
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (
        value.first_name === "" || value.last_name === "" || value.email === "" || value.password === "" || value.mobile === "" || value.dob === "" ||
        value.address_1 === "" || value.city === "" || value.state === "" || value.zipcode === "" || value.hourly_rate === "" || value.carrier === "" ||
        value.image === "" || value.username  === ""  || value.permissions?.length === 0 || !validatePhoneNumber(value.mobile) || !validateZipcodeNumber(value.zipcode)
      ||!validEmail(value.email)) {
        setError({
          ...error,
          first_name: value.first_name === "" ? "Please Enter First Name" : error?.first_name,
          last_name: value.last_name === "" ? "Please Enter Last Name" : error?.last_name,
          email: value.email === "" ? "Please Enter Email" : !validEmail(value.email)?"Please Enter Valid Email ":error?.email,
          password: value.password === "" ? "Please Enter Password" : error?.password,
          mobile: value.mobile === "" ? "Please Enter Mobile" : !validatePhoneNumber(value.mobile) ? "Please Enter Valid Mobile Number" : error.mobile,
          dob: value.dob === "" ? "Please Enter DOB" : error?.dob,
          address_1: value.address_1 === "" ? "Please Enter Address" : error.address_1,
          city: value.city === "" ? "Please Enter City" : error.city,
          state: value.state === "" ? "Please Enter State" : error.state,
          hourly_rate: value.hourly_rate === "" ? "Please Enter Hourly Rate" : error.hourly_rate,
          carrier: value.carrier === "" ? "Please Enter Carrier" : error.carrier,
          image: value.image === "" ? "Select Image" : error.image,
          username : value.username  === "" ? "Please Enter User Name" : error.username ,
          zipcode: value.zipcode === "" ? "Please Enter Zip Code" : !validateZipcodeNumber(value.zipcode) ? "Please Enter Valid Zip Code" : error.zipcode,
          permissions: value.permissions?.length === 0 ? "Please Enter Permission" : error.permissions
        });
        return false;
      }
      const formData = new FormData();
      formData.append("username",value.username );
      formData.append("image", value.image);
      formData.append("first_name", value.first_name);
      formData.append("middle_name", value.middle_name);
      formData.append("last_name", value.last_name);
      formData.append("email", value.email);
      formData.append("password", value.password);
      formData.append("mobile", value.mobile);
      formData.append("dob", dob);
      formData.append("social_security_no", value.social_security_no);
      formData.append("address_1", value.address_1);
      formData.append("address_2", value.address_2);
      formData.append("city", value.city);
      formData.append("state", value.state);
      formData.append("zipcode", value.zipcode);
      formData.append("hourly_rate", value.hourly_rate);
      formData.append("carrier", value.carrier);
      formData.append("permissions", JSON.stringify(value.permissions));
      formData.append("is_active", value.is_active);
      formData.append("is_email_notify", value.is_email_notify);
      formData.append("is_sms_notify", value.is_sms_notify);
      formData.append("selected_nodes", JSON.stringify(value.selected_nodes));
      
      let response = await addEmployee({ payload: formData });
      if (response.status === false) {
        setIsLoading(false);
        toast.error(response.message || "Something went wrong");
      } else {
        setIsLoading(false);
        toast.success(response.message||"Employee Created Successfully");
        setValues({
          first_name: "",
          middle_name: "",
          last_name: "",
          email: "",
          password: "",
          mobile: "",
          dob: "",
          social_security_no: "",
          address_1: "",
          address_2: "",
          city: "",
          state: "",
          zipcode: "",
          hourly_rate: "",
          carrier: "",
          image: "",
          username : "",
          permissions: [],
        });
        navigate("/manage-employees");
        return response;
      }
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err.message ? err.message : "Something Went Wrong during login";
      toast.error(err?.response?.data?.message);
      return false;
    }
  };
  const handleChange = (e) => {
    setValues({
      ...value,
      [e?.target?.name]: e?.target?.value,
    });
    setError({
      ...error,
      [e?.target?.name]:"",
    });
  };
  useEffect(() => {
    async function fetchMyAPI() {
      let response = await getCarrier();
      setCarrier(response.data)
    }
    fetchMyAPI();
  }, [])
  useEffect(() => {
    async function fetchMyAPI() {
      let response = await getPermission();

      const permissionData = [];

      response.data.map((permission) => {
        const permObj = {
          key: permission.id,
          label: permission.type,
          data: permission.type,
          icon: "",
          children: [],
        };

        permission.permissions.map((child) => {
          let childObj = {
            key: permission.id + "-" + child.id,
            label: child.name,
            data: child.name,
          };
          permObj.children.push(childObj);
        });

        permissionData.push(permObj);
      });
      setPermissions(permissionData);
    }
    fetchMyAPI();
  }, []);
  const handleReset = () => {
    setValues((prev) => {
      return {
        ...prev,
        username : "",
        password: ""
      }
    })
  };
  const handleCarrier = (e) => {
    setSelectedCarrier(e.value)
    setError({
      ...error,
      carrier: "",
  });
    setValues({
      ...value,
      carrier: e.value.id,
    })

  }
  return (
    <ContentArea>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="p-3">
          <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
            <h2 className="text-main">
              {props.title ? props.title : "Add Employee Details"}
            </h2>
          </div>
          <div className="shadow-2 p-5 border-round-lg">
            <div className="p-fluid grid align-items-center">
              <div className="col-12 md:col-12 text-main">
                <h2 className="m-0 mb-2">Employee Photo</h2>
              </div>
            </div>
            <div className="p-fluid grid flex align-items-center mb-4">
              <div className="upload-image col-12 md:col-2">
                <p className="mb-2 text-sm">Upload Image</p>
                <img
                  src={
                    value.image
                      ? URL.createObjectURL(value?.image)
                      : EmployeeIcon
                  }
                  alt=""
                  width={102}
                  height={109}
                  className="w-full h-full"
                />
              </div>
              <div className="col-12 md:col-10">
                <div className="upload_image mb-3">
                  <label
                    htmlFor="profile-img"
                    className="bg-primary cursor-pointer w-3 px-5 border-0 p-2 border-round-md"
                  >
                    <i className="pi pi-plus mr-3"></i>
                    <span>Choose a File</span>
                  </label>
                  <input
                    type="file"
                    mode="basic"
                    hidden
                    className="file-upload text-white border-round-lg"
                    id="profile-img"
                    chooseLabel="Choose a file"
                    name="profile-img"
                    accept="image/*"
                    maxFileSize={1000000}
                    onChange={(e) => {
                      setError({
                        ...error,
                        image: null,
                      });
                      setValues({ ...value, image: e.target.files[0] });
                    }}
                  />
                  {error.image && <span className="error">{error.image}</span>}
                </div>
                <p className="text-500 m-0 text-xs"> Acceptable Formats jpg, png only up to 10mb</p>
              </div>
            </div>
            <div className="p-fluid grid mb-4">
              {/* <div className="col-12 md:col-4">
                <label className="font block mb-2">
                  Employee Id<code className="text-red">*</code>
                </label>
                <InputNumber
                  inputClassName="border-round-md"
                  name="emp_id"
                  value={value.emp_id}
                  useGrouping={false}
                  onValueChange={(e) => {
                    setError({
                      ...error,
                      emp_id: null,
                    });
                    setValues({ ...value, emp_id: e.value });
                  }}
                />
                {error.emp_id && <span className="error">{error.emp_id}</span>}
                <br />
              </div> */}
              <div className=" col-12 md:col-4">
                <label className="font block mb-2">
                  Employee Email<code className="text-red">*</code>
                </label>
                <InputText
                  className="border-round-md"
                  name="email"
                  value={value.email}
                  onChange={(e) => handleChange(e)}
                />
                {error.email && <span className="error">{error.email}</span>}
                <br />
              </div>
              <div className=" col-12 md:col-4">
                <label className="font block mb-2">Social Security No.</label>
                <InputText
                  className="border-round-md"
                  name="social_security_no"
                  value={value.social_security_no}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="col-12 md:col-4">
                <label className="font block mb-2">
                  First Name<code className="text-red">*</code>
                </label>
                <InputText
                  inputClassName="border-round-md"
                  name="first_name"
                  value={value.first_name}
                  onChange={(e) => handleChange(e)}
                />
                {error.first_name && (
                  <span className="error">{error.first_name}</span>
                )}
                <br />
              </div>
              <div className=" col-12 md:col-4">
                <label className="font block mb-2">Middle Name</label>
                <InputText
                  className="border-round-md"
                  name="middle_name"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className=" col-12 md:col-4">
                <label className="font block mb-2">
                  Last Name<code className="text-red">*</code>
                </label>
                <InputText
                  className="border-round-md"
                  name="last_name"
                  value={value.last_name}
                  onChange={(e) => handleChange(e)}
                />
                {error.last_name && (
                  <span className="error">{error.last_name}</span>
                )}
                <br />
              </div>
              <div className="col-12 md:col-4 ">
                <label className="font block mb-2">
                  DOB<code className="text-red">*</code>
                </label>
                <Calendar
                  className="border-round-md calender_picker "
                  inputClassName="calendar p-2 border-round-md "
                  placeholder=""
                  value={value.dob}
                  name="dob"
                  onChange={(e) => {
                    setError({
                      ...error,
                      dob: null,
                    });
                    setValues({ ...value, dob: e.value });
                  }}
                />
                {error.dob && <span className="error">{error.dob}</span>}
              </div>
              <div className="col-12 md:col-4 ">
                <label className="font block mb-2">
                  Carrier<code className="text-red">*</code>
                </label>
                <Dropdown className="border-round-md " name="carrier" options={carrier} optionLabel="carrier_name" value={selectedCarrier} onChange={(e) => handleCarrier(e)} />
             {error.carrier && <span className="error">{error.carrier}</span>}   
              </div>
              <div className=" col-12 md:col-4">
                <label className="font block mb-2">
                  Phone No.<code className="text-red">*</code>
                </label>
                <InputNumber
                  className="border-round-md"
                  inputClassName="border-round-md"
                  value={value.mobile}
                  useGrouping={false}
                  maxLength={10}
                  name="mobile"
                  onValueChange={(e) => {
                    setError({
                      ...error,
                      mobile: null,
                    });
                    setValues({
                      ...value,
                      mobile: e.value,
                    });
                  }}
                />
                {error.mobile && <span className="error">{error.mobile}</span>}
              </div>
              <div className="col-12 md:col-4 ">
                <label className="font block mb-2">
                  Address 1<code className="text-red">*</code>
                </label>
                <InputText
                  id="inputtext"
                  className="border-round-md "
                  name="address_1"
                  value={value.address_1}
                  onChange={(e) => handleChange(e)}
                />
                {error.address_1 && (
                  <span className="error">{error.address_1}</span>
                )}
                <br />
              </div>
              <div className=" col-12 md:col-4">
                <label className="font block mb-2"> Address 2 </label>
                <InputText
                  id="inputtext"
                  className="border-round-md "
                  name="address_2"
                  value={value.address_2}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="col-12 md:col-4 ">
                <label className="font block mb-2">
                  City<code className="text-red">*</code>
                </label>
                <InputText
                  id="inputtext"
                  className="border-round-md "
                  name="city"
                  value={value.city}
                  onChange={(e) => handleChange(e)}
                />
                {error.city && <span className="error">{error.city}</span>}
                <br />
              </div>
              <div className="col-12 md:col-4 ">
                <label className="font block mb-2">
                  State<code className="text-red">*</code>
                </label>
                <InputText
                  id="inputtext"
                  className="border-round-md "
                  name="state"
                  value={value.state}
                  onChange={(e) => handleChange(e)}
                />
                {error.state && <span className="error">{error.state}</span>}
                <br />
              </div>
              <div className="col-12 md:col-4 ">
                <label className="font block mb-2">
                  Zip Code<code className="text-red">*</code>
                </label>
                <InputNumber
                  id="inputnumber"
                  className="border-round-md "
                  inputClassName="border-round-md"
                  name="zipcode"
                  value={value.zipcode}
                  useGrouping={false}
                  maxLength={5}
                  onValueChange={(e) => {
                    setError({
                      ...error,
                      zipcode: null,
                    });
                    setValues({
                      ...value,
                      zipcode: e.value,
                    });
                  }}
                />
                {error.zipcode && (
                  <span className="error">{error.zipcode}</span>
                )}
              </div>
            </div>
            <div className="p-fluid grid mb-4 flex align-items-center">
              <div className=" col-12 md:col-4">
                <label className="font block mb-2">
                  Set Employee Hourly Rate<code className="text-red">*</code>
                </label>
                <InputText
                  className="hr border-round-md"
                  placeholder="/hr"
                  value={value.hourly_rate}
                  name="hourly_rate"
                  onChange={(e) => handleChange(e)}
                ></InputText>
                {error.hourly_rate && (
                  <span className="error">{error.hourly_rate}</span>
                )}
                <br />
              </div>
              <div className=" col-12 md:col-8 mt-5 ">
                <input
                  className="form-check-input ml-5 mb-2"
                  type="checkbox"
                  onChange={(e) => {
                    setError({
                      ...error,
                      is_email_notify: null,
                    });
                    setValues({
                      ...value,
                      is_email_notify: e.target.checked === true ? 1 : 0,
                    });
                  }}
                  value={value.is_email_notify}
                  name="is_email_notify"
                />
                &nbsp; Check to receive the Email reminder notifications.
                <div className="col-12 md:col-12 p-0 m-0 ">
                  <input
                    className="form-check-input ml-5"
                    type="checkbox"
                    onChange={(e) => {
                      setError({
                        ...error,
                        is_sms_notify: null,
                      });
                      setValues({
                        ...value,
                        is_sms_notify: e.target.checked === true ? 1 : 0,
                      });
                    }}
                    value={value.is_sms_notify}
                    name="is_sms_notify"
                  />
                  &nbsp; Check to receive the SMS reminder notifications.
                </div>
              </div>
            </div>
            <p className="text-main text-2xl font-bold Karla ">
              Set Login Details
            </p>
            <div className="p-fluid grid mb-4 flex align-items-center">
              <div className=" col-12 md:col-4">
                <label className="font block mb-2 ">
                  Employee login/ Username<code className="text-red">*</code>
                </label>
                <InputText
                  className="border-round-md"
                  name="username"
                  value={value.username}
                  onChange={(e) => handleChange(e)}
                />
                {error.username && <span className="error">{error.username}</span>}
                <br />
              </div>
            </div>
            <div className="p-fluid grid mb-4">
              <div className=" col-12 md:col-4">
                <label className="font block mb-2 ">
                  Employee Password<code className="text-red">*</code>
                </label>
                <InputText
                  className="border-round-md"
                  name="password"
                  value={value.password}
                  onChange={(e) => handleChange(e)}
                />
                {error.password && (
                  <span className="error">{error.password}</span>
                )}
                <br />
              </div>
              <div className=" col-12 md:col-8 mt-2">
                <p className=" text-lg flex align-items-center">
                  Reset
                  <span className="">
                    <Button
                      icon="pi pi-replay"
                      className="border-round-3xl  ml-3 "
                      onClick={handleReset}
                    ></Button>
                  </span>
                </p>
              </div>
            </div>
            <h3 className="text-main ">Set Permissions</h3>
            <Permissions
              permissions={permissions}
              selectedNodes={selectedNodes}
              value={value}
              setSelectedNodes={setSelectedNodes}
              setSelectedKeys={setValues}
              error ={error}
              setError={setError}
            /> 
            {error.permissions && <span className="error">{error.permissions}</span>}
            <div className=" btn flex mt-3">
              <div className="text-center">
                <Button
                  label="Save"
                  className="w-full p-3 px-5  border-0 border-round-md bg-main"
                  onClick={handleSubmit}
                />
              </div>
              <div className="text-center">
                <Button
                  label="Cancel"
                  className="w-full surface-500 p-3  ml-5 border-0 border-round-md "
                  onClick={cancelButton}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </ContentArea>
  );
}
