
import React, { useState, useEffect } from "react";
import AddButton from "../../../components/Buttons/AddButton";
import ContentArea from '../../../shared/ContentArea'
import EditIcon from "../../../assets/icons/edit.png";
import DeleteIcon from "../../../assets/icons/delete.png";
import Table from "../../../components/TableList/Table";
import EyeIcon from "../../../assets/icons/eyeicon.png";
import SearchIcon from "../../../assets/icons/search.png";
import FilterIcon from "../../../assets/icons/filter.png";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import Deleteimg from "../../../assets/images/deleted.png";
import { getSalesPerson } from "../../../utils/action";
import { useNavigate } from "react-router-dom";
import Index from "../Index";
import AddSalesPerson from "./AddSalesPerson";
import { Loading } from "../../../components/Loader/Loading";
import EditSalesPerson from "./EditSalesPerson";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { deleteSalesPerson } from "../../../utils/action";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Calendar } from "primereact/calendar";
toast.configure()
export default function ManageSalesPerson() {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const { isNextPage, setIsNextPage } = Index();
    const [editButton, setEditButton] = useState(false)
    const [deleteButton, setDeleteButton] = useState();
    const [tableData, setTableData] = useState("")
    const [data, setData] = useState("");
    const [isFiltered, setIsFiltered] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [isShowFilter, setIsShowFilter] = useState(false);
    const [filterData, setFilterData] = useState({
        start_date: "",
        end_date: ""
    })
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        member_since: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
    });
    const addSalesPerson = () => {
        setIsNextPage(!isNextPage)
        navigate("/add-sales-person")
    }
    const tableHeading = [
        { field: "SrNo", header: "Sr. No.", body: "srnoTemplates" },
        { field: "profile_id", header: "Sales Person Id" },
        { field: "member_since", header: "Member Since", sortable: true },
        { field: "name", header: "Sales Person Name", sortable: true },
        { field: "email", header: "Sales Person Email" },
        { field: "mobile", header: "Contact no." },
        { field: "icons", header: "Action", body: "imageBodyTemp" },

    ];
    const Clearfilter = async () => {
        setIsFiltered(false)
        setIsShowFilter(false)
        let res = await getSalesPerson({ payload: data })
        res?.data?.map((item, index) => {
            let date = new Date(item.member_since)
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let dt = date.getDate();
            if (dt < 10) {
                dt = '0' + dt;
            }
            if (month < 10) {
                month = '0' + month;
            }
            item.member_since = year + '-' + month + '-' + dt;
        })
        setTableData(res.data.reverse())
    }
    const handleGetSalesPerson = async () => {
        setIsLoading(true)
        let response = await getSalesPerson({ payload: data });
        response?.data?.map((item, index) => {
            let date = new Date(item.member_since)
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let dt = date.getDate();
            if (dt < 10) {
                dt = '0' + dt;
            }
            if (month < 10) {
                month = '0' + month;
            }
            item.member_since = year + '-' + month + '-' + dt;

        })

        setIsLoading(false)
        setTableData(response.data.reverse())

    }
    useEffect(() => {
        handleGetSalesPerson()
    }, [])
    const refreshPage = () => {
        window.location.reload(true);
    }
    let id = data
    const handleDelete = async () => {
        setIsLoading(true)
        let response = await deleteSalesPerson(id)
        if (response.status === false) {
            setIsLoading(false)
            toast.error(response.message || "Something went Wrong")
            setDeleteButton(false)
        }
        else if (response.status === true) {
            setIsLoading(false)
            toast.success(response.message || "Sales Person  Delete Successfully")
            setDeleteButton(false)
            handleGetSalesPerson()
        }

    }
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const handleChange = (e) => {
        let date = new Date(e.target.value)
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let dt = date.getDate();
        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }
        let dob = year + '-' + month + '-' + dt;
        setFilterData({
            ...filterData,
            [e?.target?.name]: dob
        });
    };
    const applyfilter = async () => {
        setIsShowFilter(!isShowFilter)
        let response = await getSalesPerson({ payload: filterData })
        response?.data?.map((item, index) => {
            let date = new Date(item.member_since)
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let dt = date.getDate();
            if (dt < 10) {
                dt = '0' + dt;
            }
            if (month < 10) {
                month = '0' + month;
            }
            item.member_since = year + '-' + month + '-' + dt;
        })
        setIsFiltered(true)
        setTableData(response.data)
    }
    return (
        <>
            {
                !isNextPage && !editButton ?
                    <ContentArea>
                        {isLoading ? <Loading /> :
                            <div>
                                <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-evenly">
                                    <div className="md:col-5 ">
                                        <h1 className="text-main  " style={{ fontSize: "24px" }}>
                                            Manage Sales Person
                                        </h1>
                                    </div>
                                    <div className="md:col-3">
                                        <div className="search-box w-full">
                                            <img src={SearchIcon} alt="Search Icon" width="15px" height="15px" />
                                            <input type="search" placeholder="Search" onChange={onGlobalFilterChange} onClick={() => setIsShowFilter(false)} />
                                        </div>

                                    </div>

                                    <div className="md:col-1 relative bg-main px-3 border-round-md w-max  ">
                                        {
                                            isFiltered ?
                                                <div onClick={() => Clearfilter()} className="bg-main border-round-md text-white text-center text-base relative karla">
                                                    Clear &nbsp;
                                                    <i className="pi pi-filter-slash"></i></div>
                                                :
                                                <div onClick={() => setIsShowFilter(prev => !prev)} className="bg-main border-round-md text-white text-center text-base relative karla">
                                                    Filter &nbsp;
                                                    <img src={FilterIcon} alt="" width={15} /></div>
                                        }
                                        {
                                            isShowFilter ?
                                                <div className="filter-popup">
                                                    <label className="font block mb-2">
                                                        Start Date<code className="text-red"></code>
                                                    </label>
                                                    <Calendar
                                                        className="border-round-md calender_picker "
                                                        inputClassName="calendar p-2 border-round-md "
                                                        placeholder="Select Date"
                                                        name="start_date"
                                                        onChange={(e) => handleChange(e)}
                                                    />
                                                    <label className="font block mb-2 mt-2">
                                                        End Date<code className="text-red"></code>
                                                    </label>
                                                    <Calendar
                                                        className="border-round-md calender_picker "
                                                        inputClassName="calendar p-2 border-round-md "
                                                        placeholder="Select Date"
                                                        name="end_date"
                                                        onChange={(e) => handleChange(e)}
                                                    />
                                                    <div className="mt-3 flex justify-content-between">
                                                        <Button onClick={Clearfilter}>Clear</Button>
                                                        <Button onClick={(e) => applyfilter(e)}>Apply</Button></div>
                                                </div>
                                                : null
                                        }
                                    </div>

                                    <div className="md:col-2 w-max">
                                        <AddButton handleClick={addSalesPerson} />
                                    </div>
                                </div>

                                <div className="grid mt-2">
                                    <div className="md:col-12">

                                        <Table tableHeading={tableHeading} filters={filters} tableData={tableData} setEditButton={setEditButton} setDeleteButton={setDeleteButton} title="salesPerson" setData={setData} />
                                    </div>
                                </div>

                            </div>
                        }
                    </ContentArea>
                    : null
            }
            {
                isNextPage ? <AddSalesPerson /> : null
            }
            {
                editButton ? <EditSalesPerson /> : null
            }
            {
                deleteButton ?
                    <Dialog
                        className="flex justify-content-center"
                        visible={deleteButton}
                        onHide={() => setDeleteButton(false)}
                        style={{ width: '30vw' }}>
                        <p className="m-0">
                            <div className=" flex justify-content-center">
                                <img src={Deleteimg} alt="" width={150} height={127}></img>
                            </div>
                            <div className="text-center mb-5">
                                <h4 className=" p-0 m-0 ">Are you sure you want to delete this user?</h4>
                                <p className="text-xs text-400">All associated data will also be deleted! There is no undo!</p>
                            </div>
                            <div className=" btn flex justify-content-center">
                                <div className="text-center">
                                    <Button
                                        label="Cancel"
                                        className="w-full surface-500 p-3 px-5    border-0 border-round-md "
                                        onClick={() => setDeleteButton(false)}
                                    />
                                </div>
                                <div className="text-center">
                                    <Button
                                        label="Delete"
                                        className=" w-full px-5 border-0 p-3 ml-3 border-round-md bg-red-500"
                                        onClick={handleDelete}
                                    />
                                </div>
                            </div>
                        </p>
                    </Dialog> : null
            }
        </>
    )
}
