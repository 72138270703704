import React, { useEffect, useState } from "react";
import ContentArea from "../../../shared/ContentArea";
import Table from "../../../components/TableList/Table";
import EyeIcon from "../../../assets/icons/eyeicon.png";
import SearchIcon from "../../../assets/icons/search.png";
import FilterIcon from "../../../assets/icons/filter.png";
import { getPaymentDetails } from "../../../utils/action";
import Index from "../Index";
import ViewSubscriptionBilling from "./ViewSubscriptionBilling";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { FilterMatchMode } from 'primereact/api';
import { Loading } from "../../../components/Loader/Loading";
export default function PaymentBilling() {
  const [isShowFilter, setIsShowFilter] = useState(false);
  const { isNextPage, setIsNextPage } = Index();
  const [editButton, setEditButton] = useState(false);
  const [viewButton, setViewButton] = useState(false);
  const [tableData, setTableData] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const[data,setData] = useState("")
  const [filterData, setFilterData] = useState({
    start_date: "",
    end_date: ""
  })
  const [isFiltered, setIsFiltered] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    program_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },

  });
  const tableHeading = [
    { field: "SrNo", header: "Sr. No.", body: "srnoTemplates" },
    { field: "created_at", header: "Date",sortable: true  },
    { field: "client.id", header: "Client Id" },
    { field: "client.name", header: "Client Name",sortable: true  },
    { field: "client.company_name", header: "Company Name",sortable: true  },
    { field: "transaction_id", header: "Transaction Id" },
    { field: "subscription_plan.program_name", header: "Program Name" },
    { field: "subscription_plan.price_per_person", header: "Price" },
    { field: "status", header: "Status",sortable: true  },
    { field: "icons", header: "Action", body: "eyeBodyTemplate" },
  ];
  const paymentDetail = async () => {
    setIsLoading(true)
    try {
      let response = await getPaymentDetails({payload:data})
      response?.data?.map((item, index) => {
        let date = new Date(item.created_at)
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let dt = date.getDate();
        if (dt < 10) {
          dt = '0' + dt;
        }
        if (month < 10) {
          month = '0' + month;
        }
        item.created_at = year + '-' + month + '-' + dt;
      })
      setTableData(response.data.reverse())
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.log(err);
    }
  }
  const applyfilter = async (e) => {
    setIsShowFilter(!isShowFilter)
    let response = await getPaymentDetails({ payload: filterData })
    setIsFiltered(true)
    response?.data?.map((item, index) => {
      console.log(item,"====item=====")
      let date = new Date(item.created_at)
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();
      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
      item.created_at = year + '-' + month + '-' + dt;
    })
    setTableData(response.data)
  }
  const Clearfilter = async () => {
    setIsFiltered(false)
    setIsShowFilter(false)
    let res = await getPaymentDetails({ payload: data })
    res?.data?.map((item, index) => {
      let date = new Date(item.created_at)
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();
      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
      item.created_at = year + '-' + month + '-' + dt;
    })
    setTableData(res.data.reverse())
  }
  useEffect(() => {
    paymentDetail()
  }, [])

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const handleChange = (e) => {
    let date = new Date(e.target.value)
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();
    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }
    let dob = year + '-' + month + '-' + dt;
    setFilterData({
      ...filterData,
      [e?.target?.name]: dob,
    });
  };
  return (
    <>
      {isLoading ? <Loading /> :
        <>
          {!isNextPage & !editButton & !viewButton ? (
            <ContentArea>
              <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-evenly">
                <div className="md:col-6 ">
                  <h1 className="text-main  " style={{ fontSize: "24px" }}>
                    Payment Details
                  </h1>
                </div>

                <div className="md:col-3">
                  <div className="search-box w-full">
                    <img
                      src={SearchIcon}
                      alt="Search Icon"
                      width="15px"
                      height="15px"
                    />
                    <input type="search" placeholder="Search" onChange={onGlobalFilterChange} onClick={()=>setIsShowFilter(false)}/>
                  </div>
                </div>
                <div className="md:col-1 relative bg-main px-3 border-round-md w-max  ">
                  {
                    isFiltered ?
                      <div onClick={() => Clearfilter()} className="bg-main border-round-md text-white text-center text-base relative karla">
                        Clear &nbsp;
                        <i className="pi pi-filter-slash"></i></div>
                      :
                      <div onClick={() => setIsShowFilter(prev => !prev)} className="bg-main border-round-md text-white text-center text-base relative karla">
                        Filter &nbsp;
                        <img src={FilterIcon} alt="" width={20} /></div>
                  }
                  {
                    isShowFilter ?
                      <div className="filter-popup">
                        <label className="font block mb-2">
                          Start Date<code className="text-red"></code>
                        </label>
                        <Calendar
                          className="border-round-md calender_picker "
                          inputClassName="calendar p-2 border-round-md "
                          placeholder="Select Date"
                          name="start_date"
                          onChange={(e) => handleChange(e)}
                        />
                        <label className="font block mb-2 mt-2">
                          End Date<code className="text-red"></code>
                        </label>
                        <Calendar
                          className="border-round-md calender_picker "
                          inputClassName="calendar p-2 border-round-md "
                          placeholder="Select Date"
                          name="end_date"
                          onChange={(e) => handleChange(e)}
                        />
                        <div className="mt-3 flex justify-content-between">
                          <Button onClick={Clearfilter}>Clear</Button>
                          <Button onClick={(e) => applyfilter(e)}>Apply</Button></div>
                      </div>
                      : null
                  }
                </div>
              </div>

              <div className="grid mt-2">
                <div className="md:col-12">
                  <Table
                    tableHeading={tableHeading}
                    tableData={tableData}
                    setEditButton={setEditButton}
                    setViewButton={setViewButton}
                    title="paymentBilling"
                    filters={filters}
                    setData={setData}
                    setFilters={setFilters}
                  />
                </div>
              </div>
            </ContentArea>
          ) : null}

          {editButton ? <ViewSubscriptionBilling title="Billing Details" /> : null}

          {viewButton ? <ViewSubscriptionBilling title="Billing Details" /> : null}
        </>
      }

    </>
  );
}
