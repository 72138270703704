import React from "react";
import loader from "../../assets/images/loader.gif";

export const Loading = () => {
    return (
        <>
            <div className="try-load flex justify-content-center">
                <img src={loader} />
            </div>
        </>
    )
}