import React, { useState } from "react";
import ContentArea from "../../../shared/ContentArea";
import { Button } from "primereact/button";
import Table from "../../../components/TableList/Table";
import { useNavigate } from "react-router-dom";
import { Dialog } from 'primereact/dialog';
import ViewQuickNotification from "./ViewQuickNotification";
import Index from "../Index";


export default function ManageQuickNotification() {

    const navigate = useNavigate()
    const [descriptionButton, setDescriptionButton] = useState(false)


    const SendQuickNotification = () => {
        navigate("/send-quick-notification")
    }
    

    console.log(descriptionButton, "descriptionButtondescriptionButtondescriptionButtondescriptionButton")

    const TableData = [
        {
            SrNo: 1,
            Date: "12-04-2023",
            Title: "Payment Pending",
            Message: " View",
            SentTo: "Employee",

        },
        {
            SrNo: 2,
            Date: "12-04-2023",
            Title: "Poor Performance",
            Message: " View",
            SentTo: "Sales Person",

        },
        {
            SrNo: 3,
            Date: "12-04-2023",
            Title: "No Sales",
            Message: " View",
            SentTo: "All",

        },
        {
            SrNo: 4,
            Date: "12-04-2023",
            Title: "Commission Transacted",
            Message: " View",
            SentTo: "Clients",

        },
        {
            SrNo: 5,
            Date: "12-04-2023",
            Title: "Payment Pending",
            Message: " View",
            SentTo: "Employee",

        },
        {
            SrNo: 6,
            Date: "12-04-2023",
            Title: "Payment Pending",
            Message: " View",
            SentTo: "Employee",

        },
        {
            SrNo: 7,
            Date: "12-04-2023",
            Title: "Payment Pending",
            Message: " View",
            SentTo: "Employee",

        },
        {
            SrNo: 8,
            Date: "12-04-2023",
            Title: "Payment Pending",
            Message: " View",
            SentTo: "Employee",

        },
        {
            SrNo: 9,
            Date: "12-04-2023",
            Title: "Payment Pending",
            Message: " View",
            SentTo: "Employee",

        },
        {
            SrNo: 10,
            Date: "12-04-2023",
            Title: "Payment Pending",
            Message: " View",
            SentTo: "Employee",

        },

    ]

    const TableHeading = [
        { field: "SrNo", header: "Sr. No.", },
        { field: "Date", header: "Date" },
        { field: "Title", header: "Title", },
        { field: "fileicon", header: "Message", body: "notificationIconBodyTemplate" },
        { field: "SentTo", header: "Sent To", },
        { field: "icons", header: "Action", body: "ViewIconBodyTemplate" }
    ];





    return (

        <>

            <ContentArea>
                <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-evenly">
                    <div className="md:col-8 ">
                        <h1 className="text-main  Karla " style={{ fontSize: "24px" }}>
                            Manage Quick Notifications
                        </h1>
                    </div>
                    <div className="md:col-3 text-center">
                        <Button label="Send Quick Notification" className="bg-main" onClick={SendQuickNotification} />
                    </div>
                </div>
                <div className="grid mt-2">
                    <div className="md:col-12">
                        <div className="subscription_table">
                            <Table tableHeading={TableHeading}
                                tableData={TableData}
                                setDescriptionButton={setDescriptionButton}
                          
                            />
                        </div>
                    </div>
                </div>
            </ContentArea>
         
            {
                descriptionButton ?
                    <Dialog
                        onClick={() => setDescriptionButton(false)}

                        className="flex justify-content-center"
                        visible={descriptionButton}
                        onHide={() => setDescriptionButton(false)}
                        style={{ width: '50vw' }}>

                        <div className="p-0">
                            <p className="text-main mb-3 text-xl fon-medium">19 Feb 2023 05:00 am </p>
                            <p className="font-medium text-2xl mb-3">Lorem Ipsum is simply</p>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                            <div className="text-center">
                                <Button
                                    label="Ok"
                                    className=" px-5 border-0 p-3 ml-3 border-round-md bg-main"

                                />
                            </div>
                        </div>

                    </Dialog> : null

            }

        </>
    )
}
