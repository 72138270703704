
import React, { useState, useRef, useEffect } from "react";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import ContentArea from "../../../shared/ContentArea";
import EmployeeImage from "../../../assets/images/Employeeimage.png"
import { InputSwitch } from 'primereact/inputswitch';
import { viewEmployess,getPermission,getCarrier } from "../../../utils/action";
import { useParams } from "react-router-dom";
import { Loading } from "../../../components/Loader/Loading";
import Permissions from "./Permission";
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from "primereact/dropdown";
export default function ViewEmployees(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [checked, setChecked] = useState(false);
    const [data, setData] = useState()
    const[error,setError] = useState()
    const[isEmail,setIsEmail] =  useState(false);
    const[isSms,setIsSms] =  useState(false)
    const toast = useRef(null);
    const [permissions, setPermissions] = useState([])
    const [selectedNodes, setSelectedNodes] = useState({});
    const [carrier, setCarrier] = useState("")
    const [selectedCarrier, setSelectedCarrier] = useState(null);
    const [IsId, setIsId] = useState({
        employee_id: ""
    })
    const onUpload = () => {
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    };
    const { id } = useParams()
    useState(() => {
        setIsId({
            employee_id: id
        })
    })

    const showViewData = async () => {
        let response = await viewEmployess({ payload: IsId })
        setData(response.data[0].is_active)
        if(response.data[0].is_email_notify){
            setIsEmail(true)
        }
        if(response.data[0].is_sms_notify){
            setIsSms(true)
        }
        if(response.data[0].is_active){
            setChecked(true)
        }
        setSelectedNodes(response.data[0].selected_nodes)
        response.data[0].dob = new Date(response.data[0].dob.slice(0,10))
        response.data[0].social_security_no = response.data[0].social_security_no ===null?"":response.data[0].social_security_no
        setData(response.data[0])
    }
    useEffect(() => {
        showViewData()
    }, [])
    useEffect(() => {
        async function fetchMyAPI() {
            let response = await getPermission();

            const permissionData = [];

            response.data.map((permission) => {
                const permObj = {
                    key: permission.id,
                    label: permission.type,
                    data: permission.type,
                    icon: '',
                    children: []
                }

                permission.permissions.map(child => {
                    let childObj = {
                        key: permission.id+"-"+child.id,
                        label: child.name,
                        data: child.name
                    }
                    permObj.children.push(childObj);
                });

                 permissionData.push(permObj);
            });
            setPermissions(permissionData);
        }
        fetchMyAPI();
    }, [])
    useEffect(() => {
        async function fetchMyAPI() {
          let response = await getCarrier();
          setCarrier(response.data)
        }
        fetchMyAPI();
      }, [])
      useEffect(() => {
        carrier.length > 0 && carrier?.map((item) => {
          if (item.id == data.carrier) {
            setSelectedCarrier(item)
          }
        })
      }, [carrier,data])
      const handleCarrier = (e) => {
        setSelectedCarrier(e.value)
      }
    return (
        <ContentArea>
            {isLoading ? <Loading /> :
                <div className="p-3">
                    <div className="grid card ml-0 mr-0 px-3 mb-5 align-items-center bg-main-light border-round-lg">
                        <h2 className="text-main">
                            {props.title ? props.title : "Employee Details"}
                        </h2>
                    </div>
                                <div className="shadow-2 p-5 border-round-lg">
                                    <h2 className="text-main mb-5 p-0 m-0 Karla flex align-items-center justify-content-between"> Employee Details
                                        <span className="flex text-lg text-700 disable-input">
                                            In Active &nbsp;&nbsp;  <InputSwitch checked={checked} onChange={(e) => setChecked(e.value)}  />
                                        </span>
                                    </h2>
                                    <div className="p-fluid grid p-0 m-0">
                                        <div className="col-12 md:col-12 p-0 m-0 ">
                                            <p>Upload Image</p>
                                        </div>
                                    </div>
                                    <div className="p-fluid grid flex align-items-center mb-4">
                                        <div className="upload-image col-12 md:col-2">
                                            <img src={data?.image?.length != 0 ? data?.image : EmployeeImage} alt="" width={102} height={109} className="w-full h-full" />
                                        </div>
                                        <div className="col-12 md:col-10">
                                            <div className="upload_image">
                                                <label htmlFor="profile-img" className="bg-primary cursor-pointer w-3 px-5 border-0 p-2 border-round-md disable-input">
                                                    <i className="pi pi-plus mr-3 disable-input"></i>
                                                    <span>Choose a File</span>
                                                </label>
                                                <input type="file" mode="basic" hidden className="file-upload text-white border-round-lg disable-input" id="profile-img" chooseLabel="Choose a file" name="profile-img"
                                                    accept="image/*" maxFileSize={1000000}
                                                />
                                            </div>
                                            <p className="text-500"> Acceptable Formats jpg, png only </p>
                                        </div>
                                    </div>
                                    <div className="p-fluid grid mb-4">
                                        <div className="col-12 md:col-4">
                                            <label className="font block mb-2">Employee Id<code className="text-red" >*</code></label>
                                            <InputText
                                                inputClassName="border-round-md disable-input"
                                                placeholder=""
                                                value={data?.emp_id}
                                            />
                                        </div>
                                        <div className=" col-12 md:col-4">
                                            <label className="font block mb-2"> Employee Email<code className="text-red" >*</code> </label>
                                            <InputText
                                                className="border-round-md disable-input"
                                                placeholder=""
                                                value={data?.email}
                                            />
                                        </div>
                                        <div className=" col-12 md:col-4">
                                            <label className="font block mb-2">Social Security No.</label>
                                            <InputText
                                                placeholder=""
                                                className="border-round-md disable-input"
                                                value={data?.social_security_no}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-fluid grid mb-4">
                                        <div className="col-12 md:col-4">
                                            <label className="font block mb-2">First Name<code className="text-red" >*</code> </label>
                                            <InputText
                                                inputClassName="border-round-md disable-input"
                                                placeholder=" "
                                                value={data?.first_name}
                                            />
                                        </div>
                                        <div className=" col-12 md:col-4">
                                            <label className="font block mb-2">Middle Name</label>

                                            <InputText
                                                className="border-round-md disable-input"
                                                placeholder=""
                                                value={data?.middle_name}
                                            />
                                        </div>
                                        <div className=" col-12 md:col-4">
                                            <label className="font block mb-2">Last Name<code className="text-red" >*</code> </label>
                                            <InputText
                                                className="border-round-md disable-input"
                                                placeholder=""
                                                value={data?.last_name}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-fluid grid mb-4">
                                        <div className="col-12 md:col-4 ">
                                            <label className="font block mb-2"> DOB<code className="text-red" >*</code></label>
                                            <Calendar
                                                className="border-round-md calender_picker disable-input"
                                                inputClassName="calendar p-2 border-round-md  "
                                                placeholder=""
                                                value={data?.dob}
                                                dateFormat="dd/mm/yy"
                                            />
                                        </div>
                                        <div className=" col-12 md:col-4">
                                            <label className="font block mb-2">Carrier<code className="text-red" >*</code></label>
                                            <Dropdown className="border-round-md disable-input " name="carrier" options={carrier} optionLabel="carrier_name" value={selectedCarrier} onChange={(e) => handleCarrier(e)} />
                                        </div>
                                        <div className=" col-12 md:col-4">
                                            <label className="font block mb-2">Phone No.<code className="text-red" >*</code></label>
                                            <InputNumber
                                                className="border-round-md"
                                                inputClassName="border-round-md disable-input"
                                                placeholder=""
                                                value={data?.mobile}
                                                useGrouping={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-fluid grid mb-3">
                                        <div className="col-12 md:col-4 ">
                                            <label className="font block mb-2"> Address 1<code className="text-red" >*</code></label>
                                            <InputText
                                                id="inputtext"
                                                className="border-round-md disable-input"
                                                placeholder=""
                                                value={data?.address_1}
                                            />
                                        </div>
                                        <div className=" col-12 md:col-4">
                                            <label className="font block mb-2"> Address 2 </label>
                                            <InputText
                                                id="inputtext"
                                                className="border-round-md disable-input "
                                                placeholder=""
                                                value={data?.address_2}

                                            />
                                        </div>
                                        <div className="col-12 md:col-4 ">
                                            <label className="font block mb-2"> City<code className="text-red" >*</code></label>
                                            <InputText
                                                id="inputtext"
                                                className="border-round-md disable-input"
                                                placeholder=""
                                                value={data?.city}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-fluid grid mb-4">
                                        <div className="col-12 md:col-4 ">
                                            <label className="font block mb-2"> State<code className="text-red" >*</code></label>
                                            <InputText
                                                id="inputtext"
                                                className="border-round-md disable-input"
                                                placeholder=""
                                                value={data?.state}
                                            />
                                        </div>
                                        <div className="col-12 md:col-4 ">
                                            <label className="font block mb-2"> Zip Code<code className="text-red" >*</code></label>
                                            <InputNumber
                                                id="inputnumber"
                                                className="border-round-md disable-input"
                                                inputClassName="border-round-md"
                                                placeholder=""
                                                value={data?.zipcode}
                                                useGrouping={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-fluid grid mb-4 flex align-items-center">
                                        <div className=" col-12 md:col-4">
                                            <label className="font block mb-2">Set Employee Hourly Rate*</label>
                                            <InputText className="hr border-round-md disable-input" placeholder='/hr' value={data?.hourly_rate} ></InputText>
                                        </div>
                                        <div className=" col-12 md:col-8 mt-5 ">
                                        <Checkbox onChange={e => setIsEmail(e.checked)} checked={isEmail} ></Checkbox>
                                            {/* <input className='form-check-input ml-5 mb-2' type="checkbox"
                                                name="is_email_notify" value = {data?.is_email_notify} /> */}
                                                &nbsp; Check to receive the Email reminder notifications.
                                            <div className="col-12 md:col-12 p-0 m-0 ">
                                            <Checkbox onChange={e =>setIsSms(e.checked)} checked={isSms}></Checkbox>
                                               &nbsp; Check to receive the SMS reminder notifications.
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-main text-2xl font-bold Karla ">Set Login Details</p>
                                    <div className="p-fluid grid mb-4 flex align-items-center">
                                        <div className=" col-12 md:col-4">
                                            <label className="font block mb-2 ">Employee login/ Username<code className="text-red" >*</code></label>
                                            <InputText
                                                className="border-round-md disable-input"
                                                value={data?.username}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-fluid grid mb-4">
                                        <div className=" col-12 md:col-4">
                                            <label className="font block mb-2 "> Employee Password<code className="text-red" >*</code></label>
                                            <InputText
                                                className="border-round-md disable-input"
                                                value={data?.password}
                                            />
                                        </div>
                                        <div className=" col-12 md:col-8 mt-2">
                                            <p className=" text-lg flex align-items-center"> Reset
                                                <span className="">
                                                    <Button
                                                        icon="pi pi-replay"
                                                        className="border-round-3xl  ml-3 "
                                                    ></Button>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <h3 className="text-main ">Set Permissions</h3>
                                    <Permissions  permissions={permissions} disabled={true} selectedNodes={selectedNodes} value={data} setSelectedNodes={setSelectedNodes} setSelectedKeys={setData}setError={setError} />
                                </div>
                          
                    
                </div>
            }
        </ContentArea>
    )
}
