import React from 'react'
import ContentArea from "../../../shared/ContentArea";
import Table from "../../../components/TableList/Table";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import SendCustomNotification from './SendCustomNotification';
export default function ManageCustomNotification() {

    const navigate = useNavigate()
 
    const SendCustomNotification = () => {
        navigate("/send-custom-notification")
    }


    const TableData = [
        {
            SrNo: 1,
            Title: "Payment Pending",
            Message: " Lorem Ipsum is simply dummy text",
            Event: "First Login",
            
        },
        {
            SrNo: 2,
            Title: "Payment Pending",
            Message: " Lorem Ipsum is simply dummy text",
            Event: "First Login",
            

        },
        {
            SrNo: 3,
            Title: "Payment Pending",
            Message: " Lorem Ipsum is simply dummy text",
            Event: "First Login",
            

        },
        {
            SrNo: 4,
            Title: "Payment Pending",
            Message: " Lorem Ipsum is simply dummy text",
            Event: "First Login",
            

        },
        {
            SrNo: 5,
            Title: "Payment Pending",
            Message: " Lorem Ipsum is simply dummy text",
            Event: "First Login",
            

        },
        {
            SrNo: 6,
            Title: "Payment Pending",
            Message: " Lorem Ipsum is simply dummy text",
            Event: "First Login",
            

        },
        {
            SrNo: 7,
            Title: "Payment Pending",
            Message: " Lorem Ipsum is simply dummy text",
            Event: "First Login",
            
        },
        {
            SrNo: 8,
            Title: "Payment Pending",
            Message: " Lorem Ipsum is simply dummy text",
            Event: "First Login",
            

        },
        {
            SrNo: 9,
            Title: "Payment Pending",
            Message: " Lorem Ipsum is simply dummy text",
            Event: "First Login",
            

        },
        {
            SrNo: 10,
            Title: "Payment Pending",
            Message: " Lorem Ipsum is simply dummy text",
            Event: "First Login",
            

        },

    ]

    const TableHeading = [
        { field: "SrNo", header: "Sr. No."},
        { field: "Title", header: "Title"},
        { field: "Message", header: "Message"},
        { field: "Event", header: "Event"},
        { field: "icons", header: "Action", body: "iconBodyTemplate" },
    ];

  return (
    <>
     <ContentArea>
                <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-evenly">
                    <div className="md:col-8 ">
                        <h1 className="text-main  Karla " style={{ fontSize: "24px" }}>
                        Manage Custom Notification
                        </h1>
                    </div>
                    <div className="md:col-3 text-center">
                        <Button label="Send Custom Notification" className="bg-main" onClick={SendCustomNotification} />
                    </div>
                </div>
                <div className="grid mt-2">
                    <div className="md:col-12">
                        <div className="subscription_table">
                            <Table tableHeading={TableHeading}
                                tableData={TableData}                               
                            />
                        </div>
                    </div>
                </div>
            </ContentArea>
    </>
  )
}
