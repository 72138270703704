import React, { useEffect, useState } from "react";
import { Tree } from "primereact/tree";
const Permissions = ({ permissions, selectedNodes, setSelectedNodes, value, setSelectedKeys, disabled ,setError}) => {

    const [expandedKeys, setExpandedKeys] = useState({});
    const getSelectedKeysArray = () => {
        const selectedKeysArrays = []
        for (let keys in selectedNodes) {
            if (keys.length > 1) {
                selectedKeysArrays.push(keys.split('-')[1])
            }
        }
        setSelectedKeys({
            ...value,
            permissions: selectedKeysArrays,
            selected_nodes: selectedNodes
        });
        setError((prev)=>{
            return{
              ...prev,
              permissions:""
            }
          })
    }


    useEffect(() => {
        getSelectedKeysArray()
    }, [selectedNodes])

    useEffect(() => {
        let expanded = {}
        permissions.map(element => {
            expanded[element.key] = true
        })
        setExpandedKeys(expanded)
    
    }, [permissions])
  
    return (
        <div>
            <div className="card flex justify-content-center permissions-container">
                <Tree value={permissions} selectionMode="checkbox" disabled={disabled? true : false} expandedKeys={`${expandedKeys}`} selectionKeys={selectedNodes} onSelectionChange={(e) => setSelectedNodes(e.value)} className="w-full" />
            
            </div>
            <div className="p-fluid grid mb-4">
            </div>
        </div>

    )
}
export default Permissions;