import React, { useState, useEffect } from "react";
import InputBox from "./Inputbox";
import TableAddButton from "./TableAddButton";
import ContentArea from "../../../shared/ContentArea";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { Loading } from "../../../components/Loader/Loading";
import Table from "../../../components/TableList/Table";
import { Card } from "primereact/card";
import { Checkbox } from "primereact/checkbox";
import { viewClient, ViewDiscount, getCarrier } from "../../../utils/action";
import { useParams } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
export default function ViewClient() {
  const [client, setClient] = useState(0)
  const [total, setTotal] = useState(0)
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [IsId, setId] = useState({
    client_id: "",
  });
  const [discount, setDiscount] = useState("")
  const [getDiscount, setGetDiscount] = useState()
  const [SubscriptionPlanTableData, setSubscriptionPlanTableData] = useState("")
  const [finalDiscount, setFinalDiscount] = useState()
  const [carrier, setCarrier] = useState("");
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [selectedCompanyCarrier, setSelectedCompanyCarrier] = useState(null);
  const [selectedCompanyCarrier2, setSelectedCompanyCarrier2] = useState(null);
  const[countPrice,setCountPrice] =  useState(0)
  const[selectedDiscount,setSelectedDiscount] = useState()
  const { id } = useParams();
  useState(() => {
    setId({
      client_id: id,
    });
  });

  const SubscriptionPlanTableHeading = [
    { field: "subscription_plan.program_name", header: "Program Name" },
    { field: "subscription_plan.price_per_person", header: "Price Per Client" },
    { field: "number_of_client", header: "No. of Clients" },
    { field: "created_at", header: "Starting Date" },
    { field: "exp_date", header: "Cancellation Date" },
    { field: "total", header: "Revenue" },
  ];
  const showViewData = async () => {
    setIsLoading(true);
    let response = await viewClient({ payload: IsId });
    setIsLoading(false);
    if (response.data[0].is_active) {
      setChecked(true);
    }
    response.data[0].dob = new Date(response.data[0].dob.slice(0, 10));
    response.data[0].address_2 = response.data[0].address === null ?"":""
    response?.data[0]?.subscription_details?.map((item, index) => {
      let date = new Date(item.created_at)
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();
      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
      item.created_at = year + '-' + month + '-' + dt;
      let dates =  new Date(item.exp_date)
      let years = date.getFullYear();
      let months=  date.getMonth() +1;
      let dts = date.getDate();
      if (dts < 10) {
        dts = '0' + dts;
      }
      if (months < 10) {
        months = '0' + months;
      }
      item.exp_date = years + '-' + months + '-' + dts;
    })
    setData(response.data[0]);
    setSubscriptionPlanTableData(response.data[0].subscription_details)
  };
  const handleDiscount = async () => {
    let res = await ViewDiscount()
    setDiscount(res.data)

  }
  useEffect(() => {
    discount?.length > 0 && discount?.map((item) => {
      data?.subscription_details?.length > 0 && data?.subscription_details?.map((managaeDiscount) => {
        if (item.id == managaeDiscount.manage_discounts_id) {
          setSelectedDiscount(item)
        }
      })
    })
  }, [discount])
  useEffect(() => {
    let client = 0;
    let total = 0;
    SubscriptionPlanTableData.length > 0 && SubscriptionPlanTableData?.map((item) => {
      client += item.number_of_client
      total += item.total
    })
    setClient(client)
    setTotal(total)
    let final = total * selectedDiscount?.discount_rate_percent / 100
    let totalCount = total - final
    setCountPrice(totalCount)
    setFinalDiscount(final)
  }, [SubscriptionPlanTableData, selectedDiscount])
  useEffect(() => {
    showViewData();
    handleDiscount();
  }, []);
  useEffect(() => {
    async function fetchMyAPI() {
      let response = await getCarrier();
      setCarrier(response.data)
    }
    fetchMyAPI();
  }, [])

  useEffect(() => {
    carrier.length > 0 && carrier?.map((item) => {
      if (item.id == data?.carrier) {
        setSelectedCarrier(item)
      }
      if (item.id == data?.company_carrier_1) {
        setSelectedCompanyCarrier(item)
      }
      if (item.id == data?.company_carrier_2) {
        setSelectedCompanyCarrier2(item)
      }
    })
  }, [carrier])
  const handleCarrier = (e) => {
    setSelectedCarrier(e.value)
  }
  const handleCompanyCarrier = (e) => {
    setSelectedCompanyCarrier(e.value)
  }
  const handleCompanyCarrier2 = (e) => {
    setSelectedCompanyCarrier2(e.value)
  }
  function handleGrandTotal(){
    return(
      <>
         <div className="grid  mt-2">
                      <div className="md:col-2 pl-4">
                        <small className="karla">Grand Total</small>
                      </div>
                      <div className="md:col-2 pl-8"></div>
                      <div className="md:col-2 pl-7">
                        <small className="karla">{client}</small>
                      </div>
                      <div className="md:col-4 pl-8">
                        <small className="karla"></small>
                      </div>
                      <div className="md:col-2 pl-7">
                        <small>${total}</small>
                      </div>
                    </div>
                    <div className="grid  mt-2 bg-main-light">
                      <div className="md:col-2 pl-4">
                        <small className="karla">Discount</small>
                      </div>
                      <div className="md:col-2 pl-8"></div>
                      <div className="md:col-2 pl-8">
                        <small className="karla"></small>
                      </div>
                      <div className="md:col-4 pl-8">
                        <small className="karla"></small>
                      </div>
                      <div className="md:col-2 pl-7">
                        <small>${finalDiscount? finalDiscount : 0}</small>
                      </div>
                    </div>
                    <div className="grid  mt-2">
                      <div className="md:col-2 pl-4">
                        <small className="karla">Final Total</small>
                      </div>
                      <div className="md:col-2 pl-8"></div>
                      <div className="md:col-2 pl-8">
                        <small className="karla"></small>
                      </div>
                      <div className="md:col-4 pl-8">
                        <small className="karla"></small>
                      </div>
                      <div className="md:col-2 pl-7">
                        <small>${countPrice}</small>
                      </div>
                    </div>
      </>
    )
  }
  return (
    <>
      <ContentArea>
        {isLoading ? (
          <Loading />
        ) : (
          <div className="p-3">
            <div className="grid card ml-0 mr-0 px-3 mb-5 align-items-center bg-main-light border-round-lg">
              <h2 className="text-main">Edit Client</h2>
            </div>
            <div className="shadow-2 p-5 border-round-lg">
              <h2 className="text-main mb-5 p-0 m-0 Karla flex align-items-center justify-content-between">
                Client Details
                <span className="flex text-lg text-700 disable-input">
                      Active &nbsp;&nbsp;{" "}
                      <InputSwitch
                        checked={checked}
                        onChange={(e) => setChecked(e.value)}
                      />
                    </span>
              </h2>
              <div className="p-fluid grid mb-3">
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Client Id<code className="text-red">*</code>
                  </label>
                  <InputText
                    id=""
                    className="border-round-md disable-input"
                    name="first_name"
                    value={data?.profile_id}
                  />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    First Name<code className="text-red">*</code>
                  </label>
                  <InputText
                    id="inputtext"
                    placeholder=""
                    className="border-round-md disable-input"
                    value={data?.first_name}
                  />
                </div>
                <div className=" col-12 md:col-4">
                  <label className="font block mb-2"> Middle Name </label>
                  <InputText
                    id="inputtext"
                    placeholder=""
                    className="border-round-md disable-input"
                    value={data?.middle_name}
                  />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Last Name<code className="text-red">*</code>
                  </label>
                  <InputText
                    id="inputtext"
                    placeholder=""
                    className="border-round-md disable-input"
                    value={data?.last_name}
                  />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Email<code className="text-red">*</code>
                  </label>
                  <InputText
                    id="inputtext"
                    placeholder=""
                    className="border-round-md disable-input"
                    value={data?.email}
                  />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Carrier*<code className="text-red">*</code>
                  </label>
                  <Dropdown className="border-round-md disable-input" name="carrier" options={carrier} optionLabel="carrier_name" value={selectedCarrier} onChange={(e) => handleCarrier(e)} />
                </div>
                <div className=" col-12 md:col-4">
                  <label className="font block mb-2">
                    Phone No.<code className="text-red">*</code>
                  </label>
                  <InputNumber
                    id="inputnumber"
                    placeholder=""
                    useGrouping={false}
                    className="border-round-md disable-input"
                    inputClassName="border-round-md"
                    value={data?.mobile}
                  />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    DOB<code className="text-red">*</code>
                  </label>
                  <Calendar
                    className="border-round-md calender_picker disable-input"
                    inputClassName="calendar p-2 border-round-md "
                    placeholder=""
                    value={data?.dob}
                  />
                </div>
                <div className=" col-12 md:col-4">
                  <label className="font block mb-2">
                    Social Security No.<code className="text-red">*</code>
                  </label>
                  <InputText
                    id="inputnumber"
                    placeholder=""
                    className="border-round-md "
                    inputClassName="border-round-md"
                    name="mobile"
                    value={data?.social_security_no}
                  />

                  <br />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Address 1<code className="text-red">*</code>
                  </label>
                  <InputText
                    id="inputtext"
                    placeholder=""
                    className="border-round-md disable-input "
                    value={data?.address_1}
                  />
                </div>
                <div className=" col-12 md:col-4">
                  <label className="font block mb-2"> Address 2 </label>
                  <InputText
                    id="inputtext"
                    placeholder=""
                    className="border-round-md disable-input"
                    value={data?.address_2}
                  />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    City<code className="text-red">*</code>
                  </label>
                  <InputText
                    id="inputtext"
                    placeholder=""
                    className="border-round-md disable-input"
                    value={data?.city}
                  />
                </div>
                <div className="col-12 md:col-6 ">
                  <label className="font block mb-2">
                    State<code className="text-red">*</code>
                  </label>
                  <InputText
                    id="inputtext"
                    placeholder=""
                    className="border-round-md disable-input"
                    value={data?.state}
                  />
                </div>
                <div className="col-12 md:col-6 ">
                  <label className="font block mb-2">
                    Zip Code<code className="text-red">*</code>
                  </label>
                  <InputNumber
                    id="inputnumber"
                    placeholder=""
                    className="border-round-md disable-input"
                    inputClassName="border-round-md"
                    value={data?.zipcode}
                    useGrouping={false}
                  />
                </div>
              </div>
              <h2 className="text-main mb-5 p-0 m-0 Karla ">
                Company Details
              </h2>
              <div className="p-fluid grid mb-3">
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Company Name<code className="text-red">*</code>
                  </label>
                  <InputText
                    id="inputtext"
                    placeholder=""
                    className="border-round-md disable-input"
                    value={data?.company_name}
                  />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Company TIN/EIN<code className="text-red">*</code>
                  </label>
                  <InputText
                    id="inputtext"
                    placeholder=""
                    className="border-round-md disable-input"
                    value={data?.company_tin_eim}
                  />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Company Email<code className="text-red">*</code>
                  </label>
                  <InputText
                    id="inputtext"
                    placeholder=""
                    className="border-round-md disable-input"
                    value={data?.company_email}
                  />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Carrier*<code className="text-red">*</code>
                  </label>
                  <Dropdown className="border-round-md disable-input " name="carrier" options={carrier} optionLabel="carrier_name" value={selectedCompanyCarrier} onChange={(e) => handleCompanyCarrier(e)} />
                </div>
                <div className=" col-12 md:col-4">
                  <label className="font block mb-2">
                    Company Contact no.<code className="text-red">*</code>
                  </label>
                  <InputNumber
                    id="inputnumber"
                    placeholder=""
                    useGrouping={false}
                    className="border-round-md disable-input"
                    inputClassName="border-round-md"
                    value={data?.company_mobile}
                  />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Carrier*<code className="text-red">*</code>
                  </label>
                  <Dropdown className="border-round-md disable-input " name="carrier" options={carrier} optionLabel="carrier_name" value={selectedCompanyCarrier2} onChange={(e) => handleCompanyCarrier2(e)} />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Phone No.<code className="text-red">*</code>
                  </label>
                  <InputNumber
                    id="inputnumber"
                    placeholder=""
                    useGrouping={false}
                    className="border-round-md disable-input"
                    inputClassName="border-round-md"
                    value={data?.company_alt_mobile}
                  />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Address 1<code className="text-red">*</code>
                  </label>
                  <InputText
                    id="inputtext"
                    placeholder=""
                    className="border-round-md disable-input"
                    value={data?.company_address_1}
                  />
                </div>
                <div className=" col-12 md:col-4">
                  <label className="font block mb-2"> Address 2 </label>
                  <InputText
                    id="inputtext"
                    placeholder=""
                    className="border-round-md disable-input"
                    value={data?.company_address_2}
                  />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    City<code className="text-red">*</code>
                  </label>
                  <InputText
                    id="inputtext"
                    placeholder=""
                    className="border-round-md disable-input"
                    value={data?.company_city}
                  />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    State<code className="text-red">*</code>
                  </label>
                  <InputText
                    id="inputtext"
                    placeholder=""
                    className="border-round-md disable-input"
                    value={data?.company_state}
                  />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Zip Code<code className="text-red">*</code>
                  </label>
                  <InputNumber
                    id="inputnumber"
                    placeholder=""
                    className="border-round-md disable-input"
                    inputClassName="border-round-md"
                    value={data?.company_zipcode}
                    useGrouping={false}
                  />
                </div>
              </div>
              <Card className="shadow-2 text-main border-round-lg mb-5">
                <h2 className="text-main mb-3 p-0 m-0 Karla ">
                  Subscription Plan Details
                </h2>
                <div className="grid program_table">
                  <div className="md:col-12">
                    <Table
                      tableHeading={SubscriptionPlanTableHeading}
                      tableData={SubscriptionPlanTableData}
                      title = "viewClient"
                      handleGrandTotal={handleGrandTotal()}
                    />
                 
                  </div>
                </div>
              </Card>
            </div>
          </div>
        )}
      </ContentArea>
    </>
  );
}
