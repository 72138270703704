import React, { useEffect, useState } from "react";
import AddButton from "../../../components/Buttons/AddButton";
import ContentArea from '../../../shared/ContentArea';
import Table from "../../../components/TableList/Table";
import SearchIcon from "../../../assets/icons/search.png";
import FilterIcon from "../../../assets/icons/filter.png";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import Deleteimg from "../../../assets/images/deleted.png";
import Index from "../Index";
import AddEmployees from "./AddEmployees";
import { FilterMatchMode } from 'primereact/api';
import ViewEmployees from "./ViewEmployees";
import EditEmployees from "./EditEmployees";
import { getEmployee, deletEmployee, getCarrier } from "../../../utils/action";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loading } from "../../../components/Loader/Loading";
toast.configure()
export default function ManageEmployees() {
    const { isNextPage, setIsNextPage } = Index();
    const [editButton, setEditButton] = useState(false)
    const [tableData, setTableData] = useState([])
    const { viewButton, setViewButton } = useState();
    const [deleteButton, setDeleteButton] = useState();
    const [data, setData] = useState("")
    const [isloading, setIsLoading] = useState(false);
    const [carrier, setCarrier] = useState("")
    const [selectedCarrier, setSelectedCarrier] = useState(null)
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [isShowFilter, setIsShowFilter] = useState(false);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },

    });
    const navigate = useNavigate()
    const handleGetEmployee = async () => {
        setIsLoading(true)
        let response = await getEmployee({ payload: data });
        response?.data?.length > 0 && response.data?.map((item) => {
            carrier?.length > 0 && carrier.map((carrId) => {
                if (item.carrier == carrId.id) {
                    item.carrier = carrId.carrier_name
                }
            })
        })
        setIsLoading(false)
        setTableData(response.data);

    }
    useEffect(() => {
        handleGetEmployee();
    }, [carrier])
    let id = data
    const handleDelete = async () => {
        setIsLoading(true)
        let response = await deletEmployee(id)
        if (response.status === false) {
            setIsLoading(false)
            toast.error(response.message || "Something went Wrong")
            setDeleteButton(false)
        }
        else if (response.status === true) {
            toast.success(response.message || "Employee Delete Successfully")
            setDeleteButton(false)
            setIsLoading(false)
            handleGetEmployee()
        }

    }
    const addEmployee = () => {
        setIsNextPage(!isNextPage)
        navigate("/add-employess")
    }
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
        setIsShowFilter(false)
    };
    const tableHeading = [
        { field: "SrNo", header: "Sr. No.", body: "srnoTemplates" },
        { field: "emp_id", header: "Employee Id" },
        { field: "name", header: "Employee Name" },
        { field: "email", header: "Email" },
        { field: "carrier", header: "Mobile Carrier" },
        { field: "mobile", header: "Contact No." },
        { field: "is_active", header: "Status", body: "activeTemplate" },
        { field: "icons", header: "Action", body: "imageBodyTemp" },
    ];
    const clearFilter = (value) => {
        setIsShowFilter(false)
        setGlobalFilterValue(value)
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            is_active: { value: null, matchMode: FilterMatchMode.EQUALS }
        })
    }
    useEffect(() => {
        async function fetchMyAPI() {
            let response = await getCarrier();
            setCarrier(response.data)
        }
        fetchMyAPI();
    }, [])
    useEffect(() => {
        carrier.length > 0 && carrier.map((item, index) => {
            if (item.id == tableData[0]?.carrier) {
                setSelectedCarrier(item)
            }
        })
    }, [])
    return (
        <>
            {
                !isNextPage &
                    !editButton
                    //   & !viewButton 
                    ?
                    <ContentArea>
                        <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-evenly">
                            <div className="md:col-5 ">
                                <h1 className="text-main  " style={{ fontSize: "24px" }}>
                                    Manage Employees
                                </h1>
                            </div>
                            <div className="md:col-3">
                                <div className="search-box w-full">
                                    <img src={SearchIcon} alt="Search Icon" width="15px" height="15px" />
                                    <input type="search" placeholder="Search" onChange={onGlobalFilterChange} onClick={() => setIsShowFilter(false)} />
                                </div>
                            </div>
                            <div className="md:col-1 relative bg-main px-3 border-round-md w-max  ">
                                <div onClick={() => setIsShowFilter(prev => !prev)} className="bg-main border-round-md text-white text-center text-base relative karla">
                                    Filter &nbsp;
                                    <img src={FilterIcon} alt="" width={15} /></div>
                                {
                                    isShowFilter ?
                                        < div className="flex flex-column gap-2 filter-popup" style={{ display: isShowFilter ? "block" : "none" }} >
                                            <label htmlFor="verified-filter" className="font-bold">
                                                Status
                                            </label>
                                            <select name="" id="" onChange={onGlobalFilterChange} className="p-2">
                                                <option value="">{globalFilterValue === "0" ? "Inactive" : globalFilterValue === "1" ? "Active" : "Select"}</option>
                                                <option value="0">Inactive</option>
                                                <option value="1">Active</option>
                                            </select>
                                            <div>
                                                <Button type="button" label="Clear" className="mr-3" onClick={()=>clearFilter("select")} severity="secondary"></Button>
                                            </div>
                                        </div >
                                        : null
                                }
                            </div>
                            <div className="md:col-2 w-max">
                                <AddButton handleClick={addEmployee} />
                            </div>
                        </div>
                        {isloading ? <Loading /> :
                            <div className="grid mt-2">
                                <div className="md:col-12">
                                    <Table
                                        tableHeading={tableHeading}
                                        tableData={tableData}
                                        setEditButton={setEditButton}
                                        setViewButton={setViewButton}
                                        filters={filters}
                                        setDeleteButton={setDeleteButton}
                                        setData={setData}
                                        title="empTable"
                                    />
                                </div>
                            </div>
                        }
                    </ContentArea>
                    : null
            }
            {
                isNextPage ? <AddEmployees /> : null
            }
            {
                editButton ? <EditEmployees /> : null
            }
            {
                viewButton ? <ViewEmployees /> : null
            }
            {
                deleteButton ?
                    <Dialog
                        className="flex justify-content-center"
                        visible={deleteButton}
                        onHide={() => setDeleteButton(false)}
                        style={{ width: '30vw' }}>
                        <p className="m-0">
                            <div className=" flex justify-content-center">
                                <img src={Deleteimg} alt="" width={150} height={127}></img>
                            </div>
                            <div className="text-center mb-5">
                                <h4 className=" p-0 m-0 ">Are you sure you want to delete this user?</h4>
                                <p className="text-xs text-400">All associated data will also be deleted! There is no undo!</p>
                            </div>
                            <div className=" btn flex justify-content-center">
                                <div className="text-center">
                                    <Button
                                        label="Cancel"
                                        className="w-full surface-500 p-3 px-5    border-0 border-round-md "
                                        onClick={() => setDeleteButton(false)}
                                    />
                                </div>
                                <div className="text-center">
                                    <Button
                                        label="Delete"
                                        className=" w-full px-5 border-0 p-3 ml-3 border-round-md bg-red-500"
                                        onClick={handleDelete}
                                    />
                                </div>
                            </div>
                        </p>
                    </Dialog> : null
            }
        </>
    )
}
