import React, { useState } from "react";
import Logo from "../../assets/icons/resmedx-logo-blue.png";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Route, Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import axios from "axios";
import { forgetPasswordEmail } from "../../utils/action";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loading } from "../../components/Loader/Loading";
import { validEmail } from "../../shared/Validation";
toast.configure();
export default function ForgotPassword() {
  let navigate = useNavigate();
  const [data, setData] = useState({
    email: ""
  })
  const [error, setError] = useState({ email: "" });
  const [isLoading, setIsLoading] = useState(false);
  const sendOtp = async (e) => {
    try {
      e?.preventDefault();
      setIsLoading(true)
      if (data.email === "" || !validEmail(data.email)) {
        setError({
          ...error,
          email: data.email === "" ? "Please Enter Email" : !validEmail(data.email)?"Please Enter Valid Email":error?.email,
        });
        setIsLoading(false)
        return false;
      }
      let response = await forgetPasswordEmail({ payload: data });
      if (response.success === false) {
        toast.error(response.message||"Something went wrong during login.")
        setIsLoading(false)
      }
      if (response.success === true) {
        setIsLoading(false)
        localStorage.setItem("email",data.email)
        navigate("/verify-password")
      }
    } catch (err) {
      let message =
        err && err.message ? err.message : "Something went wrong during login";
      toast.error(err?.response?.data?.message);
      setIsLoading(false)
      return false;
    }
  };

  return (
    <>
     
        <div className="bg-main h-screen">
          <div className="flex h-full justify-content-center align-items-center">
          {isLoading ? <Loading /> :
            <div
              className="card bg-white p-3 w-4
                   border-round-2xl "
            >
              <div className="grid ">
                <div className="md:col-12 text-center">
                  <img src={Logo} width="117px" height="92px" alt="ResMedX" />

                  <h1 className="font-bold Karla" style={{ color: " #525252" }}>
                    Forgot password
                  </h1>
                </div>
              </div>
              <div className="grid flex justify-content-center">
                <div className="md:col-10">
                  <p className="text-center  Karla" style = {{color:"#717171"}}>
                    Forgot your password? Enter  your  email  address  and  we’ll  send
                    you  a  link  to  reset  it.
                  </p>
                </div>
              </div>

              <div className="grid flex justify-content-center">
                <div className="md:col-10 mb-3">
                  <label className="mb-2 font-bold text-xs">Email</label>
                  <InputText
                    className="w-full mt-1 border-round"
                    placeholder=""
                    name="email"
                    id=""
                    onChange={(e) => {
                      setError({
                        ...error,
                        email: null,
                      });
                      setData({ ...data, email: e.target.value });
                    }}
                  />
                  {error.email && <span className="error">{error.email}</span>}
                </div>
              </div>

              <div className="grid flex justify-content-center">
                <div className="md:col-10 mb-5">
                  <Button
                    onClick={() => sendOtp()}
                    label="Continue"
                    labelClassName="text-xl font-bold"
                    className="p p-3 w-full bg-main border-round-lg font-bold"
                  />
                </div>
              </div>
            </div>
          }
          </div>
        </div>
    

    </>
  );
}
