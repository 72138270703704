
import React, { useState, useEffect } from "react";
import AddButton from "../../../components/Buttons/AddButton";
import ContentArea from '../../../shared/ContentArea'
import Table from "../../../components/TableList/Table";
import SearchIcon from "../../../assets/icons/search.png";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import Deleteimg from "../../../assets/images/deleted.png";
import { getSubscription, getDescription, deleteDescription } from "../../../utils/action";
import { useNavigate } from "react-router-dom";
import Index from "../Index";
import { FilterMatchMode } from 'primereact/api';
import AddSubscriptionPlan from "./AddSubscriptionPlan";
import { Loading } from "../../../components/Loader/Loading";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure()
export default function SubscriptionPlan() {
    const navigate = useNavigate()
    const { isNextPage, setIsNextPage } = Index();
    const [editButton, setEditButton] = useState(false)
    const [deleteButton, setDeleteButton] = useState(false)
    const [descriptionButton, setDescriptionButton] = useState(false)
    const [tableData, setTableData] = useState([])
    const [data, setData] = useState("")
    const [showDescription, setShowDescription] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [isShowFilter, setIsShowFilter] = useState(false);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },

    });
    const tableHeading = [
        { field: "SrNo", header: "Sr. No.", body: "srnoTemplates" },
        { field: "program_name", header: "Program Name", sortable: true },
        { field: "price_per_person", header: "Price Per Person" },
        { field: "fileicon", header: "Description", body: "viewIconBodyTemplate" },
        { field: "icons", header: "Action", body: "subscriptionBodyTemp" },

    ];
const  handleGetSubscription =  async()=>{
    setIsLoading(true)
    let response = await getSubscription({ payload: data });
    setIsLoading(false);
    setTableData(response.data.reverse());
}
    useEffect(() => {
        handleGetSubscription()
    }, [])
    const addSubscription = () => {
        setIsNextPage(!isNextPage)
        navigate("/add-subscription")
    }

    const handleShowDescription = async (id) => {
        setIsLoading(true)
        let response = await getDescription(id)
        if (response.status === false) {
            setIsLoading(false)
            toast.error("Something went wrong")
        } else if (response.status === true) {
            setIsLoading(false)
            setShowDescription(response.data)
        }
    }
    let id = data
    const handleDelete = async () => {
       setIsLoading(true)
       let response =  await deleteDescription(id)
       if(response.status ===  true){
        setIsLoading(false);
        toast.success(response.message || "Subscription Plan deleted Successfully");
        setDeleteButton(false);
        handleGetSubscription()
       }
       else {
        setIsLoading(false);
        toast.error(response.message ||"Something went wrong");
        setDeleteButton(false);
       }
      
    }
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    return (
        <>
        {isLoading?<Loading/>:
         <div>
         {
             !isNextPage && !editButton ?
                 <ContentArea>
                     <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-evenly">
                         <div className="md:col-7 ">
                             <h1 className="text-main  Karla " style={{ fontSize: "24px" }}>
                                 Subscription Plan
                             </h1>
                         </div>
                         <div className="md:col-3">
                             <div className="search-box w-full">
                                 <img src={SearchIcon} alt="Search Icon" width="15px" height="15px" />
                                 <input type="search" placeholder="Search" onChange={onGlobalFilterChange} />
                             </div>
                         </div>
                         <div className="md:col-2 w-max">
                             <AddButton handleClick={addSubscription} />
                         </div>
                     </div>
                     <div className="grid mt-2">
                         <div className="md:col-12">
                             <div className="subscription_table">
                                 <Table tableHeading={tableHeading}
                                     tableData={tableData}
                                     setEditButton={setEditButton}
                                     setDeleteButton={setDeleteButton}
                                     setDescriptionButton={setDescriptionButton}
                                     handleShowDescription={handleShowDescription}
                                     descriptionButton={descriptionButton}
                                     setData={setData}
                                     filters={filters}
                                     title="subscriptionTable"
                                 />
                             </div>
                         </div>
                     </div>
                 </ContentArea>
                 : null
         }
         {
             isNextPage ? <AddSubscriptionPlan /> : null
         }
         {
             editButton ? <AddSubscriptionPlan /> : null
         }
         {
             deleteButton ?
                 <Dialog
                     className="flex justify-content-center"
                     visible={deleteButton}
                     onHide={() => setDeleteButton(false)}
                     style={{ width: '30vw' }}>
                     {isLoading ? <Loading /> :
                         <p className="m-0">
                             <div className=" flex justify-content-center">
                                 <img src={Deleteimg} alt="" width={150} height={127}></img>
                             </div>
                             <div className="text-center mb-5">
                                 <h4 className=" p-0 m-0 ">Are you sure you want to delete this user?</h4>
                                 <p className="text-xs text-400">All associated data will also be deleted! There is no undo!</p>
                             </div>
                             <div className=" btn flex justify-content-center">
                                 <div className="text-center">
                                     <Button
                                         label="Cancel"
                                         className="w-full surface-500 p-3 px-5    border-0 border-round-md "
                                         onClick={() => setDeleteButton(false)}
                                     />
                                 </div>
                                 <div className="text-center">
                                     <Button
                                         label="Delete"
                                         className=" w-full px-5 border-0 p-3 ml-3 border-round-md bg-red-500"
                                         onClick={handleDelete}
                                     />
                                 </div>
                             </div>
                         </p>
                     }

                 </Dialog> : null
         }
         {
             descriptionButton ?
                 <Dialog
                     onClick={() => setDescriptionButton(false)}
                     className="flex justify-content-center"
                     visible={descriptionButton}
                     onHide={() => setDescriptionButton(false)}
                     style={{ width: '50vw' }}>
                     {isLoading ? <Loading /> :
                         <p className="m-0">
                             <h3>Description</h3>
                             <p className="text-xs text-500 mb-5">
                                 {showDescription}
                             </p>
                             <div className=" btn flex justify-content-center">
                                 <div className="text-center">
                                     <Button
                                         label="OK"
                                         className="w-full p-3 px-5 border-0 border-round-lg "
                                         onClick={() => setDeleteButton(false)}
                                     />
                                 </div>
                             </div>
                         </p>
                     }
                 </Dialog> : null
         }
         </div>
        }
       
        </>
    )
}
