import React, { useState, useEffect } from "react";
import ContentArea from "../../../shared/ContentArea";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import Table from "../../../components/TableList/Table";
import { Card } from "primereact/card";
import { Loading } from "../../../components/Loader/Loading";
import { addClient, ViewDiscount, getSubscription, getCarrier } from "../../../utils/action";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { validatePhoneNumber, validateZipcodeNumber, validEmail } from "../../../shared/Validation";
import DropdownMenu from "../../../components/Dropdown/DropdownMenu.jsx";
import { Dropdown } from "primereact/dropdown";

toast.configure();

function AddClients(props) {
  const navigate = useNavigate();
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [selectedCompanyCarrier, setSelectedCompanyCarrier] = useState(null);
  const [selectedCompanyCarrier2, setSelectedCompanyCarrier2] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [discount, setDiscount] = useState([])
  const [carrier, setCarrier] = useState("")
  const [programData, setProgramData] = useState()
  const [totalClient, setTotalClient] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)
  const [finalPrice, setFinalPrice] = useState(0)
  const [countPrice, setCountPrice] = useState(0)
  const [data, setData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    mobile: "",
    dob: "",
    social_security_no: "",
    address_1: "",
    address_2: "",
    state: "",
    city: "",
    zipcode: "",
    carrier: "",
    company_name: "",
    company_mobile: "",
    company_tin_eim: "",
    company_email: "",
    company_alt_mobile: "",
    company_address_1: "",
    company_address_2: "",
    company_city: "",
    company_state: "",
    company_zipcode: "",
    is_active: "1",
    program_details: [],
    manage_discounts_id: "",
    company_carrier_1: "",
    company_carrier_2: "",
  });
  const [error, setError] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    mobile: "",
    dob: "",
    social_security_no: "",
    address_1: "",
    address_2: "",
    state: "",
    city: "",
    zipcode: "",
    company_name: "",
    company_mobile: "",
    company_tin_eim: "",
    company_email: "",
    company_alt_mobile: "",
    company_address_1: "",
    company_address_2: "",
    company_city: "",
    company_state: "",
    company_zipcode: "",
    company_carrier_1: "",
    company_carrier_2: "",
  });
  const ProgramHeading = [
    { selectionMode: "multiple" },
    { field: "program_name", header: "Program Name" },
    { field: "price_per_person", header: "Price Per Client" },
    { field: "NoofClients", header: "No. of Clients", body: "tableAddButton" },
    { field: "Total", header: "Total", body: "totalTemp" },
  ];

  const cancelButton = () => {
    navigate("/manage-client");
  };
  useEffect(() => {
    async function fetchMyAPI() {
      let response = await ViewDiscount();
      setDiscount(response.data)
      setData((prev) => {
        return {
          ...prev,
          manage_discounts_id: selectedDiscount?.id,
          program_details: selectedProducts
        }
      })
      setError((prev)=>{
        return{
          ...prev,
          program_details:""
        }
      })
    }
    fetchMyAPI();
  }, [selectedDiscount, selectedProducts])
  useEffect(() => {
    async function fetchMyAPI() {
      let response = await getCarrier();
      setCarrier(response.data)
    }
    fetchMyAPI();
  }, [])
  useEffect(() => {
    async function fetchMyAPI() {
      let response = await getSubscription();
      let program = []
      response.data.map((item, index) => {
        const payload = {
          subscription_plans_id: item.id,
          program_name: item.program_name,
          price_per_person: item.price_per_person,
          number_of_client: 0,
          total: 0
        }
        program.push(payload)
        setProgramData(program)
      })

    }
    fetchMyAPI();
  }, [])
  const handelChange = (e) => {
    setData({
      ...data,
      [e?.target?.name]: e?.target?.value,
    });
    setError({
      ...error,
      [e?.target?.name]: "",
    });
  };
  let date = new Date(data.dob)
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();
  if (dt < 10) {
    dt = '0' + dt;
  }
  if (month < 10) {
    month = '0' + month;
  }
  let dob = year + '-' + month + '-' + dt;
  const Addclient = async (e) => {
    setData({
      ...data,
      program_details: selectedProducts
    })
    setIsLoading(true);
    try {
      e.preventDefault();
      if (
        data.first_name === "" || data.last_name === "" || data.email === "" || data.mobile === "" || data.address_1 === "" || data.dob === "" || data.city === "" || data.state === "" ||
        data.zipcode === "" || data.company_name === "" || data.company_address_1 === "" || data.company_email === "" || data.company_tin_eim === "" || data.company_mobile === "" ||
        data.company_alt_mobile === "" || data.company_state === "" || data.company_city === "" || data.company_zipcode === null || !validatePhoneNumber(data.mobile) || !validatePhoneNumber(data.company_alt_mobile) ||
        !validatePhoneNumber(data.company_mobile) || !validateZipcodeNumber(data.zipcode) || !validateZipcodeNumber(data.company_zipcode)  || data.carrier === "" || data.company_carrier_1 === "" || data.company_carrier_2 === "" || data.program_details === null ||
        !validEmail(data.email) || !validEmail(data.company_email) || data.program_details ===undefined
      ) {
        setError({
          ...error,
          clientId: data.clientId === "" ? "Please Enter Client_Id" : error.clientId,
          carrier: data.carrier === "" ? "Please Enter Carrier" : error.carrier,
          company_carrier_1: data.company_carrier_1 === "" ? "Please Enter Company Carrier" : error.company_carrier_1,
          company_carrier_2: data.company_carrier_2 === "" ? "Please Enter Company Carrier" : error.company_carrier_2,
          first_name: data.first_name === "" ? "Please Enter First Name" : error.first_name,
          last_name: data.last_name === "" ? "Please Enter  Last Name" : error?.last_name,
          email: data.email === "" ? "Please Enter Email" : !validEmail(data.email) ? "Please Enter Valid Email" : error?.email,
          mobile: data.mobile === "" ? "Please Enter Mobile" : !validatePhoneNumber(data.mobile) ? "Please Enter Valid Mobile Number" : error.mobile,
          address_1: data.address_1 === "" ? "Please Enter Address" : error.address_1,
          dob: data.dob === "" ? "Please Enter DOB" : error.dob,
          city: data.city === "" ? "Please Enter City" : error.city,
          state: data.state === "" ? "Please Enter State" : error.state,
          zipcode: data.zipcode === "" ? "Please Enter Zip Code" : !validateZipcodeNumber(data.zipcode) ? "Please Enter Valid Zip Code" : error.zipcode,
          company_name: data.company_name === "" ? "Please Enter Company Name" : error.company_name,
          company_address_1: data.company_address_1 === "" ? "Please Enter Company Address" : error.company_address_1,
          company_tin_eim: data.company_tin_eim === "" ? "Please Enter Company TIN/EIN" : error.company_tin_eim,
          company_mobile: data.company_mobile === "" ? "Please Enter Company Mobile" : !validatePhoneNumber(data.company_mobile) ? "Please Enter Valid Mobile Number" : error.company_mobile,
          company_alt_mobile: data.company_alt_mobile === "" ? "Please Enter Alternate Mobile Number" : !validatePhoneNumber(data.company_alt_mobile) ? "Please Enter Valid Mobile Number" : error.company_alt_mobile,
          company_state: data.company_state === "" ? "Please Enter Company State" : error.company_state,
          company_city: data.company_city === "" ? "Please Enter Company City" : error.company_city,
          company_email: data.company_email === "" ? "Please Enter  Email" : !validEmail(data.company_email) ? "Please Enter Valid Email" : error.company_email,
          company_zipcode: data.company_zipcode === null ? "Please Enter Company Zip Code" : !validateZipcodeNumber(data.company_zipcode) ? "Please Enter Company Valid Zip Code" : error.zipcode,
          program_details: data.program_details === null ? " Select Program " : data.program_details === undefined?"Select Program":error.program_details,
        });
        setIsLoading(false);
        return false;
      }
      const formData = new FormData();
      formData.append("first_name", data.first_name);
      formData.append("middle_name", data.middle_name);
      formData.append("last_name", data.last_name);
      formData.append("email", data.email);
      formData.append("client_carrier", data.carrier);
      formData.append("mobile", data.mobile);
      formData.append("dob", dob);
      formData.append("social_security_no", data.social_security_no);
      formData.append("address_1", data.address_1);
      formData.append("address_2", data.address_2);
      formData.append("city", data.city);
      formData.append("state", data.state);
      formData.append("zipcode", data.zipcode);
      formData.append("is_active", data.is_active);
      formData.append("company_tin_eim", data.company_tin_eim);
      formData.append("company_name", data.company_name);
      formData.append("company_email", data.company_email);
      formData.append("company_mobile", data.company_mobile);
      formData.append("company_alt_mobile", data.company_alt_mobile);
      formData.append("company_address_1", data.company_address_1);
      formData.append("company_address_2", data.company_address_2);
      formData.append("company_city", data.company_city);
      formData.append("company_state", data.company_state);
      formData.append("company_zipcode", data.company_zipcode);
      formData.append("program_details", JSON.stringify(data.program_details));
      formData.append("manage_discounts_id", data.manage_discounts_id);
      formData.append("company carrier_1", data.company_carrier_1);
      formData.append("company_carrier_2", data.company_carrier_2);
      let response = await addClient({ payload: formData });
      if (response.status === false) {
        setIsLoading(false);
        toast.error(response.message || "Something went Wrong");
      }
      if (response.status === true) {
        setIsLoading(false);
        toast.success(response?.message || "Client Created Successfully");
        setData({
          first_name: "",
          middle_name: "",
          last_name: "",
          email: "",
          mobile: "",
          dob: "",
          social_security_no: "",
          address_1: "",
          address_2: "",
          state: "",
          city: "",
          zipcode: "",
          company_name: "",
          company_mobile: "",
          company_tin_eim: "",
          company_email: "",
          company_alt_mobile: "",
          company_address_1: "",
          company_address_2: "",
          company_city: "",
          company_state: "",
          company_zipcode: "",
        });
        navigate("/manage-client");
        return true;
      }
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err.message ? err.message : "Something Went Wrong during login";
      toast.error(err?.response?.data?.message);
      return false;
    }
  };
  useEffect(() => {
    let client = 0;
    let total = 0;
    programData?.map((item) => {
      client += item.number_of_client
      total += item.total
    })
    setTotalClient(client)
    setTotalPrice(total)
    let final = totalPrice * selectedDiscount?.discount_rate_percent / 100
    let totalCount = totalPrice - final
    setCountPrice(totalCount)
    setFinalPrice(final)
  }, [programData, selectedDiscount, totalClient])
  const handleCarrier = (e) => {
    setSelectedCarrier(e.value)
    setData({
      ...data,
      carrier: e.value.id,
    })
    setError({
      ...error,
      carrier:""
    })
  }

  const handleCompanyCarrier = (e) => {
    setSelectedCompanyCarrier(e.value)
    setData({
      ...data,
      company_carrier_1: e.value.id
    })
    setError({
      ...error,
      company_carrier_1:""
    })
  }
  const handleCompanyCarrier2 = (e) => {
    setSelectedCompanyCarrier2(e.value)
    setData({
      ...data,
      company_carrier_2: e.value.id
    })
    setError({
      ...error,
      company_carrier_2:""
    })
  }
  function handleGrandTotal(){
    return(
      <>
      <div className="grid justify-content-between mt-2">
      <div className="md:col-2 pl-8">
        <small className="karla">Grand Total</small>
      </div>
      <div className="md:col-3 pl-8"></div>
      <div className="md:col-2 pl-8">
        <small className="karla">{totalClient}</small>
      </div>
      <div className="md:col-2 pl-7">
        <small>${totalPrice}</small>
      </div>
    </div>
    <div className="grid justify-content-between mt-2 bg-main-light">
      <div className="md:col-2 pl-8">
        <small className="karla">Discount</small>
      </div>
      <div className="md:col-3 pl-8"></div>
      <div className="md:col-2 pl-8">
        <small className="karla"></small>
      </div>
      <div className="md:col-2 pl-7">
        <small>${finalPrice ? finalPrice : "0"}</small>
      </div>
    </div>
    <div className="grid justify-content-between mt-2">
      <div className="md:col-2 pl-8">
        <small className="karla">Final Total</small>
      </div>
      <div className="md:col-3 pl-8"></div>
      <div className="md:col-2 pl-8">
        <small className="karla"></small>
      </div>
      <div className="md:col-2 pl-7">
        <small>${countPrice ? countPrice : "0"}</small>
      </div>
    </div>
    </>
    )
  }
  return (
    <>
      <ContentArea>
        {isLoading ? (
          <Loading />
        ) : (
          <div className="p-3">
            <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
              <h2 className="text-main">
                {props.title ? props.title : "Add Client"}
              </h2>
            </div>
            <div className="shadow-2 p-5 border-round-lg">
              <h2 className="text-main mb-5 p-0 m-0 Karla ">Client Details</h2>
              <div className="p-fluid grid mb-3">
                {/* <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Client Id
                  </label>
                  <InputText
                    id=""
                    className="border-round-md "
                    name="clientId"
                    onChange={(e) => handelChange(e)}
                  />
                  {error.clientId && <span className="error"> {error.clientId} </span>}
                </div> */}
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    First Name<code className="text-red">*</code>
                  </label>
                  <InputText
                    id=""
                    value={data.first_name}
                    className="border-round-md "
                    name="first_name"
                    onChange={(e) => handelChange(e)}
                  />
                  {error.first_name && (
                    <span className="error">{error.first_name}</span>
                  )}
                  <br />
                </div>
                <div className=" col-12 md:col-4">
                  <label className="font block mb-2"> Middle Name </label>
                  <InputText
                    id=""
                    className="border-round-md "
                    value={data.middle_name}
                    name="middle_name"
                    onChange={(e) => handelChange(e)}
                  />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Last Name<code className="text-red">*</code>
                  </label>
                  <InputText
                    id=""
                    className="border-round-md "
                    value={data.last_name}
                    name="last_name"
                    onChange={(e) => handelChange(e)}
                  />
                  {error.last_name && (
                    <span className="error">{error.last_name}</span>
                  )}
                  <br />
                </div>

                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    {" "}
                    Email<code className="text-red">*</code>
                  </label>
                  <InputText
                    id=""
                    className="border-round-md "
                    value={data.email}
                    name="email"
                    onChange={(e) => handelChange(e)}
                  />
                  {error.email && <span className="error">{error.email}</span>}
                  <br />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Carrier<code className="text-red">*</code>
                  </label>
                  <Dropdown className="border-round-md "  name="carrier" options={carrier} optionLabel="carrier_name" value={selectedCarrier} onChange={(e) => handleCarrier(e)} />
                  {error.carrier && <span className="error">{error.carrier}</span>}
                </div>
                <div className=" col-12 md:col-4">
                  <label className="font block mb-2">
                    Phone No.<code className="text-red">*</code>
                  </label>
                  <InputNumber
                    id=""
                    className="border-round-md "
                    inputClassName="border-round-md"
                    Value={data.mobile}
                    useGrouping={false}
                    name="mobile"
                    maxLength ={10}
                    onValueChange={(e) => {
                      setError({
                        ...error,
                        mobile: null,
                      });
                      setData({
                        ...data,
                        mobile: e.value,
                      });
                    }}
                  />
                  {error.mobile && (
                    <span className="error">{error.mobile}</span>
                  )}
                  <br />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    DOB<code className="text-red">*</code>
                  </label>
                  <Calendar
                    className="border-round-md calender_picker "
                    inputClassName="calendar p-2 border-round-md "
                    value={data.dob}
                    placeholder=""
                    name="dob"
                    onChange={(e) => {
                      setError({
                        ...error,
                        dob: null,
                      });
                      setData({ ...data, dob: e.value });
                    }}
                  />
                  {error.dob && <span className="error">{error.dob}</span>}
                  <br />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">Social Security No.</label>
                  <InputText
                    id=""
                    className="border-round-md "
                    name="social_security_no"
                    onChange={(e) => handelChange(e)}
                    value={data.social_security_no}
                  />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Address 1<code className="text-red">*</code>
                  </label>
                  <InputText
                    id=""
                    className="border-round-md "
                    name="address_1"
                    onChange={(e) => handelChange(e)}
                    value={data.address_1}
                  />
                  {error.address_1 && (
                    <span className="error">{error.address_1}</span>
                  )}
                  <br />
                </div>
                <div className=" col-12 md:col-6">
                  <label className="font block mb-2"> Address 2</label>
                  <InputText
                    id=""
                    className="border-round-md "
                    name="address_2"
                    onChange={(e) => handelChange(e)}
                    value={data.address_2}
                  />
                </div>

                <div className="col-12 md:col-6 ">
                  <label className="font block mb-2">
                    City<code className="text-red">*</code>
                  </label>
                  <InputText
                    id=""
                    className="border-round-md "
                    name="city"
                    onChange={(e) => handelChange(e)}
                    value={data.city}
                  />
                  {error.city && <span className="error">{error.city}</span>}
                  <br />
                </div>
                <div className="col-12 md:col-6 ">
                  <label className="font block mb-2">
                    State<code className="text-red">*</code>
                  </label>
                  <InputText
                    id=""
                    className="border-round-md "
                    onChange={(e) => handelChange(e)}
                    name="state"
                    value={data.state}
                  />
                  {error.state && <span className="error">{error.state}</span>}
                </div>
                <div className="col-12 md:col-6 ">
                  <label className="font block mb-2">
                    Zip Code<code className="text-red">*</code>
                  </label>
                  <InputNumber
                    id=""
                    className="border-round-md "
                    inputClassName="border-round-md"
                    useGrouping={false}
                    value={data.zipcode}
                    maxLength={5}
                    name="zipcode"
                    onValueChange={(e) => {
                      setError({
                        ...error,
                        zipcode: null,
                      });
                      setData({
                        ...data,
                        zipcode: e.value,
                      });
                    }}
                  />
                  {error.zipcode && (
                    <span className="error">{error.zipcode}</span>
                  )}
                </div>
              </div>
              <h2 className="text-main mb-5 p-0 m-0 Karla ">Company Details</h2>
              <div className="p-fluid grid mb-3">
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Company Name<code className="text-red">*</code>
                  </label>
                  <InputText
                    id=""
                    className="border-round-md "
                    name="company_name"
                    onChange={(e) => handelChange(e)}
                    value={data.company_name}
                  />
                  {error.company_name && (
                    <span className="error">{error.company_name}</span>
                  )}
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Company TIN/EIN<code className="text-red">*</code>
                  </label>
                  <InputNumber
                    id=""
                    useGrouping={false}
                    inputClassName="border-round-md "
                    value={data.company_tin_eim}
                    name="company_tin_eim"
                    onValueChange={(e) => {
                      setError({
                        ...error,
                        company_tin_eim: null,
                      });
                      setData({
                        ...data,
                        company_tin_eim: e.value,
                      });
                    }}
                  />
                  {error.company_tin_eim && (
                    <span className="error">{error.company_tin_eim}</span>
                  )}
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Company Email<code className="text-red">*</code>
                  </label>
                  <InputText
                    id=""
                    className="border-round-md "
                    name="company_email"
                    onChange={(e) => handelChange(e)}
                    value={data.company_email}
                  />
                  {error.company_email && (
                    <span className="error">{error.company_email}</span>
                  )}
                </div>
                <div className="col-12 md:col-6">
                  <label className="font block mb-2">
                    Carrier<code className="text-red">*</code>
                  </label>
                  <Dropdown className="border-round-md " name="carrier" options={carrier} optionLabel="carrier_name" value={selectedCompanyCarrier} onChange={(e) => handleCompanyCarrier(e)} />
                  {error.company_carrier_1 && <span className="error">{error.company_carrier_1}</span>}
                </div>
                <div className=" col-12 md:col-6">
                  <label className="font block mb-2">
                    Company Contact no.<code className="text-red">*</code>
                  </label>
                  <InputNumber
                    id=""
                    inputClassName="border-round-md"
                    useGrouping={false}
                    name="company_mobile"
                    value={data.company_mobile}
                    maxLength={10}
                    onValueChange={(e) => {
                      setError({
                        ...error,
                        company_mobile: null,
                      });
                      setData({
                        ...data,
                        company_mobile: e.value,
                      });
                    }}
                  />
                  {error.company_mobile && (
                    <span className="error">{error.company_mobile}</span>
                  )}
                </div>
                <div className="col-12 md:col-6 ">
                  <label className="font block mb-2">
                    Carrier<code className="text-red">*</code>
                  </label>
                  <Dropdown className="border-round-md " name="carrier" options={carrier} optionLabel="carrier_name" value={selectedCompanyCarrier2} onChange={(e) => handleCompanyCarrier2(e)} />
                  {error.company_carrier_2 && <span className="error">{error.company_carrier_2}</span>}
                </div>
                <div className="col-12 md:col-6 ">
                  <label className="font block mb-2">
                    Phone No.<code className="text-red">*</code>
                  </label>
                  <InputNumber
                    id=""
                    inputClassName="border-round-md"
                    name="company_alt_mobile"
                    useGrouping={false}
                    value={data.company_alt_mobile}
                    maxLength={10}
                    onValueChange={(e) => {
                      setError({
                        ...error,
                        company_alt_mobile: null,
                      });
                      setData({
                        ...data,
                        company_alt_mobile: e.value,
                      });
                    }}
                  />
                  {error.company_alt_mobile && (
                    <span className="error">{error.company_alt_mobile}</span>
                  )}
                </div>
                <div className="col-12 md:col-6 ">
                  <label className="font block mb-2">
                    Address 1<code className="text-red">*</code>
                  </label>
                  <InputText
                    id=""
                    className="border-round-md "
                    name="company_address_1"
                    onChange={(e) => handelChange(e)}
                    value={data.company_address_1}
                  />
                  {error.company_address_1 && (
                    <span className="error">{error.company_address_1}</span>
                  )}
                </div>
                <div className=" col-12 md:col-6">
                  <label className="font block mb-2"> Address 2 </label>
                  <InputText
                    id=""
                    className="border-round-md "
                    name="company_address_2"
                    onChange={(e) => handelChange(e)}
                    value={data.company_address_2}
                  />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    City<code className="text-red">*</code>
                  </label>
                  <InputText
                    id=""
                    className="border-round-md "
                    name="company_city"
                    onChange={(e) => handelChange(e)}
                    value={data.company_city}
                  />
                  {error.company_city && (
                    <span className="error">{error.company_city}</span>
                  )}
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    State<code className="text-red">*</code>
                  </label>
                  <InputText
                    id=""
                    className="border-round-md "
                    name="company_state"
                    onChange={(e) => handelChange(e)}
                    value={data.company_state}
                  />
                  {error.company_state && (
                    <span className="error">{error.company_state}</span>
                  )}
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Zip Code<code className="text-red">*</code>
                  </label>
                  <InputNumber
                    id=""
                    className="border-round-md "
                    // inputClassName="border-round-md"
                    name="company_zipcode"
                    useGrouping={false}
                    value={data.company_zipcode}
                    maxLength={5}
                    onValueChange={(e) => {
                      setError({
                        ...error,
                        company_zipcode: null,
                      });
                      setData({
                        ...data,
                        company_zipcode: e.value,
                      });
                    }}
                  />
                  {error.company_zipcode && (
                    <span className="error">{error.company_zipcode}</span>
                  )}
                </div>
              </div>
              <Card className="shadow-2 text-main border-round-lg mb-5">
                <div className="grid justify-content-between align-items-center mb-3">
                  <h2 className="text-main mb-3 p-0 m-0 Karla md:col-3">
                    Program Details
                  </h2>
                  <Dropdown
                    className="border-round-md md:col-3"
                    placeholder="Apply Discount"
                    options={discount}
                    optionLabel="discount_code"
                    value={selectedDiscount} onChange={(e) => setSelectedDiscount(e.value)}
                  />
                </div>

                <div className="grid program_table">
                  <div className="md:col-12">
                    <Table
                      tableHeading={ProgramHeading}
                      tableData={programData}
                      setSelectedProducts={setSelectedProducts}
                      selectedProducts={selectedProducts}
                      setProgramData={setProgramData}
                      programData={programData}
                      title = "addClient"
                      handleGrandTotal  ={handleGrandTotal()}
                    />
                  </div>
                  {error.program_details && <span className="error">{error.program_details}</span>}
                </div>
              </Card>
              <div className=" btn flex">
                <div className="text-center">
                  <Button
                    onClick={Addclient}
                    label="Save"
                    className=" w-full p-3 px-5  border-0 border-round-md bg-main"
                  />
                </div>
                <div className="text-center">
                  <Button
                    label="Cancel"
                    className="w-full surface-500 p-3  ml-5 border-0 border-round-md "
                    onClick={cancelButton}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </ContentArea>
    </>
  );
}
export default AddClients;
