import React, { useEffect, useState } from "react";
import ContentArea from "../../../shared/ContentArea";
import AddButton from "../../../components/Buttons/AddButton";
import { Dialog } from 'primereact/dialog';
import { useNavigate } from "react-router-dom";
import Index from "../Index";
import AddNotification from "./AddNotification";

export default function Notification() {
    const navigate = useNavigate()
    const [visible, setVisible] = useState(false);

    const { isNextPage, setIsNextPage } = Index();

    useEffect(() => {
        setIsNextPage(false)
    }, [])
    const addNotification = () => {
        setIsNextPage(!isNextPage)
        navigate("/add-notification")
    }
    return (
        <>
            {
                !isNextPage ?
                    <ContentArea>
                        <div className="p-3">
                            <div className="grid mb-5 align-items-center bg-main-light border-round-lg shadow-1">
                                <div className="md:col-12 p-0 m-0">
                                    <h1 className="text-main px-5 flex justify-content-between Karla" style={{ fontSize: "24px" }}>
                                        Notifications
                                        {/* <span>
                                            <AddButton handleClick={addNotification} />
                                        </span> */}
                                    </h1>
                                </div>
                            </div>
                            <div className='grid flex align-items-center shadow-1 border-round-lg mb-4 '>
                                <div className='md:col-10 p-0 m-0 p-2'>
                                    <p className='font-semibold text-xl p-0 m-0 mb-2'>
                                        <span className="disk"> . </span> <span className='text-main '>  19 Feb 2023  </span>- Lorem Ipsum is simply.
                                    </p>
                                    <p className='text-400 p-0 m-0'>
                                        Lorem Ipsum is simply Dummy text of the printing and typesetting industry. Lorem Ipsum has
                                        <span className='text-main ml-2 underline' onClick={() => setVisible(true)}>
                                            view more.
                                        </span>
                                    </p>
                                </div>
                                <div className='md:col-2 p-0 m-0'>
                                    <p className='text-main font-semibold text-xl'> 05:00 am </p>
                                </div>
                            </div>
                            <div className='grid flex align-items-center shadow-1 border-round-lg mb-4 '>
                                <div className='md:col-10 p-0 m-0 p-2'>
                                    <p className='font-semibold text-xl p-0 m-0 mb-2'>
                                        <span className="disk"> . </span>  <span className='text-main '> 18 Feb 2023  </span>- Lorem Ipsum is simply.
                                    </p>
                                    <p className='text-400 p-0 m-0'>
                                        Lorem Ipsum is simply Dummy text of the printing and typesetting industry. Lorem Ipsum has
                                        <span className='text-main ml-2 underline' onClick={() => setVisible(true)} >
                                            view more.
                                        </span>
                                    </p>
                                </div>
                                <div className='md:col-2 p-0 m-0'>
                                    <p className='text-main font-semibold text-xl'> 05:00 am </p>
                                </div>
                            </div>
                            <div className='grid flex align-items-center shadow-1 border-round-lg mb-4 '>
                                <div className='md:col-10 p-0 m-0 p-2'>
                                    <p className='font-semibold text-xl p-0 m-0 mb-2'>
                                        <span className="disk"> . </span>   <span className='text-main '> 17 Feb 2023  </span>- Lorem Ipsum is simply.
                                    </p>
                                    <p className='text-400 p-0 m-0'>
                                        Lorem Ipsum is simply Dummy text of the printing and typesetting industry. Lorem Ipsum has
                                        <span className='text-main ml-2 underline' onClick={() => setVisible(true)} >
                                            view more.
                                        </span>
                                    </p>
                                </div>
                                <div className='md:col-2 p-0 m-0'>
                                    <p className='text-main font-semibold text-xl'> 05:00 am </p>
                                </div>
                            </div>
                            <div className='grid flex align-items-center shadow-1 border-round-lg mb-4 '>
                                <div className='md:col-10 p-0 m-0 p-2'>
                                    <p className='font-semibold text-xl p-0 m-0 mb-2'>
                                        <span className="disk"> . </span>  <span className='text-main '> 16 Feb 2023  </span>- Lorem Ipsum is simply.
                                    </p>
                                    <p className='text-400 p-0 m-0'>
                                        Lorem Ipsum is simply Dummy text of the printing and typesetting industry. Lorem Ipsum has
                                        <span className='text-main ml-2 underline' onClick={() => setVisible(true)} >
                                            view more.
                                        </span>
                                    </p>
                                </div>
                                <div className='md:col-2 p-0 m-0'>
                                    <p className='text-main font-semibold text-xl'> 05:00 am </p>
                                </div>
                            </div>
                            <div className='grid flex align-items-center shadow-1 border-round-lg mb-4 '>
                                <div className='md:col-10 p-0 m-0 p-2'>
                                    <p className='font-semibold text-xl p-0 m-0 mb-2'>
                                        <span className="disk"> . </span>   <span className='text-main '> 15 Feb 2023  </span>- Lorem Ipsum is simply.
                                    </p>
                                    <p className='text-400 p-0 m-0'>
                                        Lorem Ipsum is simply Dummy text of the printing and typesetting industry. Lorem Ipsum has
                                        <span className='text-main ml-2 underline' onClick={() => setVisible(true)} >
                                            view more.
                                        </span>
                                    </p>
                                </div>
                                <div className='md:col-2 p-0 m-0'>
                                    <p className='text-main font-semibold text-xl'> 05:00 am </p>
                                </div>
                            </div>
                            <div className='grid flex align-items-center shadow-1 border-round-lg mb-4 '>
                                <div className='md:col-10 p-0 m-0 p-2'>
                                    <p className='font-semibold text-xl p-0 m-0 mb-2'>
                                        <span className="disk"> . </span>   <span className='text-main '> 14 Feb 2023  </span>- Lorem Ipsum is simply.
                                    </p>
                                    <p className='text-400 p-0 m-0'>
                                        Lorem Ipsum is simply Dummy text of the printing and typesetting industry. Lorem Ipsum has
                                        <span className='text-main ml-2 underline' onClick={() => setVisible(true)} >
                                            view more.
                                        </span>
                                    </p>
                                </div>
                                <div className='md:col-2 p-0 m-0'>
                                    <p className='text-main font-semibold text-xl'> 05:00 am </p>
                                </div>
                            </div>
                            <div className='grid flex align-items-center shadow-1 border-round-lg mb-4  '>
                                <div className='md:col-10 p-0 m-0 p-2'>
                                    <p className='font-semibold text-xl p-0 m-0 mb-2'>
                                        <span className="disk"> . </span>  <span className='text-main '> 13 Feb 2023  </span>- Lorem Ipsum is simply.
                                    </p>
                                    <p className='text-400 p-0 m-0'>
                                        Lorem Ipsum is simply Dummy text of the printing and typesetting industry. Lorem Ipsum has
                                        <span className='text-main ml-2 underline' onClick={() => setVisible(true)} >
                                            view more.
                                        </span>
                                    </p>
                                </div>
                                <div className='md:col-2 p-0 m-0'>
                                    <p className='text-main font-semibold text-xl'> 05:00 am </p>
                                </div>
                            </div>
                        </div>
                        {/* Notification View More Dailog */}
                        <Dialog
                            className="p-0 m-0 border-round-lg flex justify-content-center"
                            visible={visible}
                            style={{ width: '50vw' }}
                            onHide={() => setVisible(false)}>
                            <div className="p-0">
                                <p className="text-main mb-3 text-xl fon-medium">19 Feb 2023 05:00 am </p>
                                <p className="font-medium text-2xl mb-3">Lorem Ipsum is simply</p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                    when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                            </div>
                        </Dialog>
                    </ContentArea>
                    : null
            }
            {
                isNextPage ? <AddNotification /> : null
            }
        </>
    )
}
