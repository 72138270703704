import React from "react";
import LineChartData from './lineChartData'
import { Chart } from "primereact/chart";
import DropdownIcon from "../../../assets/icons/arrow-down-black.png"


export default function LineChart() {

  const { lineStylesData, basicOptions } = LineChartData();
  // console.log("LineChartData -->", lineStylesData)
  return (
    <div>
      <div className="p-3 border-solid border-1 border-round-md surface-overlay border-500" style={{ height: "270px" }}>
        <p className="m-0 mb-3 text-main text-lg flex align-items-center justify-content-between font-bold Karla">Sales Person Revenue Stats

          <span className="text-xs text-900 bg-blue-100 border-round px-2 flex align-items-center  p-2" >
          Tom Hanks &nbsp;
            <img src={DropdownIcon} alt="" width={8} height={5} className="" />
          </span>


        </p>
        <Chart type="line" data={lineStylesData} options={basicOptions} height="172px" />
      </div>
    </div>
  );
}
