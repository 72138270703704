export function validatePhoneNumber(input_str) {
    var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  
    return re.test(input_str);
  }

  export function validateZipcodeNumber(input_str) {
    var re =  /^\d{5}[-\s]?(?:\d{4})?$/gm;
    return re.test(input_str);
  }

export function validEmail(input_str){
  const emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, "gm");
  return emailRegex.test(input_str);
}