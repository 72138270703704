import React from 'react'
import { Chart } from "primereact/chart";
import RevenueChartData from "./revenueStatusData"
import DropdownIcon from "../../../assets/icons/arrow-down-black.png"





export default function RevenueStatus() {
  const { basicOptions, lineStylesData } = RevenueChartData();
  return (
    <div>
      <div className="p-3 border-solid border-1 border-round-md surface-overlay border-500" style={{ height: "270px" }}>
        <p className="m-0 mb-3 text-main text-lg flex align-items-center justify-content-between font-bold Karla ">Total Revenue Stats

          <span className="text-xs text-900 bg-blue-100 border-round px-2 flex align-items-center  p-2" >
            Month &nbsp;
            <img src={DropdownIcon} alt="" width={8} height={5} className="" />
          </span>


        </p>
        <Chart type="line" data={lineStylesData} options={basicOptions} height="189px" />
      </div>
    </div>
  )
}
