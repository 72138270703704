
import React, { useEffect, useState } from "react";
import ContentArea from '../../../shared/ContentArea'
import Table from "../../../components/TableList/Table";
import SearchIcon from "../../../assets/icons/search.png";
import BillingDiscount from "../Billing/BillingDiscount";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import Deleteimg from "../../../assets/images/deleted.png";
import { ViewDiscount,deleteDiscount } from "../../../utils/action";
import Index from "../Index";
import AddDiscount from "./AddDiscount";
import { Loading } from "../../../components/Loader/Loading";
import { toast } from "react-toastify";
import { FilterMatchMode } from 'primereact/api';
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import AddButton from "../../../components/Buttons/AddButton";
toast.configure()
export default function ManageDiscount() {
  const { isNextPage, setIsNextPage } = Index();
  const [editButton, setEditButton] = useState(false)
  const [deleteButton, setDeleteButton] = useState(false)
  const [tableData, setTableData] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const[data,setData] = useState("")
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },

});
const navigate = useNavigate();
const onGlobalFilterChange = (e) => {
  const value = e.target.value;
  let _filters = { ...filters };
  _filters['global'].value = value;
  setFilters(_filters);
  setGlobalFilterValue(value);
};
  useEffect(() => {
    setIsNextPage(false)
  }, [])
  const AddDiscountButton = (

    <BillingDiscount handleClick={setIsNextPage} />

  );
  const handleShowDiscount = async () => {
    setIsLoading(true)
    let response = await ViewDiscount()
    setTableData(response.data.reverse())
    setIsLoading(false)
  }
  useEffect(() => {
    handleShowDiscount()
  }, [])
  let id = data
  const handleDelete = async () => {
    let response = await deleteDiscount(id)
    if (response.status === false) {
      setIsLoading(false)
      toast.error(response.message || "Something went Wrong")
      setDeleteButton(false)
    }
    else if (response.status === true) {
      setIsLoading(false)
      toast.success(response.message || "client Delet Successfully")
      setDeleteButton(false)
      handleShowDiscount()
    }
  }
  const tableHeading = [

    { field: "SrNo", header: "Sr. No.", body: "srnoTemplates" },
    { field: "discount_code", header: "Discount Name" , sortable: true},
    // { field: "subscription_plan.program_name", header: "Program" },
    { field: "discount_rate_percent", header: "Discount Rate %" },
    // { field: "AddDiscountButton", header: AddDiscountButton },
    { field: "icons", header: "Action", body: "subscriptionBodyTemp" },

  ];
  const AddDiscount = () => {
    setIsNextPage(!isNextPage)
    navigate("/add-discount")
}

  return (
    <>
      {isLoading ? <Loading /> :
        <div>
          {
            !isNextPage && !editButton ?
              <ContentArea>
                <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-evenly ">
                  <div className="md:col-7 ">
                    <h1 className="text-main   Karla" style={{ fontSize: "24px" }}>
                      Manage Discount
                    </h1>
                  </div>
                  <div className="md:col-3">
                    <div className="search-box w-full ">
                      <img src={SearchIcon} alt="Search Icon" width="15px" height="15px" />
                      <input type="search" placeholder="Search"  onChange={onGlobalFilterChange}/>
                    </div>
                  </div>
                  <div className="md:col-2 w-max">
                    <AddButton handleClick={AddDiscount}>Add</AddButton>
                  </div>
                </div>
                <div className="grid mt-2">
                  <div className="md:col-12">
                    <div className="manage_discount_datatable">
                      <Table
                        tableHeading={tableHeading}
                        tableData={tableData}
                        setEditButton={setEditButton}
                        setDeleteButton={setDeleteButton}
                        setData = {setData}
                        filters={filters}
                        title="manageDiscount" />
                    </div>
                  </div>
                </div>
              </ContentArea>
              : null
          }
          {
            isNextPage ? <AddDiscount /> : null
          }
          {
            editButton ? <AddDiscount /> : null
          }
          {
            deleteButton ?
              <Dialog
                className="flex justify-content-center"
                visible={deleteButton}
                onHide={() => setDeleteButton(false)}
                style={{ width: '30vw' }}>
                <p className="m-0">
                  <div className=" flex justify-content-center">
                    <img src={Deleteimg} alt="" width={150} height={127}></img>
                  </div>
                  <div className="text-center mb-5">
                    <h4 className=" p-0 m-0 ">Are you sure you want to delete this user?</h4>
                    <p className="text-xs text-400">All associated data will also be deleted! There is no undo!</p>
                  </div>
                  <div className=" btn flex justify-content-center">
                    <div className="text-center">
                      <Button
                        label="Cancel"
                        className="w-full surface-500 p-3 px-5    border-0 border-round-md "
                        onClick={() => setDeleteButton(false)}
                      />
                    </div>
                    <div className="text-center">
                      <Button
                        label="Delete"
                        className=" w-full px-5 border-0 p-3 ml-3 border-round-md bg-red-500"
                        onClick={handleDelete}
                      />
                    </div>
                  </div>
                </p>
              </Dialog> : null
          }
        </div>
      }

    </>
  )
}
