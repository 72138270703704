import React, { useEffect, useState } from "react";
import Logo from "../assets/icons/resmedx-logo-blue.png";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Route, Link, Navigate, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import OTPInput, { ResendOTP } from "otp-input-react";
import axios from "axios";
import { verifyOtps, forgetPasswordEmail } from "../utils/action";
import { toast } from "react-toastify";
export default function VerifyPassword() {
  let navigate = useNavigate();
  const [OTP, setOTP] = useState();
  const [counter, setCounter] = useState(60);
  const [error, setError] = useState({ OTP: "" });
  const [data, setData] = useState("");
  console.log(OTP, "==OTP++++")
  const verifyOtp = async () => {
    try {
      if (OTP === undefined) {
        setError({
          ...error,
          OTP: OTP === undefined ? "Please Enter OTP" : error.OTP
        })
        return false;
      }
      let response = await verifyOtps({ payload: data })
      if (response.success === true) {
        navigate("/reset-password")
      }
    } catch (err) {
      console.log(err)
      toast.error(err?.response?.data?.message)
    }
  }
  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000)
    }
  }, [counter]);
  const resendOtp = async () => {

    setCounter(60);
    let response = await forgetPasswordEmail({ payload: data });
    if (response.success === false) {
      toast.error(response.message || "Something went wrong during login.")
    }
    if (response.success === true) {
      localStorage.setItem("email", data.email)
    }
  }

  useEffect(() => {
    setData({
      email: localStorage.getItem('email'),
      otp: OTP
    })
  }, [OTP])

  let email = localStorage.getItem('email');
  let hashedEmail = "";
  for (let i in email) {
    if (i > 2 && i < email.length - 5) {
      hashedEmail += '*'
    }
    else {
      hashedEmail += email[i];
    }
    if (email[i] === '@') {
      hashedEmail += '@'
    }
  }



  return (
    <>
      <div className="bg-main h-screen">
        <div className="flex h-full justify-content-center align-items-center">
          <div
            className="card bg-white p-3 w-4
          border-round-2xl "
          >
            <div className="grid">
              <div className="md:col-12 text-center">
                <img src={Logo} width="117px" height="92px" alt="ResMedX" />

                <div className="mb-4 Karla">
                  <h1 className="font-bold Karla" style={{ color: " #525252" }}>
                    OTP Verification{" "}
                  </h1>
                  <span className="text-xs">
                    Code has been send to registered email:{" "}
                  </span>
                  <br />
                  <span className="text-xs">
                    {" "}
                    {hashedEmail}
                  </span>
                </div>
              </div>
            </div>

            <div className="grid  flex justify-content-center align-items-center mb-3">
              <div className="md:col-9  flex flex-column align-items-center">
                {/* <label className="mb-2 font-bold text-xs"></label> */}
                {/* <Password
                  className="w-full mt-1"
                  inputClassName="w-full border-round"
                  toggleMask
                /> */}
                <div className="otp m-auto">
                  <OTPInput
                    className="otpinput flex
    flex-wrap
    justify-content-center;
    align-items-center;
}"
                    value={OTP}
                    onChange={setOTP}
                    autoFocus
                    OTPLength={4}
                    otpType="number"
                    disabled={false}
                  // secure
                  />
                </div>
                {error.OTP && <span className="error">{error.OTP}</span>}
                <div className="resendcode mt-4 m-auto">
                  {" "}
                  <p className="text-main text-center fw-semibold">
                    {counter === 0 ? <span onClick={resendOtp} className="text-color-main font-bold">Resend Code </span> : <><span className="text-color-main font-bold">Resend Code </span><span className="text-color font-bold">in</span> {counter}
                      <span className="text-color font-bold ml-1">s</span></>}
                  </p>
                  {/* <ResendOTP
                    className=" resendotp-btn flex flex-row-reverse"
                    onResendClick={() => console.log("Resend clicked")}
                  /> */}
                </div>
              </div>
            </div>
            {/* <div className="grid flex justify-content-center mb-3">
              <div className="md:col-10">
                <label className="mb-2 font-bold text-xs">
                  Confirm Password
                </label>
                <Password
                  className="w-full mt-1"
                  inputClassName="w-full border-round"
                  toggleMask
                />
              </div>
            </div> */}

            <div className="grid flex justify-content-center">
              <div className="md:col-10 mb-5">
                <Button
                  onClick={() => verifyOtp()}
                  label="Continue "
                  className="p p-3 w-full bg-main border-round"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
