import React, { useEffect, useState } from "react";
import Table from "../../../components/TableList/Table";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { getClientDetailById, ViewDiscount, viewCommisionReportById, getSubscriptionReportsById } from "../../../utils/action";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Loading } from "../../../components/Loader/Loading";
export default function ViewReports(props) {
  const [data, setData] = useState()
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [isLoading, setIsLoading] = useState(false)
  const [tableData, setTableData] = useState()
  const [noOfClient, setNoOfClient] = useState()
  const [discount, setDiscount] = useState()
  const [discountData, setDiscountData] = useState()
  const [showDiscount, setShowDiscount] = useState(0)
  const [finalPrice, setFinalPrice] = useState(0)
  const [totalData, setTotalData] = useState()
  const { id } = useParams();
  const navigate = useNavigate()
  const redirectPage = () => {
    if(location.pathname.includes("/subscription-report")){
      navigate("/subscription-report")
    }
    else if(location.pathname.includes("/view-commission-report")){
      navigate("/commision-report")
    }else if(location.pathname.includes("/view-report")){
      navigate("/client-report")
    }
    
  }
  const clientData = async () => {
    setIsLoading(true)
    let client = 0;
    let totalrevenue = 0;
    let array = []

    let response = await getClientDetailById(id)
    response.data?.subscription_details?.map((item) => {
      client += item.number_of_client
      let date = new Date(item.created_at)
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();
      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
      item.created_at = year + '-' + month + '-' + dt;
      let dates = new Date(item.exp_date)
      let years = date.getFullYear();
      let months = date.getMonth() + 1;
      let dts = date.getDate();
      if (dts < 10) {
        dts = '0' + dts;
      }
      if (months < 10) {
        months = '0' + months;
      }
      item.exp_date = years + '-' + months + '-' + dts;
      totalrevenue += item.total
      array.push(item)

    })
    setNoOfClient(client)
    array.push(
      {

        "subscription_plan": {
          "program_name": "Grand Total",
        }
      }
    )
    array.push(
      {

        "subscription_plan": {
          "program_name": "Discount",
        }
      }
    )
    array.push(
      {

        "subscription_plan": {
          "program_name": "Final Total",
        }
      }
    )
    setTableData(array)
    setData(response.data)
    setTotalRevenue(totalrevenue)
    setIsLoading(false)
  }
  const showDiscountId = async () => {
    let response = await ViewDiscount()
    setDiscount(response.data)
  }
  const CommissionReport = async () => {

    setIsLoading(true)
    let array = []
    let objectTotal
    let response = await viewCommisionReportById(id)
    setData(response.data)
    let date = new Date(response.data?.created_at)
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();
    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }
    response.data.created_at = month + '-' + dt + '-' + year;
    array.push(response.data)
    array.push(
      {
        "created_at": "Grand Total"
      }
    )
    setTableData(array)
    response.data?.commission_details?.length > 0 && response.data?.commission_details?.map((item) => {
      objectTotal = item.total
    })
    setTotalData(objectTotal)
    setIsLoading(false)
  }
  const SubscriptionReport = async () => {
    setIsLoading(true)
    let totalrevenue = 0;
    let client = 0;
    let array = []
    let response = await getSubscriptionReportsById(id)
    let dates = new Date(response.data.exp_date)
    let years = dates.getFullYear();
    let months = dates.getMonth() + 1;
    let dts = dates.getDate();
    if (dts < 10) {
      dts = '0' + dts;
    }
    if (months < 10) {
      months = '0' + months;
    }
    client  += response.data?.number_of_client
    totalrevenue += response?.data?.total
    response.data.exp_date = dts + '-' + months + '-' + years;
    let date = new Date(response.data.created_at)
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();
    if (dts < 10) {
      dts = '0' + dts;
    }
    if (months < 10) {
      months = '0' + months;
    }
    response.data.created_at = dt + '-' + month + '-' + year;
    setData(response.data.client)
    setNoOfClient(client)
    array.push(response.data)
    array.push(
      {

        "subscription_plan": {
          "program_name": "Grand Total",
        }
      }
    )
    array.push(
      {

        "subscription_plan": {
          "program_name": "Discount",
        }
      }
    )
    array.push(
      {

        "subscription_plan": {
          "program_name": "Final Total",
        }
      }
    )
    setTableData(array)
    setTotalRevenue(totalrevenue)
    setIsLoading(false)
  }
  const location = useLocation()
  useEffect(() => {
    if (location.pathname.includes("/view-report")) {
      clientData()
      showDiscountId()
    } else if (location.pathname.includes("/view-commission-report")) {
      CommissionReport()
    } else if (location.pathname.includes("/subscription-report")) {
      SubscriptionReport()
      showDiscountId()
    }
  }, [])

  console.log(tableData,"====tableData====")
  useEffect(() => {
    let final = 0;
    let discountDataCalculate
    let finalDiscounts = 0;
    discount?.length > 0 && discount?.map((item) => {
      console.log(item,"====item====")
      tableData?.map((name) => {
        if (item.id == name.manage_discounts_id) {
          discountDataCalculate = item.discount_rate_percent
        }
      })
    })
    setDiscountData(discountDataCalculate)
   
  }, [discount, tableData])
  useEffect(()=>{
    let final = 0;
    let discountDataCalculate
    let finalDiscounts = 0;
    finalDiscounts = totalRevenue * discountData / 100
    let totalCount = totalRevenue - finalDiscounts
    setFinalPrice(parseInt(totalCount))
    setShowDiscount(parseInt(finalDiscounts))
  },[discountData,totalRevenue])
  const tableHeading = [
    { field: "subscription_plan.program_name", header: "Program Name" },
    { field: "subscription_plan.price_per_person", header: "Price Per Client" },
    { field: "number_of_client", header: "No. of Clients", body: "noofClientTemplate" },
    { field: "created_at", header: "Starting Date" },
    { field: "exp_date", header: "Cancellation Date" },
    { field: "revenue", header: "Revenue", body: "totalRevenueTemplate" },
  ];
  const commissionHeading = [
    { field: "created_at", header: "Date", sortable: true },
    { field: "name", header: "Clients" },
    { field: "number_of_client", header: "No. of Clients", body: "reportsSalesTemplate" },
    { field: "TotalRevenue", header: "Total Revenue", body: "reportsRevenueTemplate" },
    { field: "payout", header: "Payout", body: "payoutTemplate" },
    { field: "total", header: "Total", body: "totalCommissionTemplate" },
  ]
  const subscriptionHeading = [
    { field: "subscription_plan.program_name", header: "Program Name" },
    { field: "subscription_plan.price_per_person", header: "Price Per Client" },
    { field: "number_of_client", header: "No. of Clients",body: "noofClientTemplate" },
    { field: "created_at", header: "Starting Date", },
    { field: "exp_date", header: "Cancellation Date" },
    { field: "revenue", header: "Revenue",body: "totalRevenueTemplate"  },
  ]
  return (
    <>
      {isLoading === true ? <Loading /> :
        <div className="p-5">
          <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 ">
            <div className="md:col-12 p-0 m-0">
              <h1
                className="text-main px-5 flex justify-content-between Karla"
                style={{ fontSize: "24px" }}
              >
               {location?.pathname?.includes("/subscription-report") ? "Subscription Report":location?.pathname?.includes("/view-commission-report")?"Commissions Report":location?.pathname?.includes("/view-report") ? "Clients Report":""}
              </h1>
            </div>
          </div>
          <div className="p-fluid grid mb-4">
            <div className="col-12 md:col-6 ">
              <h1 className=" border-radius text-white bg-main w-full p-3 text-lg font-bold px-3 ">
             {location.pathname?.includes("/view-report") ? "Client Details": location.pathname?.includes("/view-commission-report") ? "Sales Person Details":location?.pathname?.includes("/subscription-report") ? "Client Details" :""}   
              </h1>
              <div
                className="card h-23rem  shadow-2 border-round-lg ml-0 mr-0 p-4 "
                style={{ background: "#FFFFFF" }}
              >
                <div className="p-fluid grid  ">
                  <div className="col-12 md:col-7 p-0 m-0">
                    <p className="">
                      Name<span className="text-red"> * </span>
                    </p>
                  </div>
                  <div className="col-12 md:col-5 p-0 m-0">
                    <p className="text-color">{data?.name}</p>
                  </div>
                  <div className="col-12 md:col-7 m-0 p-0 ">
                    <p>
                      Email<span className="text-red"> * </span>
                    </p>
                  </div>
                  <div className="col-12 md:col-5 m-0 p-0">
                    <p className="text-color">{data?.email}</p>
                  </div>
                  <div className="col-12 md:col-7 m-0 p-0 ">
                    <p>
                      Phone No<span className="text-red"> * </span>
                    </p>
                  </div>
                  <div className="col-12 md:col-5 m-0 p-0 ">
                    <p className="text-color">{data?.mobile}</p>
                  </div>
                  <div className="col-12 md:col-7 m-0 p-0 ">
                    <p>
                      Address<span className="text-red"> * </span>
                    </p>
                  </div>
                  <div className="col-12 md:col-5 m-0 p-0 ">
                    <p className="text-color">
                      {data?.address_1}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-12 md:col-6 ">
              <h1 className=" border-radius text-white bg-main w-full p-3 text-lg font-bold px-3 ">
                Client Company Details
              </h1>
              <div
                className="card h-23rem  shadow-2 border-round-lg ml-0 mr-0 p-4 "
                style={{ background: "#FFFFFF" }}
              >
                <div className=" p-fluid grid ">
                  <div className="col-12 md:col-6 m-0 p-0  ">
                    <p>
                      Name<span className="text-red"> * </span>
                    </p>
                  </div>
                  <div className="col-12 md:col-6 m-0 p-0 ">
                    <p className="text-color">{data?.company_name}</p>
                  </div>
                  <div className="col-12 md:col-6 m-0 p-0 ">
                    <p>
                      TIN/EIN<span className="text-red"> * </span>
                    </p>
                  </div>
                  <div className="col-12 md:col-6 m-0 p-0 ">
                    <p className="text-color">{data?.company_tin_eim}</p>
                  </div>
                  <div className="col-12 md:col-6 m-0 p-0  ">
                    <p>
                      Type<span className="text-red"> * </span>
                    </p>
                  </div>
                  <div className="col-12 md:col-6 m-0 p-0  ">
                    <p className="text-color">Adult Day Program</p>
                  </div>
                  <div className="col-12 md:col-6 m-0 p-0  ">
                    <p>
                      Email<span className="text-red"> * </span>
                    </p>
                  </div>
                  <div className="col-12 md:col-6 m-0 p-0  ">
                    <p className="text-color">{data?.company_email} </p>
                  </div>
                  <div className="col-12 md:col-6 m-0 p-0  ">
                    <p>
                      Contact no.<span className="text-red"> * </span>
                    </p>
                  </div>
                  <div className="col-12 md:col-6 m-0 p-0  ">
                    <p className="text-color">{data?.company_mobile}</p>
                  </div>
                  <div className="col-12 md:col-6 m-0 p-0  ">
                    <p>
                      Address<span className="text-red"> * </span>
                    </p>
                  </div>
                  <div className="col-12 md:col-6 m-0 p-0  ">
                    <p className="text-color">
                      {data?.company_address_1}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Card className="shadow-2 text-main border-round-lg mb-5">
            <h4 className="text-main mb-3 p-0 m-0 Karla ">
            {location.pathname?.includes("/view-report") ? "Subscription Plan Details": location.pathname?.includes("/view-commission-report") ? "Commission Details":location?.pathname?.includes("/subscription-report") ? "Subscription Plan Details" :""}    
            </h4>
            <div className="grid program_table">
              <div className="md:col-12">
                <Table tableHeading={location?.pathname?.includes("/view-report") ? tableHeading : location?.pathname?.includes("/view-commission-report") ? commissionHeading : subscriptionHeading} tableData={tableData} noOfClient={noOfClient} totalRevenue={totalRevenue} discountData={showDiscount} finalPrice={finalPrice} totalData={totalData} />
                {/* <div className="grid  mt-2">
                  <div className="md:col-2 pl-4">
                    <small className="karla">Grand Total</small>
                  </div>
                  <div className="md:col-1 pl-8"></div>
                  <div className="md:col-2 pl-8">
                    <small className="karla">224</small>
                  </div>
                  <div className="md:col-1"></div>
                  <div className="md:col-1"></div>
                  <div className="md:col-1"></div>

                  <div className="md:col-1 pl-6">$70</div>
                  <div className="md:col-1 pl-7">
                    <small className="karla">$1200</small>
                  </div>
                  <div className="md:col-2 pl-8">
                    <small>$950</small>
                  </div>
                </div> */}
              </div>
            </div>
          </Card>
          <div className="flex justify-content-end">
            <Button
              label="Close"
              onClick={redirectPage}
              className="surface-500 border-0  p-3  border-round-md px-5"
            />
          </div>
        </div>
      }

    </>
  );
}
