

import React, { useState } from "react";
import { Button } from "primereact/button";
import { useLocalStorage } from "primereact/hooks";
import { useEffect } from "react";

export default function TableAddButton({ col, programData, setProgramData, selectedProducts, setSelectedProducts }) {
    const clients = (e, name) => { 
        if (name === "plus") {
            let data = programData.map((item) => {
                if (col.subscription_plans_id === item.subscription_plans_id) {
                    item.number_of_client = item.number_of_client + 1;
                    item.total = item.price_per_person * item.number_of_client
                }
                return item;
            })
            let selectedData = selectedProducts?.map((item) => {
                if (col.subscription_plans_id === item.subscription_plans_id) {
                    item.number_of_client = item.number_of_client + 1;
                    item.total = item.price_per_person * item.number_of_client
                }
                return item;
            })
            setProgramData(data);
            setSelectedProducts(selectedData)
        } else {
            let data = programData.map((item) => {
                if (col.subscription_plans_id === item.subscription_plans_id) {
                    item.number_of_client = item.number_of_client - 1;
                    item.total = item.price_per_person * item.number_of_client
                }
                return item;
            })
            let selectedData = selectedProducts?.map((item) => {
                if (col.subscription_plans_id === item.subscription_plans_id) {
                    item.number_of_client = item.number_of_client - 1;
                    item.total = item.price_per_person * item.number_of_client
                }
                return item;
            })
            setProgramData(data);
            setSelectedProducts(selectedData)
        }
    }
    return (
        <div className="grid flex p-0 ml-0 mr-0 m-0 ">

            <div className=" flex p-0 ml-0 mr-0 m-0 justify-content-evenly">
                <Button
                    icon="pi pi-minus"
                    name="minus"
                    disabled  =  {col.number_of_client ==0?true:false}
                    className=" minus border-round-3xl p-1 "
                    onClick={(e) => clients(e, "minus")}
                />
                <span className="font-normal flex border-1 border-400 border-round-md w-4 ml-2 align-items-center justify-content-center  ">{col?.number_of_client}</span>
                <Button
                    icon="pi pi-plus"
                    name="plus"
                    className=" plus border-round-3xl ml-2 p-1"
                    onClick={(e) => clients(e, "plus")}
                />
            </div>
        </div>
    );
}