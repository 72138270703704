import React from "react";
import { Chart } from "primereact/chart";
import BarChartData from "./barChartData";
import DropdownIcon from "../../../assets/icons/arrow-down-black.png"

export default function BarChart(props) {
  const { basicData, basicOptions } = BarChartData();

  // console.log(BarChartData);

  return (
    <div>
      <div className="p-3 border-solid border-1 border-round-md surface-overlay border-500" style={{ height: "270px" }}>
        <p className="m-0 text-main mb-3 text-lg font-bold flex align-items-center justify-content-between Karla" style={{ fontSize: "22px" }}> {props.title}

        <span className="text-xs text-900 bg-blue-100 border-round px-2 flex align-items-center  p-2" >
        {props.this}  &nbsp;
          <img src={DropdownIcon} alt="" width={8} height={5} className="" />
          </span>      
        </p>
        <Chart type="bar" data={basicData} options={basicOptions} height="172px" />
       
      </div>
      
    </div>
  );
}
