import { Component, useEffect, useState } from "react";
// import { Chart } from "primereact/chart";

const BarChartData = () =>{


  
    const [basicData] = useState({
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [
          {
            label: "",
            backgroundColor: "#DEEFFF",
            data: [30, 40, 20, 50, 40, 100, 50, 30, 20, 40, 50, 60],
          },
          
         
        ],
      });
      
    
      const getLightTheme = () => {
        let basicOptions = {
          maintainAspectRatio: false,
          aspectRatio: 0.8,
          plugins: {
            legend: {
              labels: {
                display: false,
                color: "#fff",
              },
              display: false
            },
          },
          scales: {
            x: {
              ticks: {
                color: "#343434",
              },
              grid: {
                color: "transparent",
              },
            },
            y: {
              ticks: {
                color: "#000",
              },
              grid: {
                color: "transparent",
              },
              display: false
            },
          },
        };
    
        
    
        return {
          basicOptions,
        };
      };
    
      const { basicOptions } = getLightTheme();

      return{basicData, basicOptions};
}

export default BarChartData;