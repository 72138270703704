import React, { useEffect, useState } from "react";
import Table from "../../../components/TableList/Table";
import { Card } from "primereact/card";
import { getSubscriptionDetailById,getPaymentDetails,viewPaymentDetails } from "../../../utils/action";
import { useParams } from "react-router-dom";
import { Loading } from "../../../components/Loader/Loading";
import { useLocation } from "react-router-dom";
export default function ViewSubscriptionBilling(props) {
  const location = useLocation()
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const[tableData,setTableData] = useState()
  const[clientData,setClientData] = useState({
    clientId:"",
    clientName:""
  })
  const[paymentData,setPaymentData] = useState("")
  const tableHeading = [
    { field: "srno", header: "Sr. No.", body: "srnoTemplates"},
    { field: "created_at", header: "Date",sortable: true  },
    { field: "ClientId", header: "Client Id",body :"clientIdTemplate" },
    { field: "ClientName", header: "Client Name",body:"clientDataTemplate",sortable: true  },
    { field: "subscription_plan.program_name", header: "Plan" },
    { field: "number_of_client", header: "No. of Clients" },
    { field: "subscription_plan.price_per_person", header: "Price Per Person" },
    { field: "revenue", header: "TotalRevenue " },
    { field: "status", header: "Status",sortable: true  },
  ];
  const paymentHeading =[
    { field: "srno", header: "Sr. No.", body: "srnoTemplates"},
    { field: "created_at", header: "Date",sortable: true  },
    { field: "id", header: "Client Id" },
    { field: "name", header: "Client Name",sortable: true  },
    { field: "company_name", header: "Company Name"},
    { field: "address_2", header: "Transaction Id",body:"transactionTemplate" },
    { field: "city", header: "Plan",body :"planTemplate" },
    { field: "state", header: "Price",body:"priceTemplate"},
    { field: "status", header: "Status",sortable: true,body:"statusTemplate"  },
  ]
  const { id } = useParams()
  const ViewSubscription = async () => {
    let array = []
    setIsLoading(true)
    try {
      let response = await getSubscriptionDetailById(id)
       response?.data?.subscription_details?.map((item)=>{
        let date = new Date(item.created_at)
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let dt = date.getDate();
        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }
        item.created_at = year + '-' + month + '-' + dt;
       array.push(item)
      })
      let payload = {
        clientId:response.data.id,
        clientName:response.data.name
      }
      setData(response.data)
      setClientData(payload)
      setTableData(array)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.log(err);
    }
  }
  const viewPayment =async()=>{
    let array = []
    setIsLoading(true)
    try {
      let response = await viewPaymentDetails(id)
      let date = new Date(response.data.created_at)
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();
      if (dt < 10) {
          dt = '0' + dt;
      }
      if (month < 10) {
          month = '0' + month;
      }
      response.data.created_at = year + '-' + month + '-' + dt;
      array.push(response.data)
      let payload = {
        clientId:response.data.id,
        clientName:response.data.name
      }
      setData(response.data)
      setClientData(payload)
  
      setPaymentData(array)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.log(err);
    }
  }
  useEffect(() => {
    if(location?.pathname?.includes("/view-payment-billing")){
      viewPayment()
    }else{
      ViewSubscription()
    }  
  }, [])
  return (
    <>
      {isLoading ? <Loading /> :
        <div className="p-5">
          <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 ">
            <div className="md:col-12 p-0 m-0">
              <h1
                className="text-main px-5 flex justify-content-between Karla"
                style={{ fontSize: "24px" }}
              >
                Client Details
              </h1>
            </div>
          </div>

          <div className="p-fluid grid mb-4">
            <div className="col-12 md:col-6 ">
              <h1 className=" border-radius text-white bg-main w-full p-3 text-lg font-bold px-3 ">
                Personal Details
              </h1>

              <div
                className="card h-23rem  shadow-2 border-round-lg ml-0 mr-0 p-4 "
                style={{ background: "#FFFFFF" }}
              >
                <div className="p-fluid grid  ">
                  <div className="col-12 md:col-7 p-0 m-0">
                    <p className="">
                      Name<span className="text-red"> * </span>
                    </p>
                  </div>

                  <div className="col-12 md:col-5 p-0 m-0">
                    <p className="text-color">{data?.name}</p>
                  </div>

                  <div className="col-12 md:col-7 m-0 p-0 ">
                    <p>
                      Email<span className="text-red"> * </span>
                    </p>
                  </div>

                  <div className="col-12 md:col-5 m-0 p-0">
                    <p className="text-color">{data?.email}</p>
                  </div>

                  <div className="col-12 md:col-7 m-0 p-0 ">
                    <p>
                      Phone No<span className="text-red"> * </span>
                    </p>
                  </div>

                  <div className="col-12 md:col-5 m-0 p-0 ">
                    <p className="text-color">{data?.mobile}</p>
                  </div>

                  <div className="col-12 md:col-7 m-0 p-0 ">
                    <p>
                      Address<span className="text-red"> * </span>
                    </p>
                  </div>

                  <div className="col-12 md:col-5 m-0 p-0 ">
                    <p className="text-color">
                      {data?.address_1}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className=" col-12 md:col-6 ">
              <h1 className=" border-radius text-white bg-main w-full p-3 text-lg font-bold px-3 ">
                Company Details
              </h1>

              <div
                className="card h-23rem  shadow-2 border-round-lg ml-0 mr-0 p-4 "
                style={{ background: "#FFFFFF" }}
              >
                <div className=" p-fluid grid ">
                  <div className="col-12 md:col-6 m-0 p-0  ">
                    <p>
                      Name<span className="text-red"> * </span>
                    </p>
                  </div>

                  <div className="col-12 md:col-6 m-0 p-0 ">
                    <p className="text-color">{data?.company_name}</p>
                  </div>

                  <div className="col-12 md:col-6 m-0 p-0 ">
                    <p>
                      TIN/EIN<span className="text-red"> * </span>
                    </p>
                  </div>

                  <div className="col-12 md:col-6 m-0 p-0 ">
                    <p className="text-color">{data?.company_tin_eim}</p>
                  </div>

                  <div className="col-12 md:col-6 m-0 p-0  ">
                    <p>
                      Type<span className="text-red"> * </span>
                    </p>
                  </div>

                  <div className="col-12 md:col-6 m-0 p-0  ">
                    <p className="text-color">Adult Day Program</p>
                  </div>

                  <div className="col-12 md:col-6 m-0 p-0  ">
                    <p>
                      Email<span className="text-red"> * </span>
                    </p>
                  </div>

                  <div className="col-12 md:col-6 m-0 p-0  ">
                    <p className="text-color">{data?.company_email}</p>
                  </div>

                  <div className="col-12 md:col-6 m-0 p-0  ">
                    <p>
                      Contact no.<span className="text-red"> * </span>
                    </p>
                  </div>

                  <div className="col-12 md:col-6 m-0 p-0  ">
                    <p className="text-color">{data?.company_mobile}</p>
                  </div>

                  <div className="col-12 md:col-6 m-0 p-0  ">
                    <p>
                      Address<span className="text-red"> * </span>
                    </p>
                  </div>

                  <div className="col-12 md:col-6 m-0 p-0  ">
                    <p className="text-color">
                      {data?.company_address_1}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Card className="shadow-2 text-main border-round-lg mb-5">
            <h2 className="text-main mb-3 p-0 m-0 Karla ">{props.title}</h2>
            <div className="grid program_table">
              <div className="md:col-12">
                <Table tableHeading={location?.pathname?.includes("view-payment-billing")?paymentHeading:tableHeading} tableData={location?.pathname?.includes("/view-payment-billing")? paymentData:tableData}setClientData= {setClientData} clientData = {clientData}/>
              </div>
            </div>
          </Card>
        </div>
      }

    </>
  );
}
