import React, { useEffect, useState } from "react";
import Logo from "../assets/icons/resmedx-logo-blue.png";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Route, Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import axios from "axios";
import Toasts from "../components/toast";
import { signInAction } from "../utils/action";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { validEmail } from "../shared/Validation";
toast.configure();
const LoginPage = () => {
  let navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState({
    email: "",
    password: "",
  });
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (values.email === "" || values.password === "" || !validEmail(values.email)) {
        setError({
          ...error,
          email: values.email === "" ? "Please enter email" :!validEmail(values.email)?"Please Enter Valid Email": error?.email,
          password:
            values.password === "" ? "Please enter password" : error?.password,
        });
        return false;
      }
      let response = await signInAction({ payload: values });
      if (response.success === false) {
        toast.error(response.message||"Something went wrong during login.")
      }
      if (response.success === true) {
        window.location.reload(false);
        localStorage.setItem("token", response?.data?.token);
        localStorage.setItem("name",response?.data?.name);
        toast.success("User login successful");
        navigate("/admin-dashboard");

      }
    } catch (err) {
      let message =
        err && err.message ? err.message : "Something went wrong during login";
      toast.error(message);
      return false;
    }
  };
  return (
    <>
      <div className="bg-main h-screen">
        <div className="flex h-full justify-content-center align-items-center">
          <div
            className="card bg-white p-3 w-4
                     border-round-2xl "
          >
            <div className="grid ">
              <div className="md:col-12 text-center">
                <img src={Logo} width="117px" height="92px" alt="ResMedX" />

                <h1 className="Karla font-bold" style={{ color: " #525252" }}>
                  {" "}
                  Welcome Back!
                </h1>
              </div>
            </div>

            <div className="grid flex justify-content-center">
              <div className="md:col-10 mb-3">
                <label className="mb-2 font-bold text-xs">Username</label>
                <InputText
                  className="w-full mt-1 border-round"
                  placeholder=""
                  name="email"
                  onChange={(e) => {
                    setError({
                      ...error,
                      email: null,
                    });
                    setValues({ ...values, email: e.target.value });
                  }}
                  id=""
                />
                {error.email && <span className="error">{error.email}</span>}
              </div>
            </div>

            <div className="grid flex justify-content-center mb-2">
              <div className="md:col-10">
                <label className="mb-2 font-bold text-xs">Password</label>
                <Password
                  className="w-full mt-1"
                  inputClassName="w-full border-round"
                  name="password"
                  toggleMask
                  onChange={(e) => {
                    setError({
                      ...error,
                      password: null,
                    });
                    setValues({ ...values,password: e.target.value });
                  }}
                  id=""
                />
                {error.password && <span className="error">{error.password}</span>}
              </div>
            </div>
            <div className="grid flex justify-content-center">
              <div className="col-12 md:col-5">
                <Checkbox
                  onChange={(e) => setChecked(e.checked)}
                  checked={checked}
                ></Checkbox>{" "}
                &nbsp; Remember me
              </div>
              <div className="col-12 md:col-5 mb-2 text-right">
                <Link to="/forgot-password" className="link no-underline">
                  <cod className="text-primary ">Forgot Password?</cod>
                </Link>
              </div>
              <div className="md:col-10 mb-5">
                <Button
                  onClick={handleSubmit}
                  label="Login"
                  className="p p-3 w-full bg-main border-round"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
