import './App.scss';
import { HashRouter, Routes, Route } from 'react-router-dom'
import LoginPage from './pages/Login';
import ForgotPassword from './pages/Forgot/ForgotPassword';
import SetNewPassword from './pages/Forgot/SetNewPassword';
import VerifyPassword from './pages/verifyotp';
import ManageClient from './views/admin-dashboard/Clientss/ManageClient';
import AddClients from './views/admin-dashboard/Clientss/AddClients';
import ViewClient from './views/admin-dashboard/Clientss/ViewClient';
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import Layout from './shared/Layout';
import { getAuth, ProtectedRoute } from './Routes/ProtectedRoute';
import ManageEmployees from './views/admin-dashboard/Employee/ManageEmployees';
import AddSalesPerson from './views/admin-dashboard/SalesPerson/AddSalesPerson';
import SubscriptionPlan from './views/admin-dashboard/SubscriptionPlan/SubscriptionPlan';
import SubscriptionBilling from './views/admin-dashboard/Billing/SubscriptionBilling';
import PaymentBilling from './views/admin-dashboard/Billing/PaymentBilling';
import ManageDiscount from './views/admin-dashboard/Billing/ManageDiscount';
import ClientReport from './views/admin-dashboard/Reports/ClientReport';
import SalesReport from './views/admin-dashboard/Reports/SalesReport';
import CommissionsReport from './views/admin-dashboard/Reports/CommissionsReport';
import SubscriptionReport from './views/admin-dashboard/Reports/SubscriptionReport';
import Notification from './views/admin-dashboard/Notification/Notification';
import AdminDashboard from './views/admin-dashboard/AdminDashboard';
import AddEmployees from './views/admin-dashboard/Employee/AddEmployees';
import AddSubscriptionPlan from './views/admin-dashboard/SubscriptionPlan/AddSubscriptionPlan';
import ManageSalesPerson from './views/admin-dashboard/SalesPerson/ManageSalesPerson';
import { useState } from 'react';
import { useEffect } from 'react';
import AddNotification from './views/admin-dashboard/Notification/AddNotification';
import AddDiscount from './views/admin-dashboard/Billing/AddDiscount';
import ViewSubscriptionBilling from './views/admin-dashboard/Billing/ViewSubscriptionBilling';
import { useNavigate, useLocation } from 'react-router-dom';
import EditClient from './views/admin-dashboard/Clientss/EditClient';
import LoginRoute from './Routes/LoginRoute';
import ViewEmployees from './views/admin-dashboard/Employee/ViewEmployees';
import EditEmployees from './views/admin-dashboard/Employee/EditEmployees';
import ViewReports from './views/admin-dashboard/Reports/ViewReports';
import ViewsalesPerson from './views/admin-dashboard/SalesPerson/ViewsalesPerson';
import EditSalesPerson from './views/admin-dashboard/SalesPerson/EditSalesPerson';
import ViewSalesReport from './views/admin-dashboard/Reports/ViewSalesReport';
import ManageQuickNotification from './views/admin-dashboard/managenotification/ManageQuickNotification';
import ManageCustomNotification from './views/admin-dashboard/managenotification/ManageCustomNotification';
import SendQuickNotification from './views/admin-dashboard/managenotification/SendQuickNotification';
import SendCustomNotification from './views/admin-dashboard/managenotification/SendCustomNotification';
import ViewQuickNotification from './views/admin-dashboard/managenotification/ViewQuickNotification';
import ViewCustomNotification from './views/admin-dashboard/managenotification/ViewCustomNotification';
function App() {
  const navigate = useNavigate()
  let location = useLocation();
  // const [isAuthorized, setIsAuthorized] = useState(false);
  // useEffect(() => {
  //   let token = localStorage.getItem("token");
  //   if (token) {
  //       console.log("Helloooo")
  //   }
  // }, [location])

  return (
    <>
      {
    localStorage.getItem("token") !== null  ?
          <Layout>
            <Routes>
              <Route path = "*" element = {<ProtectedRoute/>}/>
              <Route path="/admin-dashboard" exact={true} element={<AdminDashboard />} />
              <Route path="/manage-client" element={<ManageClient />} />
              <Route path="/add-client" element={<AddClients />} />
              <Route path="/manage-employees" element={<ManageEmployees />} />
              <Route path="/sales-person" element={<ManageSalesPerson />} />
              <Route path="/add-sales-person" element={<AddSalesPerson />} />
              <Route path="/subscription" element={<SubscriptionPlan />} />
              <Route path="/subscription-billing" element={<SubscriptionBilling />} />
              <Route path="/payment-billing" element={<PaymentBilling />} />
              <Route path="/manage-discount" element={<ManageDiscount />} />
              <Route path="/client-report" element={<ClientReport />} />
              <Route path="/sales-report" element={<SalesReport />} />
              <Route path="/commision-report" element={<CommissionsReport />} />
              <Route path="/subscription-report" element={<SubscriptionReport />} />
              <Route path="/notification" element={<Notification />} />
              <Route path="/add-employess" element={<AddEmployees />} />
              <Route path="/add-subscription" element={<AddSubscriptionPlan />} />
              <Route path= "/edit-subscription/:id" element ={<AddSubscriptionPlan/>}/>
              <Route path="/add-notification" element={<AddNotification />} />
              <Route path="/view-subscription-billing/:id" element={<ViewSubscriptionBilling />} />
              <Route path = "/view-payment-billing/:id" element= {<ViewSubscriptionBilling/>}/>
              <Route path = "/view-Client/:id" element={<ViewClient/>}/>
              <Route path = "/edit-Client/:id" element={<EditClient/>}/>
              <Route path ="/view-employee/:id" element = {<ViewEmployees/>}/>
              <Route path="/edit-employee/:id" element ={<EditEmployees/>}/>
              <Route path="/view-report/:id" element ={<ViewReports/>}/>
              <Route path ="/view-commission-report/:id" element ={<ViewReports/>}/>
              <Route path = "/view-commision-report" element ={<ViewReports/>} />
              <Route path = "/view-subscription-report" element= {<ViewReports/>}/>
              <Route path =  "/view-salesPerson/:id" element ={<ViewsalesPerson/>} />
              <Route path = "/edit-salesPerson/:id" element = {<EditSalesPerson/>}/>
              <Route path = "/edit-discount/:id" element = {<AddDiscount/>}/>
              <Route path= "/add-discount" element = {<AddDiscount/>}/>
              <Route path = "/ViewSalesReport/:id" element = {<ViewSalesReport/>}/>

              <Route path ="/subscription-report/:id" element = {<ViewReports/>}/>
              <Route path = "/manage-quick-notification" element = {<ManageQuickNotification/>}/>
              <Route path = "/send-quick-notification"  element = {<SendQuickNotification/>}/>
              <Route path = "/view-quick-notification" element = {< ViewQuickNotification/>}/>
              <Route path = "/manage-custom-notification" element = {<ManageCustomNotification/>}/>
              <Route path = "/send-custom-notification"  element = {<SendCustomNotification/>}/>
              <Route path = "/view-custom-notification"  element = {<ViewCustomNotification/>}/>
              <Route path = "/edit-custom-notification"  element = {<SendCustomNotification/>}/>

            </Routes>
          </Layout>
          :  
        <LoginRoute />
            }
    </>
  );
}

export default App;
