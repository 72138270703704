import { React, useState, useRef } from "react";
import Table from "../../../components/TableList/Table";
import SearchIcon from "../../../assets/icons/search.png";
import FilterIcon from "../../../assets/icons/filter.png";
import EyeIcon from "../../../assets/icons/eyeicon.png";
import { Button } from "primereact/button";
import ContentArea from "../../../shared/ContentArea";
import { getClientReports } from "../../../utils/action";
import Index from "../Index";
import ViewReports from "./ViewReports";
import { useEffect } from "react";
import { Loading } from "../../../components/Loader/Loading";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Calendar } from "primereact/calendar";
export default function ClientReport(props) {
  const { isNextPage, setIsNextPage } = Index();
  const [viewButton, setViewButton] = useState(false);
  const [editButton, setEditButton] = useState(false);
  const [tableData, setTableData] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [grandtotalRevenue, setGrandTotalRevenue] = useState(0)
  const [showGrandTotal, setShowGrandTotal] = useState(false)
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [filterData, setFilterData] = useState({
    month: "",
    year: ""
  })
  const [products, setProducts] = useState([]);
  const [data, setData] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    is_active: { value: null, matchMode: FilterMatchMode.EQUALS },
    dob: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
  });
  const tableHeading = [
    { selectionMode: "multiple" },
    { field: "srno", header: "Sr. No.", body: "srnoTemplates" },
    { field: "name", header: "Client Name", sortable: true },
    { field: "company_name", header: "Company Name", sortable: true },
    { field: "id", header: "Program Category", body: "programNameTemplates" },
    // { field: "CategoryRevenue", header: "Category Revenue" },
    { field: "created_by", header: "Lead Generation" },
    { field: "TotalRevenue", header: "Total Revenue", body: "revenueTemplates" },
    { field: "icons", header: "Action", body: "eyeClientBodyTemplate" },
  ];
  const showClientReports = async () => {
    let array = []
    setIsLoading(true)
    let response = await getClientReports({ payload: data })
    setTableData(response.data)
    setIsLoading(false)
    setShowGrandTotal(true)
  }
  useEffect(() => {
    let grandtotal = 0;
    tableData.length > 0 && tableData.map((item) => {
      item.subscription_details?.length > 0 && item.subscription_details?.map((sub) => {
        grandtotal += sub.total
      })
    })
    setGrandTotalRevenue(grandtotal)
  }, [tableData])
  useEffect(() => {
    showClientReports()
  }, [])
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
    setShowGrandTotal(false)
  };
  useEffect(() => {
    if (globalFilterValue === "") {
      setShowGrandTotal(true)
    }
  }, [globalFilterValue])
  const applyfilter = async () => {
    setIsLoading(true)
    setIsShowFilter(!isShowFilter)
    let response = await getClientReports({ payload: filterData })
    setIsFiltered(true)
    setIsLoading(false)
    setTableData(response.data)
  }
  const Clearfilter = async () => {
    setIsLoading(true)
    setIsFiltered(false)
    setIsShowFilter(false)
    let res = await getClientReports({ payload: data })
    setIsLoading(false)
    setTableData(res.data.reverse())
  }
  const handleChange = (e) => {
    let date = new Date(e.target.value)
    const monthdate = date.toLocaleString('default', { month: 'long' });
    setFilterData({
      ...filterData,
      month: monthdate,
    });
  };
  const handleChangeYear = (e) => {
    let dates = new Date(e.target.value)
    let years = dates.getFullYear();
    let months = dates.getMonth() + 1;
    let dts = dates.getDate();
    if (dts < 10) {
      dts = '0' + dts;
    }
    if (months < 10) {
      months = '0' + months;
    }
    let dobs = years;
    setFilterData({
      ...filterData,
      year: dobs,
    });
  }
  function handleGrandTotal() {
    return (
      <div className="grid p-0 flex align-items-center flex justify-content-center">
      <div className=" md:col-9">
        <p>Grand Total</p>

      </div>
      <div className=" md:col-3">
        <p className="ml-6">${grandtotalRevenue}</p>

      </div>

    </div>
    )
    
    
  }
  
  useEffect(() => {

    let object
    let array = []
    tableData?.length > 0 && tableData.map((item, index) => {
      let datarevenue = 0;
      item.subscription_details?.map((use) => {
        datarevenue += use.total
      })
      object = {
        SrNo: index + 1,
        ClientName: item.name,
        CompanyName: item.company_name,
        ProgramCategory: item?.subscription_details[0]?.subscription_plan?.program_name,
        LeadGeneration: item.created_by,
        TotalRevenue: datarevenue,
      }
      array.push(object)
    })
    setProducts(array)
  }, [tableData]);

  const exportExcel = () => {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(products);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });

      saveAsExcelFile(excelBuffer, 'products');
    });
  };
  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE
        });

        module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      }
    });
  };
  return (
    <>
      {isLoading === true ? <Loading /> :
        <>
          {!isNextPage & !editButton & !viewButton ? (
            <ContentArea>
              <div className="">
                <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-evenly">
                  <div className="md:col-6 ">
                    <h1 className="text-main  " style={{ fontSize: "24px" }}>
                      Clients Report
                    </h1>
                  </div>
                  <div className="md:col-3">
                    <div className="search-box w-full">
                      <img
                        src={SearchIcon}
                        alt="Search Icon"
                        width="15px"
                        height="15px"
                      />
                      <input type="search" placeholder="Search" onChange={onGlobalFilterChange} onClick={() => setIsShowFilter(false)} />
                    </div>
                  </div>
                  <div className="md:col-1 relative bg-main px-3 border-round-md w-max  ">
                    {
                      isFiltered ?
                        <div onClick={() => Clearfilter()} className="bg-main border-round-md text-white text-center text-base relative karla">
                          Clear &nbsp;
                          <i className="pi pi-filter-slash"></i></div>
                        :
                        <div onClick={() => setIsShowFilter(prev => !prev)} className="bg-main border-round-md text-white text-center text-base relative karla">
                          Filter &nbsp;
                          <img src={FilterIcon} alt="" width={15} /></div>
                    }
                    {
                      isShowFilter ?
                        <div className="filter-popup">
                          <label className="font block mb-2">
                            Select Month<code className="text-red"></code>
                          </label>
                          <Calendar onChange={(e) => handleChange(e)} view="month" dateFormat="MM" name="month" />
                          <label className="font block mb-2 mt-2">
                            Select Year<code className="text-red"></code>
                          </label>
                          <Calendar onChange={(e) => handleChangeYear(e)} name="year" view="year" dateFormat="yy" />
                          <div className="mt-3 flex justify-content-between">
                            <Button onClick={Clearfilter}>Clear</Button>
                            <Button onClick={(e) => applyfilter(e)}>Apply</Button></div>
                        </div>
                        : null
                    }
                  </div>
                  <div className="md:col-2 w-max">
                    <Button type="button" label="Export" className="bg-main karla px-4 border-round-md" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
                    {/* <Button label="Export" className="bg-main karla px-4 border-round-md" /> */}
                  </div>
                </div>
                <div className="grid mt-2">
                  <div className="md:col-12">
                    <Table
                      tableHeading={tableHeading}
                      tableData={tableData}
                      setViewButton={setViewButton}
                      title="clientReport"
                      filters={filters}
                      setFilters={setFilters}
                      setSelectedProducts={setSelectedProducts}
                      selectedProducts={selectedProducts}
                      setData={setData}
                      handleGrandTotal = {handleGrandTotal()}
                    />
                  </div>

                </div>

                



              </div>
            </ContentArea>
          ) : null}
          {viewButton ? (
            <ViewReports
              title="Subscription Plan Details"
              header="Clients Report"
            />
          ) : null}
        </>
      }

    </>
  );
}
