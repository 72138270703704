import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/sass/components/table.scss";
import EyeIcon from "../../assets/icons/eye.png";
import EditIcon from "../../assets/icons/edit.png";
import DeleteIcon from "../../assets/icons/delete.png";
import { useNavigate } from "react-router-dom";
import { Calendar } from "primereact/calendar";
import TableAddButton from "../../views/admin-dashboard/Clientss/TableAddButton";
import FileIcon from "../../assets/icons/fileicon.png"
import CloseIcon from "../../assets/icons/close.png"
import { useEffect } from "react";
import { Checkbox } from "primereact/checkbox";
import { Chip } from 'primereact/chip';
import { Paginator } from 'primereact/paginator';
export default function Table({setTableData,handleGrandTotal,totalData, noOfClient, finalPrice, discountData, totalRevenue, clientData, handleDelete, handleDeletePdf, tableHeading, programData, setProgramData, title, handleShowDescription, setSelectedProducts, selectedProducts, filters, tableData, deletButton, setData, setDeleteButton, setDescriptionButton, filter, filterTemp, filterField, dataType }) {

  const [getSelectedProducts, setGetSelectedProducts] = useState([]);
  const [newTableData, setNewTableData] = useState([])
  const [count, setCount] = useState(0);
  const [rows, setRows] = useState(10);
  const onPageChange = (event) => {
    let startPage = event.page+1
    let itemsPerPage = event.rows
    let value = []
    for (var i = 0; i < tableData.length; i++) {
      if (i >= (startPage - 1) * itemsPerPage && i < startPage * itemsPerPage) {
        value.push(tableData[i])
      }

  }
  setNewTableData(value)
    setCount(event.first);
      setRows(event.rows);
  };
  const navigate = useNavigate()
  const runEdit = (col) => {
    let link = title === 'empTable' ? `/edit-employee/${col.id}` : title === "clientTable" ? `/edit-Client/${col.id}` : title === "subscriptionTable" ? `/edit-subscription/${col.id}` : title === "salesPerson" ? `/edit-salesPerson/${col.id}` : title === "manageDiscount" ? `/edit-discount/${col.id}` : ""
    navigate(link)
    // setEditButton((prev) => !prev);
  };

  const reportsSalesTemplate = (col) => {
    return (
      <>
        {col.commission_details?.length > 0 && col?.commission_details?.map((item) => {
          return (
            <div>{item.number_of_client}</div>
          )
        })}
      </>
    )
  }
  const reportsNameTemplate = (col) => {
    return (
      <>
        {col.commission_details?.length > 0 && col?.commission_details?.map((item) => {
          return (
            <div>{item.client}</div>
          )
        })}
      </>
    )
  }
  const payoutTemplate = (col) => {
    return (
      <>
        {col.commission_details?.length > 0 && col?.commission_details?.map((item) => {
          return (
            <div>{item.payout}</div>
          )
        })}
      </>
    )
  }
  const totalCommissionTemplate = (col) => {
    return (
      <>
        {col.created_at === 'Grand Total' ? <div>{totalData}</div> : <div>{totalData}</div>}
      </>
    )
  }
  const reportsRevenueTemplate = (col) => {
    return (
      <>
        {col.commission_details?.length > 0 && col?.commission_details?.map((item) => {
          return (
            <div>{item.revenue}</div>
          )
        })}
      </>
    )
  }
  useEffect(()=>{

    let startPage = 1
    let itemsPerPage = rows
    let value = []
    for (var i = 0; i < tableData?.length; i++) {
      if (i >= (startPage - 1) * itemsPerPage && i < startPage * itemsPerPage) {
        value.push(tableData[i])
      }
  }
    setNewTableData(value)
    // setCount(0)

  },[tableData])
  const runView = (col) => {
    let link = title === 'empTable' ? `/view-employee/${col.id}` : title === "clientTable" ? `/view-client/${col.id}` : title === "subscriptionBilling" ? `/view-subscription-billing/${col.id}` : title === "paymentBilling" ? '/view-payment-billing' : title === "clientReport" ? "/view-report" : title === "salesReport" ? "/view-sales-report" : title === "commisionReport" ? "/view-commision-report" : title === "subscriptionReport" ? "/view-subscription-report" : title = "salesPerson" ? `/view-salesPerson/${col.id}` : ""
    navigate(link)
  };

  const setVisible = (col) => {
    setDeleteButton(!deletButton);
    setData(col.id)
  };
  const setVisibles = (col) => {
    setDescriptionButton((prev) => !prev);
    setData(col.id)
    handleShowDescription(col.id);
  };
  const setNotification = () => {
    setDescriptionButton(true)
  }
  const notificationIconBodyTemplate = (col) => {
    return (
      <div className="text-main underline ">
        <span onClick={(col) => setNotification(col)}>View</span>
      </div>
    )
  }

  const tableAddButton = (col) => {
    return (
      <div style={{ textAlign: "left" }}>
        <TableAddButton count={count} setCount={setCount} col={col} programData={programData} setProgramData={setProgramData} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} />
      </div>
    )
  }
  const clientDataTemplate = (col) => {
    return (
      <div>{clientData.clientName}</div>
    )
  }
  const clientIdTemplate = () => {
    return (
      <div>{clientData.clientId}</div>
    )
  }
  const dateBodyTemplate = (col) => {
    return formatDate(col.dob);
  };
  const srnoTemplates = (col, i) => {
    return (
      <span>
        {count + i.rowIndex+1}
      </span>
    )
  }
  const imageBodyTemplate = (col) => {
    return (
      <span className="flex">
        {
          col.viewIcon ?
            <img
              src={col.viewIcon}
              onClick={() => runView()}
              width="25px"
              height="25px"
              className="product-image ml-3"
            />
            : ""
        }
        {col.editIcon ?
          <img
            src={col.editIcon}
            onClick={() => runEdit()}
            width="20px"
            height="20px"
            className="product-image ml-3"
          /> : ""
        }
        {col.deleteIcon ?
          <img
            src={col.deleteIcon}
            onClick={() => setVisible()}
            width="20px"
            height="20px"
            className="product-image ml-3"
          /> : ""
        }
      </span>
    )
  }
  const iconBodyTemplate = (col) => {
    return (
      <div className="flex">
        <img
          src={EyeIcon}
          onClick={() => navigate("/view-custom-notification")}
          alt=""
          width="17px"
          height="17px"
          className="product-image m-1"

        />
        <img
          src={EditIcon}
          onClick={() => navigate("/edit-custom-notification")}
          width="17px"
          height="17px"
          className="product-image ml-3"
        />
        <img
          src={DeleteIcon}
          width="17px"
          height="17px"
          className="product-image ml-3"
        />
      </div>
    );
  };

  const imageBodyTemp = (col) => {
    return (
      <span className="flex align-items-center">
        <img src={EyeIcon} width="20px" height="15px" className="product-image" onClick={() => runView(col)} />
        <img src={EditIcon} width="20px" height="20px" className="product-image ml-3 " onClick={() => runEdit(col)} />
        <img src={DeleteIcon} width="20px" height="20px" className="product-image ml-3 " onClick={() => setVisible(col)} />
      </span>
    )
  }
  const subscriptionBodyTemp = (col) => {
    return (
      <span className="flex align-items-center">
        <img src={EditIcon} width="20px" height="20px" className="product-image " onClick={() => runEdit(col)} />
        <img src={DeleteIcon} width="20px" height="20px" className="product-image ml-3 " onClick={() => setVisible(col)} />
      </span>
    )
  }
  const subscriptionViewTemp = (col) => {
    return (
      <span className="flex align-items-center">
        <img src={EyeIcon} width="20px" height="15px" className="product-image ml-3 " onClick={() => runView(col)} />
      </span>
    )
  }
  const ViewIconBodyTemplate = (col) => {
    return (
      <div className="flex">
        <img
          onClick={() => navigate("/view-quick-notification")}
          src={EyeIcon}
          alt=""
          width="19px"
          height="14px"
          className="product-image m-1"

        />
      </div>
    );
  };
  const closePdfIconBodyTemplate = (col) => {
    return (
      <div>
        <img src={CloseIcon} alt="" width="14px" height="14px" className="product-image m-1" onClick={() => handleDeletePdf(col.name)} />
      </div>
    )
  }
  const pdfFileopen = (col) => {
    return (
      <div>
        <a href={`https://resmedxapi.appdeft.biz/public/${col.name}`} target="_blank">{col.name}</a>
      </div>
    )
  }
  const fileIconBodyTemplate = (col) => {
    return (
      <div className="flex">
        <img
          src={FileIcon}
          alt=""
          width="14px"
          height="17px"
          className="product-image m-1"
        />
      </div>
    );
  };
  const noofClientTemplate = (col) => {
    return (
      <div>
        {col.subscription_plan.program_name === "Grand Total" ? <div>{noOfClient}</div> : <div>{col.number_of_client}</div>}
      </div>
    )
  }
  const totalRevenueTemplate = (col) => {
    return (
      <div>
        {col.subscription_plan.program_name === "Grand Total" ? <div>${totalRevenue}</div> : <div>{col.total}</div>}
        {col.subscription_plan.program_name === "Discount" ? <div>${discountData}</div> : ""}
        {col.subscription_plan.program_name === "Final Total" ? <div>${finalPrice}</div> : finalPrice === "NAN" ? 0 : ""}
      </div>
    )
  }
  const viewIconBodyTemplate = (col) => {
    return (
      <div className="">
        <span className="text-md text-blue-500 underline" onClick={() => {
          setVisibles(col);
        }}>View</span>

      </div>
    );
  };
  const runViewReport = (col) => {
    let link = title === "salesReport" ? `/ViewSalesReport/${col?.client?.id}` : ""
    navigate(link)
  }
  const viewReportBodyTemplate = (col) => {
    return (
      <div className="">
        <span className="text-md text-blue-500 underline" onClick={() => runViewReport(col)}>View</span>
      </div>
    )
  }
  const programNameTemplates = (col) => {

    return (
      col?.subscription_details?.map((item) => {
        return (
          <div>{item.subscription_plan?.program_name}</div>
        )
      })
    )
  }
  const revenueTemplates = (col) => {
    let revenue = 0;
    col?.subscription_details?.map((item) => {
      revenue += item.total
    })
    return (
      <div>${revenue}</div>
    )
  }
  const onEye = (col) => {
    let link = title === "subscriptionBilling" ? `/view-subscription-billing/${col.id}` : title === "paymentBilling" ? `/view-payment-billing/${col?.id}` : ""
    navigate(link)
  }
  const eyeBodyTemplate = (col) => {
    return (
      <div className="flex">
        <img src={EyeIcon} onClick={() => { onEye(col?.client) }} alt="" width="20px" height="15px" className="product-image m-1" />
      </div>
    )
  }
  const EyeClient = (col) => {
    let link = title === "clientReport" ? `/view-report/${col.id}` : title === "commisionReport" ? `/view-commission-report/${col.id}` : title === "subscriptionReport" ? `/subscription-report/${col.id}`:""
    navigate(link)
  }
  const eyeClientBodyTemplate = (col) => {
    return (
      <div className="flex">
        <img src={EyeIcon} onClick={() => { EyeClient(col) }} alt="" width="20px" height="15px" className="product-image m-1" />
      </div>
    )
  }
  const totalTemp = (col) => {
    return (
      <span>{col.total}</span>
    )
  }
  // empName template
  const empNameTemplate = (col) => {
    return col.empName ? (
      <span className="text-main">{col.empName}</span>
    ) : null;
  };

  // email Template
  const emailTemplate = (col) => {
    return col.email ? <span className="text-main">{col.email}</span> : null;
  };

  // active/Deactive
  const activeTemplate = (col) => {
    return (
      <span className={col?.is_active == 1 ? "text-green" : "text-red"}>
        {col.is_active == 0 ? "Inactive" : "Active"}
      </span>
    );
  };
  const closeIconBodyTemplate = (col) => {
    return (
      <div className="flex">
        <img
          src={CloseIcon}
          alt=""
          width="14px"
          height="14px"
          className="product-image m-1"
          onClick={()=>handleDelete(col.key,col.id)}
        />
      </div>
    );
  };
  const transactionTemplate = (col)=>{
    return(
      col?.payments?.map((item)=>{
        return(
          <div>{item?.transaction_id}</div>
        )
       })
    )
  
  }
  const planTemplate = (col)=>{
    return(
      col?.payments?.map((item)=>{
        return(
          <div>{item?.subscription_plan?.program_name}</div>
        )
       })
    )
  }
  const priceTemplate = (col)=>{
    return(
      col?.payments?.map((item)=>{
        return(
          <div>{item?.subscription_plan?.price_per_person}</div>
        )
       })
    )
  }
  const statusTemplate =(col)=>{
    return(
      col?.payments?.map((item)=>{
        return(
          <div>{item?.status}</div>
        )
       })
    )
  }
  const formatDate = (value) => {
    return new Date(value).toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };
  const selectedData = (e) => {

    setSelectedProducts(e.value)
  }
  // dynamicColumns
  const dynamicColumns = tableHeading?.map((col, i) => {
    return col.selectionMode ? <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> : (

      <Column
        key={col.field}
        field={col.field}
        sortable={col.sortable ? true : false}
        header={col.header}
        body={eval(col.body)}
        style={{ fontSize: "14px" }}
        filter={filter ? true : false}
        filterElement={filterTemp ? filterTemp : null}
      // filterField={col.filterField ? col.filterField : null}
      // dataType={col.dataType ? col.dataType : null} 
      />
    );
  })

  useEffect(() => {
    if (selectedProducts?.length) {
      setGetSelectedProducts(selectedProducts)
    }
  }, selectedProducts)
  return (
    <div>

      {tableData ? (
        <>
        <DataTable
          value={newTableData ? newTableData : null}
          responsiveLayout="scroll"
          className="text-center dynamicdata no-rounded"
          style={{ borderRadius: "0px !important" }}
          rows={10}
          sortMode="multiple"
          globalFilterFields={['name', 'is_active', 'program_name', 'discount_code', 'subscription_plan.program_name', 'client.name','profile_id','company_name','emp_id','client.company_name','client.profile_id']}
          filters={filters}
          filterDisplay="menu"
          currentPageReportTemplate={`Showing {first} to {last} of {totalRecords}`}
          selectionMode={"checkbox"}
          selection={selectedProducts?.length ? selectedProducts : null}
          onSelectionChange={setSelectedProducts ? (e) => selectedData(e) : null}
          dataKey={tableData.rowId ? tableData.rowId : null}
        >
          {dynamicColumns}
        </DataTable>
        {title === "clientReport" || title === "salesReport" || title === "commisionReport" || title === "addClient" || title === "editClient" || title === "viewClient"? handleGrandTotal:""}
        <Paginator first={count} rows={rows} totalRecords={tableData.length} onPageChange={onPageChange} />
        </>
      ) : (
        <div className="d-4 p-3 bg-primary">Data not avialable</div>
      )}
    </div>
  );
}

