import React, { useEffect } from "react";
import { Routes,Route, useLocation,useNavigate } from "react-router-dom";
import LoginPage from "../pages/Login";
import ForgotPassword from "../pages/Forgot/ForgotPassword";
import SetNewPassword from "../pages/Forgot/SetNewPassword";
import VerifyPassword from "../pages/verifyotp";
const LoginRoute=()=>{
    let location = useLocation()
    const navigate =  useNavigate()
    // useEffect(()=>{
    //         if(location.pathname.includes("/")){
    //             setTimeout(()=> navigate("/login"),500)      
    //         }
                                      
    // },[location])
    return(
        <Routes>
        <Route path="/" exact={true} element={<LoginPage />} />
        {/* <Route path="/login" element={<LoginPage />} /> */}
        <Route path="/forgot-password" exact={true} element={<ForgotPassword />} />
        <Route path="/reset-password" exact={true} element={<SetNewPassword />} />
        <Route path="/verify-password" exact={true} element={<VerifyPassword />} />
      </Routes>
    )
}
export default LoginRoute;