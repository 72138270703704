
import React, { useState} from "react";
import ContentArea from "../../../shared/ContentArea";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";



export default function ViewCustomNotification() {

    const [selectedEvent, setSelectedEvent] = useState(null);


  const Event = [
    { name: "First Login"},
    { name: "No. of clients"},
    { name: "Limited Exceeded"},
    { name: "Payment Pending" },
    { name: "1 day before payment"},
    { name: "3 day before payment"},
    { name: "7 day before payment" },
    { name: "Payment Failed" },
    { name: "Payment Successful"},
    { name: "Plan Cancelled"},
  ];

 

  return (
    <>
      <ContentArea>
        <div className="p-3">
          <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 ">
            <div className="md:col-12 p-0 m-0">
              <h1
                className="text-main px-5 flex justify-content-between Karla"
                style={{ fontSize: "24px" }}
              >
                Add Custom Notifications
              </h1>
            </div>
          </div>

         

          <div className="shadow-2 p-5 border-round-lg">
            <div className="p-fluid grid mb-3">
              <div className="col-12 md:col-12 ">
                <label className="font block mb-2"> Title </label>
                <InputText
                  id="inputtext"
                  placeholder="Lorem Ipsum is simply dummy text"
                  className="border-round-md "
                />
              </div>
            </div>

            <div className="p-fluid grid mb-4">
              <div className="col-12 md:col-12">
                <label className="font block mb-2">Description</label>
                <InputTextarea
                  className="border-round-md"
                  placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
                  rows={4}
                />
              </div>
            </div>
           

            <div className="grid mb-5">
              <div className="md:col-4 h-10rem">
              <label className="font block mb-2"> Event </label>
                <Dropdown
                  value={selectedEvent}
                  onChange={(e) => setSelectedEvent(e.value)}
                  options={Event}
                  optionLabel="name"
                  placeholder="Select Event"
                  className="w-full border-round-lg "
                />
              </div>
            </div>

           
          </div>
        </div>
      </ContentArea>
    </>
  );
}
