import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { HashRouter, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/Login';
import ForgotPassword from './pages/Forgot/ForgotPassword';
import SetNewPassword from './pages/Forgot/SetNewPassword';
import VerifyPassword from './pages/verifyotp';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

    <HashRouter>
        <App />

    </HashRouter>
  </React.StrictMode>
);
