import axios from "axios";
const config = require("../envirement/development").config
const token = localStorage.getItem("token");
console.log(token, "tokentokentokentoken")
//insert api add Employee

export const addEmployee = ({ payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/api/admin/add-employee`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return resolve(response.data);
    } catch (err) {
      console.log(err);
      return reject(err);
    }
  });
};

//get Employee

export const getEmployee = ({ payload }) => {

  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/api/admin/get-employee`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
}

///getPermission

export const getPermission = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(`${config.backEnd}/api/admin/permission`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  })
}

//insert api add Client

export const addClient = ({ payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/api/admin/add-client`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return resolve(response.data);
    } catch (err) {
      console.log(err);
      return reject(err);
    }
  });
};


//insert api View client By Id
export const viewClient = ({ payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/api/admin/get-client`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  })
}

export const getClient = ({ payload }) => {

  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/api/admin/get-client`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
}
//insert api edit Client

export const editClient = ({ id, payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/api/admin/edit-client/${id}`, payload, {

        headers: {

          Authorization: `Bearer ${token}`
        }
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  })
}

//insert api delet client 

export const deletClient = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(`${config.backEnd}/api/admin/delete-client/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err)
    }
  })
}

//insert api View Emplyoess By Id

export const viewEmployess = ({ payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/api/admin/get-employee`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  })
}

//insert api edit Employee

export const editEmployee = ({ id, payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/api/admin/edit-employee/${id}`, payload, {

        headers: {

          Authorization: `Bearer ${token}`
        }
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  })
}

//insert api delet employee
export const deletEmployee = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(`${config.backEnd}/api/admin/delete-employee/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err)
    }
  })
}

//insert api get all sales Person
export const getSalesPerson = ({ payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/api/admin/get-sales-person`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
}

//insert api add sales Person
export const addSalesPerson = ({ payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/api/admin/add-sales-person`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err)
    }
  })
}

//inser api view sales Person By id
export const viewSalesPerson = ({ payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/api/admin/get-sales-person`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return resolve(response.data)
    } catch (err) {
      return reject(err)
    }
  })
}

//insert api Delete Sales Person 
export const deleteSalesPerson = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(`${config.backEnd}/api/admin/delete-sales-person/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return resolve(response.data)
    } catch (err) {
      return reject(err)
    }
  })
}

//insert api edit Sales Person
export const editSalesPerson = ({ id, payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/api/admin/edit-sales-person/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return resolve(response.data)
    } catch (err) {
      return reject(err)
    }
  })
}

//insert api add Subscription Plan

export const addSubscription = ({ payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/api/admin/add-subscription`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return resolve(response.data)
    } catch (err) {
      return reject(err)
    }
  })
}


//insert api getSubscription 

export const getSubscription = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(`${config.backEnd}/api/admin/get-subscription`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return resolve(response.data)
    } catch (err) {
      return reject(err)
    }
  })
}

//insert api getSubscription By id

export const getSubscriptionById = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(`${config.backEnd}/api/admin/get-subscription/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return resolve(response.data)
    } catch (err) {
      return reject(err)
    }
  })
}

//insert api get Description By Id 

export const getDescription = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(`${config.backEnd}/api/admin/get-description/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return resolve(response.data)
    } catch (err) {
      return reject(err)
    }
  })
}

//export const edit Description By Id
export const editDescription = ({ id, payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/api/admin/edit-subscription/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return resolve(response.data)
    } catch (err) {
      return reject(err)
    }
  })
}

//insert api delete description by id
export const deleteDescription = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(`${config.backEnd}/api/admin/delete-subscription/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return resolve(response.data)
    } catch (err) {
      return reject(err)
    }
  })
}


//integrate api manage discount

export const ViewDiscount = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(`${config.backEnd}/api/admin/get-discount`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return resolve(response.data)
    } catch (err) {
      return reject(err)
    }
  })
}


//integrate api add Discount

export const AddDiscountBilling = ({ payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/api/admin/add-discount`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return resolve(response.data)
    } catch (err) {
      return reject(err)
    }
  })
}


//intergarte api edit Discount

export const editDiscount = ({ id, payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/api/admin/edit-discount/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return resolve(response.data)
    } catch (err) {
      return reject(err)
    }
  })
}

//integrate api delete Discount
export const deleteDiscount = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/api/admin/delete-discount/${id}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return resolve(response.data)
    } catch (err) {
      return reject(err)
    }
  })
}


//insert api getDiscountById 

export const getDiscountById = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(`${config.backEnd}/api/admin/get-discount/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return resolve(response.data)
    } catch (err) {
      return reject(err)
    }
  })
}

export const getCarrier = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(`${config.backEnd}/api/admin/get-carrier`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return resolve(response.data)
    } catch (err) {
      return reject(err)
    }
  })
}

export const deletePdf = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(`${config.backEnd}/api/admin/delete-pdf/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return resolve(response.data)
    } catch (err) {
      return reject(err)
    }
  })
}

export const getSubscriptionDetail = ({ payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/api/admin/get-Subscription-details`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return resolve(response.data)
    } catch (err) {
      return reject(err)
    }
  })
}

export const getSubscriptionDetailById = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(`${config.backEnd}/api/admin/view-Subscription-detail/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return resolve(response.data)
    } catch (err) {
      return reject(err)
    }
  })
}

export const getPaymentDetails = ({ payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/api/admin/get-payment-details`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return resolve(response.data)
    } catch (err) {
      return reject(err)
    }
  })
}
export const viewPaymentDetails =(id)=>{
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(`${config.backEnd}/api/admin/view-payment-details/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return resolve(response.data)
    } catch (err) {
      return reject(err)
    }
  })
}

export const getClientReports = ({ payload }) => {

  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/api/admin/get-clients-report`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
}
export const getSalesPersonReports = ({payload})=>{
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/api/admin/get-sales-report`, payload,{
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return resolve(response.data)
    } catch (err) {
      return reject(err)
    }
  })
}
export const signInAction = ({ payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/api/admin/login`, payload);
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const forgetPasswordEmail = ({payload})=>{
  return new Promise(async(resolve,reject)=>{
    try{
      let response= await axios.post(`${config.backEnd}/api/admin/email-otp`,payload);
      return resolve(response.data);
    }catch(err){
      return reject(err);
    }
  })
}

export const verifyOtps = ({payload}) =>{
  return new Promise(async(resolve,reject)=>{
    try{
      let response= await axios.post(`${config.backEnd}/api/admin/verify-otp`,payload);
      return resolve(response.data);
    }catch(err){
      return reject(err);
    }
  })
}

export const NewPasswordChange = ({payload})=>{
  return new Promise(async(resolve,reject)=>{
    try{
      let response= await axios.post(`${config.backEnd}/api/admin/reset-password`,payload);
      return resolve(response.data);
    }catch(err){
      return reject(err);
    }
  })
}


export const getClientDetailById = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(`${config.backEnd}/api/admin/view-client-report/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return resolve(response.data)
    } catch (err) {
      return reject(err)
    }
  })
}

export const getSalesReportById = ({ id, payload })=>{
  return new Promise(async(resolve,reject)=>{
    try{
      let response =  await axios.post(`${config.backEnd}/api/admin/view-sale-report/${id}`,payload,{
        headers:{
          Authorization: `Bearer ${token}`
        }
      })
      return resolve(response.data)
    }catch(err){
      return reject(err)
    }
  })
}

export const getCommisionReport =({payload})=>{
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/api/admin/get-commissions-report`,payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return resolve(response.data)
    } catch (err) {
      return reject(err)
    }
  })
}

export const viewCommisionReportById = (id)=>{
  return new Promise(async(resolve,reject)=>{
    try{
      let response =  await axios.get(`${config.backEnd}/api/admin/view-commission-report/${id}`,{
        headers:{
          Authorization: `Bearer ${token}`
        }
      })
      return resolve(response.data)
    }catch(err){
      return reject(err)
    }
  })
}


export const getSubscriptionReport = (payload)=>{
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/api/admin/get-subscriptions-report`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return resolve(response.data)
    } catch (err) {
      return reject(err)
    }
  })
}

export const getSubscriptionReportsById =(id)=>{
  return new Promise(async(resolve,reject)=>{
    try{
      let response =  await axios.get(`${config.backEnd}/api/admin/view-subscription-report/${id}`,{
        headers : {
          Authorization: `Bearer ${token}`
        }
      })
      return resolve(response.data)
    }catch(err){
      return reject(err)
    }
  })
}