import { useState } from "react";

import DashboardIcon from "../assets/icons/dashboard.png";
import ClientsIcon from "../assets/icons/clients.png";
import EmployeeIcon from "../assets/icons/employees.png";
import SalesPersonIcon from "../assets/icons/salesperson.png";
import SubscriptionPlanIcon from "../assets/icons/subscriptionplan.png";
import BillingIcon from "../assets/icons/billingicon.png";
import ReportsIcon from "../assets/icons/reports.png";
import NotificationIcon from "../assets/icons/notificationicon.png";


import AdminDashboard from "../views/admin-dashboard/AdminDashboard";
import ViewClient from "../views/admin-dashboard/Clientss/ViewClient";
import ManageClient from "../views/admin-dashboard/Clientss/ManageClient";
import ManageEmployees from "../views/admin-dashboard/Employee/ManageEmployees";
import ManageSalesPerson from "../views/admin-dashboard/SalesPerson/ManageSalesPerson";
import SubscriptionPlan from "../views/admin-dashboard/SubscriptionPlan/SubscriptionPlan";
import SubscriptionBilling from "../views/admin-dashboard/Billing/SubscriptionBilling";
import PaymentBilling from "../views/admin-dashboard/Billing/PaymentBilling";
import ManageDiscount from "../views/admin-dashboard/Billing/ManageDiscount";
import ClientReport from "../views/admin-dashboard/Reports/ClientReport";
import SalesReport from "../views/admin-dashboard/Reports/SalesReport";
import CommissionsReport from "../views/admin-dashboard/Reports/CommissionsReport";
import SubscriptionReport from "../views/admin-dashboard/Reports/SubscriptionReport";
// import Notification from "../views/admin-dashboard/Notification/Notification";
import ManageQuickNotification from "../views/admin-dashboard/managenotification/ManageQuickNotification";
import ManageCustomNotification from "../views/admin-dashboard/managenotification/ManageCustomNotification";

const Index = () => {

  const [activeComponent, setActiveComponent] = useState("");

  const changeComponent = (comp) => {
    setActiveComponent(comp);
  };

  function ShowComponent(e) {
    let allComponents = {

      AdminDashboard,
      ManageClient,
      ViewClient,
      ManageEmployees,
      ManageSalesPerson,
      SubscriptionPlan,
      SubscriptionBilling,
      PaymentBilling,
      ManageDiscount,
      ClientReport,
      SalesReport,
      CommissionsReport,
      SubscriptionReport,
      // Notification,
      ManageQuickNotification,
      ManageCustomNotification






    };
    const Component = activeComponent ? allComponents[e] : null;
    return <Component />;
  }



  const allTabs = [
    {
      name: "Dashboard",
      link: "/admin-dashboard",
      icon: DashboardIcon,
      iconWidth: "24px",
      iconHeight: "24px",
      subMenu: false,
    },

    {
      name: "Clients",
      link: "/manage-client",
      icon: ClientsIcon,
      iconWidth: "26.15px",
      iconHeight: "20px",
      // subMenu: [

      //   { name: "Manage Client", link: "/manage-client" },

      // ],
    },
    {
      name: "Employees",
      link: "/manage-employees",
      icon: EmployeeIcon,
      iconWidth: "16.18px",
      iconHeight: "24px",
      // subMenu: [

      //   { name: "Manage Employees", link: "/manage-employees" },

      // ],

    },

    {
      name: "Sales Person",
      link: "/sales-person",
      icon: SalesPersonIcon,
      iconWidth: "25.18px",
      iconHeight: "22px",
      // subMenu: [

      //   { name: "Manage Sales Person", link: "/sales-person" },

      // ],

    },

    {
      name: "Subscription Plan",
      link: "/subscription",
      icon: SubscriptionPlanIcon,
      iconWidth: "21.05px",
      iconHeight: "26px",
      subMenu: false,
    },

    {
      name: "Billing",
      link: "/subscription-billing",
      icon: BillingIcon,
      iconWidth: "18px",
      iconHeight: "18px",
      subMenu: [

        { name: "Subscription Billing", link: "/subscription-billing" },
        { name: "Payment Billing", link: "/payment-billing" },
        { name: "Manage Discount", link: "/manage-discount" },


      ],

    },

    {
      name: "Reports",
      link: "/client-report",
      icon: ReportsIcon,
      iconWidth: "18.29px",
      iconHeight: "24px",
      subMenu: [

        { name: "Client Reports", link: "/client-report" },
        { name: "Sales Report", link: "/sales-report" },
        { name: "Commissions Report", link: "/commision-report" },
        { name: "Subscription Report", link: "/subscription-report" },


      ],

    },

    {
      name: "Manage Notification",
      link: "/manage-quick-notification",
      icon: NotificationIcon,
      iconWidth: "22px",
      iconHeight: "22px",
      subMenu: [

        { name: "Manage Qucik Notification", link: "/manage-quick-notification" },
        { name: "Manage Custom Notification", link: "/manage-custom-notification" },
       


      ],
    },


  ];

  return { allTabs, activeComponent, setActiveComponent, changeComponent, ShowComponent };
};

export default Index;
