
import React, { useState, useEffect } from "react";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { AddDiscountBilling, getSubscription, editDiscount, getDiscountById } from "../../../utils/action";
import { InputNumber } from 'primereact/inputnumber';
import ContentArea from "../../../shared/ContentArea";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Loading } from "../../../components/Loader/Loading";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure()
export default function AddDiscount(props) {
    const [data, setData] = useState({
        discount_code: "",
        subscription_plans_id: "",
        discount_rate_percent: ""
    })
    const [error, setError] = useState({
        discount_code: "",
        subscription_plans_id: "",
        discount_rate_percent: ""
    })
    const [Programs, SetPrograms] = useState();
    const [selected, setSelected] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams()
    useEffect(() => {
        async function fetchMyAPI() {
            let response = await getSubscription();
            SetPrograms(response.data);
        }
        fetchMyAPI();
    }, [])
    useEffect(()=>{
        if(location.pathname.includes("edit-discount")){
            setIsLoading(true);

        }else{
            setIsLoading(false)
        }
    },[location])
    useEffect(() => {
        async function fetchMyAPI() {
            let response = await getDiscountById(id).then((res)=>{
              if(res.status === true){
                setIsLoading(false);
                setData(res.data[0]);
              }
            });
            
        }
        fetchMyAPI();
    }, [])
    const handleSubmit = async () => {
        setIsLoading(true)
        try {
            setIsLoading(false)
            if (data.discount_code === undefined || data.discount_rate_percent === null) {
                setError({
                    ...error,
                    discount_code: data.discount_code === undefined  ? "Please Enter Discount Code" : error.discount_code,
                    discount_rate_percent: data.discount_rate_percent === null ? "Please Enter Discount Percent" : error.discount_rate_percent
                })
                return false;
            }

            let response = await AddDiscountBilling({ payload: data })
            if (response.status === false) {
                setIsLoading(false);
                toast.error(response.message || "Something went wrong")
            } else if (response.status === true) {
                setIsLoading(false)
                setData(response.data)
                toast.success(response.message || "Add Discount Successfully")
                navigate("/manage-discount")
            }
        } catch (err) {

            console.log(err?.response?.data?.message, "========");
            let message = err && err.message ? err.message : "Something Went Wrong during login"
            toast.error(err?.response?.data?.message);
            setIsLoading(false)
            return false;
        }
    }
    useEffect(() => {
        setError({
            ...error,
            subscription_plans_id: null
        })
        setData({
            ...data,
            subscription_plans_id: selected?.id
        })
    }, [selected])
    const handleCancel = () => {
        navigate("/manage-discount")
    }
    const handleEdit = async () => {
        setIsLoading(true)
        try {
            setIsLoading(false)
            if (data.discount_code === "" || data.discount_rate_percent === '') {
                setError({
                    ...error,
                    discount_code: data.discount_code === "" ? "Please Enter Discount Code" : error.discount_code,
                    // subscription_plans_id: data.subscription_plans_id === undefined ? "Please enter Subscription Plan" : error.subscription_plans_id,
                    discount_rate_percent: data.discount_rate_percent === '' ? "Please Enter Discount Percent" : error.discount_rate_percent
                })
                return false;
            }
            let response = await editDiscount({ payload: data, id: id })
            if (response.status === false) {
                setIsLoading(false);
                toast.error(response.message || "Something went wrong")
            } else if (response.status === true) {
                setIsLoading(false)
                setData(response.data)
                toast.success(response.message || "Edit Discount Successfully")
                navigate("/manage-discount")
            }
        } catch (err) {
            console.log(err?.response?.data?.message, "========");
            let message = err && err.message ? err.message : "Something Went Wrong during login"
            toast.error(err?.response?.data?.message);
            setIsLoading(false)
            return false;
        }

    }
    return (
        <ContentArea>
            {isLoading ? <Loading /> :
                <div className="p-3">
                    <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">

                        <h2 className="text-main">
                            {location.pathname.includes("add-discount") ? "Add Employee Details" : "Edit Employee Details"}
                            {/* {props.title ? props.title : ""} */}
                        </h2>
                    </div>
                    <div className="shadow-2 p-5 border-round-lg">
                        <div className="p-fluid grid mb-4">
                            <div className="col-12 md:col-4">
                                <label className="font block mb-2">Discount Name</label>
                                <InputText
                                    placeholder="discount_code"
                                    ClassName="border-round-md"
                                    name="discount_code"
                                    value={data?.discount_code}
                                    onChange={(e) => {
                                        setError({
                                            ...error,
                                            discount_code: null,
                                        });
                                        setData({
                                            ...data,
                                            discount_code: e.target.value
                                        })
                                    }}
                                />
                                {error.discount_code && <span className="error">{error.discount_code}</span>}<br />
                            </div>
                            {/* <div className=" col-12 md:col-4">
                                <label className="font block mb-2">Program</label>

                                <Dropdown
                                    className="border-round-md"
                                    placeholder="select"
                                    value={selected || data?.subscription_plan}
                                    name="subscription_plans_id"
                                    options={Programs}
                                    onChange={(e) => setSelected(e.value)} optionLabel="program_name" />
                                {error.subscription_plans_id && <span className="error">{error.subscription_plans_id}</span>}
                            </div> */}
                            <div className=" col-12 md:col-4">
                                <label className="font block mb-2">Discount Rate</label>
                                <InputNumber
                                    placeholder="discount_rate_percent"
                                    inputClassName="border-round-md"
                                    value={data?.discount_rate_percent}
                                    name="discount_rate_percent"
                                    onValueChange={(e) => {
                                        setError({
                                            ...error,
                                            discount_rate_percent: null,
                                        });
                                        setData({
                                            ...data,
                                            discount_rate_percent: e.value
                                        })
                                    }}
                                />
                                {error.discount_rate_percent && <span className="error">{error.discount_rate_percent}</span>}
                            </div>
                        </div>
                        <div className=" btn flex">
                            <div className="text-center">
                                <Button
                                    label="Save"
                                    className=" w-full px-5 border-0 p-3 border-round-md bg-main"
                                    onClick={location.pathname.includes("/add-discount") ? handleSubmit : handleEdit}
                                />
                            </div>
                            <div className="text-center">
                                <Button
                                    label="Cancel"
                                    className="w-full surface-500 p-3 px-5 ml-3   border-0 border-round-md "
                                    onClick={handleCancel}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }

        </ContentArea>

    )
}
