
import React, { useState, useRef } from "react";
import ContentArea from "../../../shared/ContentArea";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { MultiSelect } from "primereact/multiselect";

export default function SendQuickNotification() {
  const [selectedCities, setSelectedCities] = useState(null);

  const cilent = [
    { name: "James Wyatt", code: "JW" },
    { name: "John Leo", code: "JL" },
    { name: "Robert Michael", code: "RM" },
    { name: "Michael Grey", code: "MG" },
    { name: "Oliver Mahone", code: "OM" },
    { name: "Jack Ryan", code: "JR" },
    { name: "Harry Style", code: "HS" },
  ];

  const toast = useRef(null);

  const onUpload = () => {
    toast.current.show({
      severity: "info",
      summary: "Success",
      detail: "File Uploaded",
    });
  };

  const [ingredients, setIngredients] = useState([]);

  const onIngredientsChange = (e) => {
    let _ingredients = [...ingredients];

    if (e.checked) _ingredients.push(e.value);
    else _ingredients.splice(_ingredients.indexOf(e.value), 1);

    setIngredients(_ingredients);
  };

  return (
    <>
      <ContentArea>
        <div className="p-3">
          <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 ">
            <div className="md:col-12 p-0 m-0">
              <h1
                className="text-main px-5 flex justify-content-between Karla"
                style={{ fontSize: "24px" }}
              >
                Send Quick Notifications
              </h1>
            </div>
          </div>

         

          <div className="shadow-2 p-5 border-round-lg">
            <div className="p-fluid grid mb-3">
              <div className="col-12 md:col-12 ">
                <label className="font block mb-2"> Title </label>
                <InputText
                  id="inputtext"
                  placeholder="Lorem Ipsum is simply dummy text"
                  className="border-round-md "
                />
              </div>
            </div>

            <div className="p-fluid grid mb-4">
              <div className="col-12 md:col-12">
                <label className="font block mb-2">Description</label>
                <InputTextarea
                  className="border-round-md"
                  placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
                  rows={4}
                />
              </div>
            </div>
            <p className="text-lg">Send via</p>
            <div className="card flex mb-5">
              <div className="flex align-items-center">
                <Checkbox
                  inputId="ingredient1"
                  name="email"
                  value="Email"
                />
                <label htmlFor="ingredient1" className="ml-3">
                  Email
                </label>
              </div>
              <div className="flex align-items-center ml-3">
                <Checkbox
                  inputId="ingredient2"
                  name="sms"
                  value="SMS"
                />
                <label htmlFor="ingredient2" className="ml-3">
                  SMS
                </label>
              </div>
              <div className="flex align-items-center ml-3">
                <Checkbox
                  inputId="ingredient3"
                  name="software"
                  value="Software"
                />
                <label htmlFor="ingredient3" className="ml-3">
                  Software
                </label>
              </div>
            </div>
            <div className="grid mb-5">
              <div className="md:col-12">
                <FileUpload
                  mode="basic"
                  className="file-upload text-white border-round-lg"
                  chooseLabel="Add File"
                  name="demo[]"
                  url="/api/upload"
                  accept="image/*"
                  maxFileSize={1000000}
                  onUpload={onUpload}
                />
              </div>
            </div>

            <div className="card flex mb-5">
              <div className="flex align-items-center">
                <Checkbox
                  inputId="ingredient1"
                  name="pizza"
                  value="Employee"
                  onChange={onIngredientsChange}
                  checked={ingredients.includes("Employee")}
                />
                <label htmlFor="ingredient1" className="ml-3">
                  Employee
                </label>
              </div>
              <div className="flex align-items-center ml-3">
                <Checkbox
                  inputId="ingredient2"
                  name="Clients"
                  value="Clients"
                  onChange={onIngredientsChange}
                  checked={ingredients.includes("Clients")}
                />
                <label htmlFor="ingredient2" className="ml-3">
                  Clients
                </label>
              </div>
              <div className="flex align-items-center ml-3">
                <Checkbox
                  inputId="ingredient3"
                  name="pizza"
                  value="All"
                  onChange={onIngredientsChange}
                  checked={ingredients.includes("All")}
                />
                <label htmlFor="ingredient3" className="ml-3">
                  Sales Person
                </label>
              </div>


              <div className="flex align-items-center ml-3">
                <Checkbox
                  inputId="ingredient3"
                  name="pizza"
                  value="All"
                  onChange={onIngredientsChange}
                  checked={ingredients.includes("All")}
                />
                <label htmlFor="ingredient3" className="ml-3">
                  All
                </label>
              </div>
            </div>

            <div className="grid mb-5">
              <div className="md:col-4 h-10rem">
                <MultiSelect
                  value={selectedCities}
                  onChange={(e) => setSelectedCities(e.value)}
                  options={cilent}
                  optionLabel="name"
                  placeholder="Select Cities"
                  className="w-full border-round-lg "
                />
              </div>
            </div>

            <div className="grid mt-5">
              <div className="  md:col-1 text-center">
                <Button
                  label="Send"
                  className="w-full bg-main border-0 p-3 border-round-md "
                />
              </div>
            </div>
          </div>
        </div>
      </ContentArea>
    </>
  );
}
