import React, { useState, useEffect } from "react";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import ContentArea from "../../../shared/ContentArea";
import EmployeeImage from "../../../assets/images/Employeeimage.png"
import { InputSwitch } from 'primereact/inputswitch';
import { editEmployee, viewEmployess, getPermission, getCarrier } from "../../../utils/action";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../../components/Loader/Loading";
import Permissions from "./Permission";
import { validatePhoneNumber, validateZipcodeNumber, validEmail } from "../../../shared/Validation";
import { Dropdown } from "primereact/dropdown";
toast.configure()
export default function EditEmployees(props) {
    const [checked, setChecked] = useState(false);
    const [img, setImg] = useState({ image: null, updated: false })
    const [value, setValue] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const [permissions, setPermissions] = useState([])
    const [selectedNodes, setSelectedNodes] = useState({});
    const [selectedKeys, setSelectedKeys] = useState([])
    const [carrier, setCarrier] = useState("")
    const [selectedCarrier, setSelectedCarrier] = useState(null);
    const [IsId, setId] = useState({
        employee_id: ""
    })
    const navigate = useNavigate()
    const [data, setData] = useState({
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        password: "",
        mobile: "",
        dob: "",
        social_security_no: "",
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        zipcode: "",
        hourly_rate: "",
        carrier: "",
        image: img,
        username: "",
        emp_id: "",
        permissions: [],
        is_active: "",
        is_sms_notify: "",
        is_email_notify: "",
        selected_nodes: {}
    })
    const [error, setError] = useState({
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        password: "",
        mobile: "",
        dob: "",
        social_security_no: "",
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        zipcode: "",
        hourly_rate: "",
        carrier: "",
        image: "",
        username: "",
        emp_id: "",
        permissions: "",
    })
    const { id } = useParams()
    useState(() => {
        setId({
            employee_id: id
        })
    })
    const showViewData = async () => {
        let response = await viewEmployess({ payload: IsId })
        setValue(response.data[0].is_active)
        if (response.data[0].is_active) {
            setChecked(true)
        }
        response.data[0].middle_name = response.data[0].middle_name === null ? "" : response.data[0].middle_name
        response.data[0].dob = new Date(response.data[0].dob.slice(0, 10));
        response.data[0].social_security_no = response.data[0].social_security_no ==='null'?"":response.data[0].social_security_no
        setSelectedNodes(response.data[0].selected_nodes)
        setData(response.data[0])
    }
    useEffect(() => {
        showViewData()
    }, [])
    const handleChange = (e) => {
        setChecked(e)
        setData({
            ...data,
            [e?.target?.name]: e?.target?.value,
        });
        setError({
            ...error,
            [e?.target?.name]: "",
        });
    };
    useEffect(() => {
        async function fetchMyAPI() {
            let response = await getPermission();

            const permissionData = [];

            response.data.map((permission) => {
                const permObj = {
                    key: permission.id,
                    label: permission.type,
                    data: permission.type,
                    icon: '',
                    children: []
                }

                permission.permissions.map(child => {
                    let childObj = {
                        key: permission.id + "-" + child.id,
                        label: child.name,
                        data: child.name
                    }
                    permObj.children.push(childObj);
                });

                permissionData.push(permObj);
            });
            setPermissions(permissionData);
        }
        fetchMyAPI();
    }, [])
    const handleReset = () => {
        setData((prev) => {
            return {
                ...prev,
                name: "",
                password: ""
            }
        })
    };
    let date = new Date(data.dob)
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();
    if (dt < 10) {
        dt = '0' + dt;
    }
    if (month < 10) {
        month = '0' + month;
    }
    let dob = year + '-' + month + '-' + dt;
    const handleSubmit = async (e) => {
        setIsLoading(true);
        try {
            setIsLoading(false)
            e.preventDefault();
            if (data.first_name === "" || data.last_name === "" || data.email === "" ||
                data.mobile === "" || data.dob === null || data.address_1 === "" || !validEmail(data.email) ||
                data.city === "" || data.state === "" || data.zipcode === null || data.hourly_rate === "" || data.carrier === "" ||
                data.username === ""  || !validatePhoneNumber(data.mobile) || !validateZipcodeNumber(data.zipcode) ||data.permissions?.length===0) {
                setError({
                    ...error,
                    first_name: data.first_name === "" ? "Please Enter FirstName" : error?.first_name,
                    last_name: data.last_name === "" ? "Please Enter LastName" : error?.last_name,
                    email: data.email === "" ? "Please Enter Email" : !validEmail(data.email) ? "Please Enter valid Email " : error?.email,
                    password: data.password === "" ? "Please Enter Password" : error?.password,
                    mobile: data.mobile === "" ? "Please Enter Mobile" : !validatePhoneNumber(data.mobile) ? "Please Enter Valid Mobile Number" : error.mobile,
                    dob: data.dob === null ? "Please Enter dob" : error?.dob,
                    address_1: data.address_1 === "" ? "Please Enter Address" : error.address_1,
                    city: data.city === "" ? "Please Enter City" : error.city,
                    state: data.state === "" ? "Please Enter State" : error.state,
                    hourly_rate: data.hourly_rate === "" ? "Please Enter Hourly Rate" : error.hourly_rate,
                    carrier: data.carrier === "" ? "Please Enter Carrier" : error.carrier,
                    image: img.image === "" ? "Please Enter Image" : error.image,
                    username: data.username === "" ? "Please Enter Name" : error.username,
                    zipcode: data.zipcode === null ? "Please Enter ZipCode" : !validateZipcodeNumber(data.zipcode) ? "Please Enter Valid ZipCode" : error.zipcode,
                    emp_id: data.emp_id === null ? "Please Enter Employee Id" : error.emp_id,
                    permissions:data.permissions?.length ===0?"Please Enter Permission":error.permission,
                })
                setIsLoading(false);
                return false;
            }
            const formData = new FormData();

            formData.append("emp_id", data.id);
            if (img.updated) {
                formData.append("image", img?.image);
            }
            formData.append("first_name", data.first_name);
            formData.append("middle_name", data.middle_name)
            formData.append("last_name", data.last_name);
            formData.append("email", data.email);
            formData.append("mobile", data.mobile);
            formData.append("dob", dob);
            formData.append("social_security_no", data.social_security_no);
            formData.append("address_1", data.address_1);
            formData.append("address_2", data.address_2);
            formData.append("city", data.city);
            formData.append("state", data.state);
            formData.append("zipcode", data.zipcode);
            formData.append("hourly_rate", data.hourly_rate);
            formData.append("carrier", data.carrier);
            formData.append("permissions", JSON.stringify(data?.permissions));
            formData.append("is_active", value)
            formData.append("is_email_notify", data.is_email_notify);
            formData.append("is_sms_notify", data.is_sms_notify);
            formData.append("selected_nodes", JSON.stringify(data.selected_nodes))
            formData.append("username",data.username)
            formData.append("password",data.password)
            let response;
            if (id) {
                response = await editEmployee({ payload: formData, id: id })
            }
            if (response.status === false) {
                setIsLoading(false);
                toast.error(response.message || "Something went Wrong")
            }
            if (response.status === true) {
                setIsLoading(false);
                toast.success(response.message)
                navigate("/manage-employees")
                return true;
            }
        } catch (err) {
            setIsLoading(false);
            let message = err && err.message ? err.message : "Something Went Wrong during login"
            toast.error(err?.response?.data?.message)
            return false;
        }

    }
    const cancleButton = () => {
        navigate("/manage-employees")
    }

    const handleToggle = (e) => {
        setChecked((prev) => !prev)
        if (!checked) {
            setValue(1)
        } else {
            setValue(0)
        }
    };
    useEffect(() => {
        async function fetchMyAPI() {
            let response = await getCarrier();
            setCarrier(response.data)
        }
        fetchMyAPI();
    }, [])
    useEffect(() => {
        carrier.length > 0 && carrier?.map((item) => {
            if (item.id == data.carrier) {
                setSelectedCarrier(item)
            }
        })
    }, [carrier])
    const handleCarrier = (e) => {
        setSelectedCarrier(e.value)
        setData((prev) => {
            return {
                ...prev,
                carrier: e.value.id
            }
        })
        setError({
            ...error,
            carrier : "",
        });
    }
    return (
        <ContentArea>
            {isLoading ? <Loading /> :
                <div className="p-3">
                    <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                        <h2 className="text-main">
                            {props.title ? props.title : "Edit Employee Details"}
                        </h2>
                    </div>
                    <div className="shadow-2 p-5 border-round-lg">
                        <h2 className="text-main mb-5 p-0 m-0 Karla flex align-items-center justify-content-between">  Employee Details
                            <span className="flex text-lg text-700">
                                Active &nbsp;&nbsp;  <InputSwitch checked={checked} onChange={(e) => handleToggle(e)} name="is_active" />
                            </span>
                        </h2>
                        <div className="p-fluid grid p-0 m-0">
                            <div className="col-12 md:col-12 p-0 m-0 ">
                                <p>Upload Image</p>
                            </div>
                        </div>
                        <div className="p-fluid grid flex align-items-center mb-4">
                            <div className="upload-image col-12 md:col-2">
                                <img src={img?.image ? URL.createObjectURL(img?.image) : data?.image?.length != 0 ? data?.image : EmployeeImage} alt="" width={102} height={109} className="w-full h-full" />
                            </div>
                            <div className="col-12 md:col-10">
                                <div className="upload_image">
                                    <label htmlFor="profile-img" className="bg-primary cursor-pointer w-3 px-5 border-0 p-2 border-round-md">
                                        <i className="pi pi-plus mr-3"></i>
                                        <span>Choose a File</span>
                                    </label>
                                    <input type="file" mode="basic" hidden className="file-upload text-white border-round-lg" id="profile-img" chooseLabel="Choose a file" name="profile-img"
                                        accept="image/*" maxFileSize={1000000} onChange={(e) => {
                                            setError({
                                                ...error,
                                                image: null,
                                            });
                                            if (e.target.files.length) {
                                                setImg({ image: e.target.files[0], updated: true });
                                            }
                                        }} />
                                    {error.image && <span className="error">{error.image}</span>}
                                </div>
                                <p className="text-500"> Acceptable Formats jpg, png only </p>
                            </div>
                        </div>
                        <div className="p-fluid grid mb-4">
                            <div className="col-12 md:col-4">
                                <label className="font block mb-2">Employee Id<code className="text-red" >*</code></label>
                                <InputText
                                    inputClassName="border-round-md disable-input"
                                    placeholder=""
                                    name="emp_id"
                                    useGrouping={false}
                                    value={data.emp_id}
                                />
                            </div>
                            <div className=" col-12 md:col-4">
                                <label className="font block mb-2"> Employee Email<code className="text-red" >*</code> </label>
                                <InputText
                                    className="border-round-md"
                                    placeholder=""
                                    name="email"
                                    value={data.email}
                                    onChange={(e) => handleChange(e)}
                                />
                                {error.email && <span className="error">{error.email}</span>}<br />
                            </div>
                            <div className=" col-12 md:col-4">
                                <label className="font block mb-2">Social Security No.</label>
                                <InputText
                                    placeholder=""
                                    className="border-round-md"
                                    name="social_security_no"
                                    value={data.social_security_no}
                                    onChange={(e) => handleChange(e)}
                                />
                                {error.social_security_no && <span className="error">{error.social_security_no}</span>}<br />
                            </div>
                            <div className="col-12 md:col-4">
                                <label className="font block mb-2">First Name<code className="text-red" >*</code> </label>
                                <InputText
                                    inputClassName="border-round-md"
                                    placeholder=""
                                    name="first_name"
                                    value={data.first_name}
                                    onChange={(e) => handleChange(e)}
                                />
                                {error.first_name && <span className="error">{error.first_name}</span>}<br />
                            </div>
                            <div className=" col-12 md:col-4">
                                <label className="font block mb-2">Middle Name</label>
                                <InputText
                                    className="border-round-md"
                                    placeholder=""
                                    name="middle_name"
                                    value={data.middle_name}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                            <div className=" col-12 md:col-4">
                                <label className="font block mb-2">Last Name<code className="text-red" >*</code> </label>
                                <InputText
                                    className="border-round-md"
                                    placeholder=""
                                    name="last_name"
                                    value={data.last_name}
                                    onChange={(e) => handleChange(e)}
                                />
                                {error.last_name && <span className="error">{error.last_name}</span>}<br />
                            </div>
                            <div className="col-12 md:col-4 ">
                                <label className="font block mb-2"> DOB<code className="text-red" >*</code></label>
                                <Calendar
                                    className="border-round-md calender_picker "
                                    inputClassName="calendar p-2 border-round-md "
                                    placeholder=""
                                    value={data?.dob}
                                    name="dob"
                                    onChange={(e) => {
                                        setError({
                                            ...error,
                                            dob: null,
                                        });
                                        setData({ ...data, dob: e.value });
                                    }}
                                />
                                {error.dob && <span className="error">{error.dob}</span>}
                            </div>
                            <div className="col-12 md:col-4 ">
                                <label className="font block mb-2">
                                    Carrier<code className="text-red">*</code>
                                </label>
                                <Dropdown className="border-round-md " name="carrier" options={carrier} optionLabel="carrier_name" value={selectedCarrier} onChange={(e) => handleCarrier(e)} />
                                {error.carrier && <span className="error">{error.carrier}</span>}
                            </div>
                            <div className=" col-12 md:col-4">
                                <label className="font block mb-2">Phone No.<code className="text-red" >*</code></label>
                                <InputNumber
                                    className="border-round-md"
                                    inputClassName="border-round-md"
                                    placeholder=""
                                    name="mobile"
                                    useGrouping={false}
                                    value={data.mobile}
                                    maxLength={10}
                                    onValueChange={(e) => {
                                        setError({
                                            ...error,
                                            mobile: null,
                                        });
                                        setData({
                                            ...data,
                                            mobile: e.value
                                        })
                                    }}
                                />
                                {error.mobile && <span className="error">{error.mobile}</span>}<br />
                            </div>
                            <div className="col-12 md:col-4 ">
                                <label className="font block mb-2"> Address 1<code className="text-red" >*</code></label>
                                <InputText
                                    id="inputtext"
                                    className="border-round-md "
                                    placeholder=""
                                    name="address_1"
                                    value={data.address_1}
                                    onChange={(e) => handleChange(e)}
                                />
                                {error.address_1 && <span className="error">{error.address_1}</span>}<br />
                            </div>
                            <div className=" col-12 md:col-4">
                                <label className="font block mb-2"> Address 2 </label>
                                <InputText
                                    id="inputtext"
                                    className="border-round-md "
                                    placeholder=""
                                    name="address_2"
                                    value={data.address_2}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                            <div className="col-12 md:col-4 ">
                                <label className="font block mb-2"> City<code className="text-red" >*</code></label>
                                <InputText
                                    id="inputtext"
                                    className="border-round-md "
                                    placeholder=""
                                    name="city"
                                    value={data.city}
                                    onChange={(e) => handleChange(e)}
                                />
                                {error.city && <span className="error">{error.city}</span>}<br />
                            </div>
                            <div className="col-12 md:col-4 ">
                                <label className="font block mb-2"> State<code className="text-red" >*</code></label>
                                <InputText
                                    id="inputtext"
                                    className="border-round-md "
                                    placeholder=""
                                    name="state"
                                    value={data.state}
                                    onChange={(e) => handleChange(e)}
                                />
                                {error.state && <span className="error">{error.state}</span>}<br />
                            </div>
                            <div className="col-12 md:col-4 ">
                                <label className="font block mb-2"> Zip Code<code className="text-red" >*</code></label>
                                <InputNumber
                                    id="inputnumber"
                                    className="border-round-md "
                                    inputClassName="border-round-md"
                                    placeholder=""
                                    name="zipcode"
                                    useGrouping={false}
                                    value={data.zipcode}
                                    maxLength={5}
                                    onValueChange={(e) => {
                                        setError({
                                            ...error,
                                            zipcode: null,
                                        });
                                        setData({
                                            ...data,
                                            zipcode: e.value
                                        })
                                    }}
                                />
                                {error.zipcode && <span className="error">{error.zipcode}</span>}<br />
                            </div>
                        </div>
                        <div className="p-fluid grid mb-4 flex align-items-center">
                            <div className=" col-12 md:col-4">
                                <label className="font block mb-2">Set Employee Hourly Rate*</label>
                                <InputText className="hr border-round-md" placeholder='/hr' name="hourly_rate" value={data.hourly_rate} onChange={(e) => handleChange(e)} ></InputText>
                                {error.hourly_rate && <span className="error">{error.hourly_rate}</span>}<br />
                            </div>
                            <div className=" col-12 md:col-8 mt-5 ">
                                <input className='form-check-input ml-5 mb-2' type="checkbox"
                                    name="remember" checked={data.is_email_notify}
                                    onChange={(e) => {
                                        setError({
                                            ...error,
                                            is_email_notify: null,
                                        });
                                        setData({ ...data, is_email_notify: (e.target.checked === true ? 1 : 0) });
                                    }} />&nbsp; Check to receive the Email reminder notifications.

                                <div className="col-12 md:col-12 p-0 m-0 ">
                                    <input className='form-check-input ml-5' type="checkbox"
                                        name="remember" checked={data.is_sms_notify}
                                        onChange={(e) => {
                                            setError({
                                                ...error,
                                                is_sms_notify: null,
                                            });
                                            setData({ ...data, is_sms_notify: (e.target.checked === true ? 1 : 0) });
                                        }}
                                    />&nbsp; Check to receive the SMS reminder notifications.
                                </div>
                            </div>
                        </div>
                        <p className="text-main text-2xl font-bold Karla ">Set Login Details</p>
                        <div className="p-fluid grid mb-4 flex align-items-center">
                            <div className=" col-12 md:col-4">
                                <label className="font block mb-2 ">Employee login/ Username<code className="text-red" >*</code></label>
                                <InputText
                                    className="border-round-md"
                                    name="username"
                                    value={data.username}
                                    onChange={(e) => handleChange(e)}
                                />
                                {error.username && <span className="error">{error.username}</span>}<br />
                            </div>
                        </div>
                        <div className="p-fluid grid mb-4">
                            <div className=" col-12 md:col-4">
                                <label className="font block mb-2 "> Employee Password<code className="text-red" >*</code></label>
                                <InputText
                                    className="border-round-md"
                                    name="password"
                                    value={data.password}
                                    onChange={(e) => handleChange(e)}
                                />
                                {error.password && <span className="error">{error.password}</span>}<br />
                            </div>
                            <div className=" col-12 md:col-8 mt-2">
                                <p className=" text-lg flex align-items-center"> Reset
                                    <span className="">
                                        <Button
                                            icon="pi pi-replay"
                                            className="border-round-3xl  ml-3 "
                                            onClick={handleReset}
                                        ></Button>
                                    </span>
                                </p>
                            </div>
                        </div>
                        <h3 className="text-main ">Set Permissions</h3>
                        <Permissions permissions={permissions} selectedNodes={selectedNodes} value={data} setSelectedNodes={setSelectedNodes} setSelectedKeys={setData} setError={setError} error ={error}/>
                       {error.permissions && <span className="error">{error.permissions}</span>}
                        <div className=" btn flex">
                            <div className="text-center">
                                <Button
                                    label="Save"
                                    className="w-full p-3 px-5  border-0 border-round-md bg-main"
                                    onClick={handleSubmit}
                                />
                            </div>
                            <div className="text-center">
                                <Button
                                    label="Cancel"
                                    className="w-full surface-500 p-3  ml-5 border-0 border-round-md "
                                    onClick={cancleButton}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </ContentArea>
    )
}
