import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import ContentArea from "../../../shared/ContentArea";
import Table from "../../../components/TableList/Table";
import FileIcon from "../../../assets/icons/fileicon.png";
import CloseIcon from "../../../assets/icons/close.png";
import { addSalesPerson, getCarrier, ViewDiscount, deletePdf } from "../../../utils/action";
import { Loading } from "../../../components/Loader/Loading";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  validatePhoneNumber,
  validateZipcodeNumber,
  validEmail
} from "../../../shared/Validation";
import DropydownMenu from "../../../components/Dropdown/DropdownMenu";
import { Dropdown } from "primereact/dropdown";
import { useEffect } from "react";
toast.configure();
export default function AddSalesPerson(props) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [carrier, setCarrier] = useState("")
  const [discount, setDiscount] = useState([])
  const [selectedCarrier, setSelectedCarrier] = useState(null)
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [tableData, setTableData] = useState("")
  const [data, setData] = useState({
    sales_person_id: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    carrier: "",
    mobile: "",
    dob: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    zipcode: "",
    member_since: "",
    discount: "",
    files: [],
    is_active:"1",
    commission: ""
  });
  const [error, setError] = useState({
    sales_person_id: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    carrier: "",
    mobile: "",
    dob: "",
    address_1: "",
    city: "",
    state: "",
    zipcode: "",
    member_since: "",
    discount: "",
    files: "",
  });
  const handleDeletePdf = async (nam) => {
    let object
    let pdf = data.files
    let array = []
    let filePdf = Object.values(pdf)
    var ind = filePdf.findIndex(function (element) {
      return element.name === nam;
    })
    if (ind !== -1) {
      filePdf.splice(ind, 1)
    }
    filePdf.length > 0 && filePdf.map((item) => {
      object = {
        name: item.name,
        size: item.size,
        lastModifiedDate: item.lastModifiedDate?.toLocaleDateString(),
      }
      array.push(object)
    })
    setTableData(array)
    setData((prev) => {
      return {
        ...prev,
        files: filePdf
      }
    })
  }
  useEffect(() => {

  }, [tableData])
  let date = new Date(data.dob)
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();
  if (dt < 10) {
    dt = '0' + dt;
  }
  if (month < 10) {
    month = '0' + month;
  }
  let dob = year + '-' + month + '-' + dt;
  let dates = new Date(data.member_since)
  let years = dates.getFullYear();
  let months = dates.getMonth() + 1;
  let dts = dates.getDate();
  if (dts < 10) {
    dts = '0' + dts;
  }
  if (months < 10) {
    months = '0' + months;
  }
  let member = years + '-' + months + '-' + dts;
  const handleSubmit = async (e) => {
    let file = data.files
    setIsLoading(true);
    try {
      e.preventDefault();
      if (
        (data.first_name === "" ||
          data.last_name === "" ||
          data.email === "" ||
          data.carrier === "" ||
          data.mobile === "" ||
          data.dob === "" ||
          data.address_1 === "" ||
          data.city === "" ||
          data.state === "" ||
          data.zipcode === "" ||
          data.member_since === "" ||
          data.discount === "" ||
          data.files === "" ||
          data.dob === "",
          data.member_since === "" ||
          !validatePhoneNumber(data.mobile) ||
          !validateZipcodeNumber(data.zipcode)) ||
        !validEmail(data.email)||
        data.files?.length === 0 ||
        data.commission ===null
      ) {
        setError({
          ...error,
          first_name:
            data.first_name === ""
              ? "Please Enter First Name"
              : error.first_name,
          last_name:
            data.last_name === "" ? "Please Enter Last Name" : error.last_name,
          email: data.email === "" ? "Please Enter Email" : !validEmail(data.email) ? "Please Enter Valid Email" : error.email,
          carrier: data.carrier === "" ? "Please Enter Carrier" : error.carrier,
          mobile:
            data.mobile === ""
              ? "Please Enter Mobile"
              : !validatePhoneNumber(data.mobile)
                ? "Please Enter Valid Mobile Number"
                : error.mobile,
          dob: data.dob === "" ? "Please Enter DOB" : error.dob,
          address_1:
            data.address_1 === "" ? "Please Enter Address" : error.address_1,
          city: data.city === "" ? "Please Enter City" : error.city,
          state: data.state === "" ? "Please Enter State" : error.state,
          zipcode:
            data.zipcode === ""
              ? "Please Enter Zip Code"
              : !validateZipcodeNumber(data.zipcode)
                ? " Please Enter Valid Zip Code"
                : error.zipcode,
          discount:
            data.discount === "" ? "Please Enter Discount" : error.discount,
          files:  data.files?.length === 0 ? "Please Select File" : error.files,
          member_since:
            data.member_since === ""
              ? "Please Enter Member since"
              : error.member_since,
              commission:data.commission ===null?"Please Enter Commision":error.commission
        });
        setIsLoading(false);
        return false;
      }
      const formData = new FormData();
      for (let i = 0; i <= file?.length; i++) {
        formData.append("files[]", file[i]);
      }
      formData.append("sales_person_id", data.sales_person_id);
      formData.append("first_name", data.first_name);
      formData.append("middle_name", data.middle_name);
      formData.append("last_name", data.last_name);
      formData.append("email", data.email);
      formData.append("carrier", data.carrier);
      formData.append("mobile", data.mobile);
      formData.append("dob", dob);
      formData.append("address_1", data.address_1);
      formData.append("address_2", data.address_2);
      formData.append("city", data.city);
      formData.append("state", data.state);
      formData.append("zipcode", data.zipcode);
      formData.append("member_since", member);
      formData.append("discount", data.discount);
      formData.append("is_active", data.is_active);
      formData.append("commission", data.commission);
      let response = await addSalesPerson({ payload: formData });
      if (response.status === false) {
        setIsLoading(false);
        toast.error(response.message || "Something went Wrong");
      } else if (response.status === true) {
        setIsLoading(false);
        toast.success(response?.message || "Client Created Successfully");
        setData({
          sales_person_id: "",
          first_name: "",
          middle_name: "",
          last_name: "",
          email: "",
          carrier: "",
          mobile: "",
          dob: "",
          address_1: "",
          address_2: "",
          city: "",
          state: "",
          zipcode: "",
          member_since: "",
          discount: "",
          file: "",
        });
        navigate("/sales-person");
        return true;
      }
    } catch (err) {
      setIsLoading(false);
      toast.error(err?.response?.data?.message);
      return false;
    }
  };
  const handleChange = (e) => {
    setData({
      ...data,
      [e?.target?.name]: e?.target?.value,
    });
    setError({
      ...error,
      [e?.target?.name]:"",
    });
  };
  const tableHeading = [
    { field: "fileicon", header: "", body: "fileIconBodyTemplate" },
    { field: "name", header: "File Name" },
    { field: "size", header: "File size" },
    { field: "lastModifiedDate", header: "Date modified" },
    { field: "closeicon", header: "", body: "closePdfIconBodyTemplate" },
  ];
  useEffect(() => {
    let object
    let pdf = data.files
    let array = []
    let filePdf = Object.values(pdf)
    filePdf.length > 0 && filePdf.map((item) => {
      object = {
        name: item.name,
        size: item.size,
        lastModifiedDate: item.lastModifiedDate?.toLocaleDateString(),
      }
      array.push(object)
    })
    setTableData(array)
  }, [data])
  const cancelButton = () => {
    navigate("/sales-person");
  };
  useEffect(() => {
    async function fetchMyAPI() {
      let response = await getCarrier();
      setCarrier(response.data)
    }
    fetchMyAPI();
  }, [])
  const handleCarrier = (e) => {
    setSelectedCarrier(e.value)
    setData((prev) => {
      return {
        ...prev,
        carrier: e.value.id
      }
    })
    setError((prev)=>{
      return{
        ...prev,
        carrier:""
      }
    })
  }
  useEffect(() => {
    async function fetchMyAPI() {
      let response = await ViewDiscount();
      setDiscount(response.data)
    }
    fetchMyAPI();
  }, [])
  console.log(discount,"=====discount=====")
  const handleDiscount = (e) => {
    setSelectedDiscount(e.value)
    setData((prev) => {
      return {
        ...prev,
        discount: e.value.id
      }
    })
    setError((prev)=>{
      return{
        ...prev,
        discount:""
      }
    })
  }
  return (
    <ContentArea>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="p-3">
          <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
            <h2 className="text-main">
              {props.title ? props.title : "Add Sales Person"}
            </h2>
          </div>
          <div className="shadow-2 p-5 border-round-lg">
            <div className="p-fluid grid mb-4">
              {/* <div className="col-12 md:col-4">
                <label className="font block mb-2">
                  Salesperson Id
                </label>
                <InputNumber
                  placeholder=""
                  inputClassName="border-round-md"
                  value={data.sales_person_id}
                  name="sales_person_id"
                  useGrouping={false}
                  onValueChange={(e) => {
                    setError({
                      ...error,
                      sales_person_id: null,
                    });
                    setData({ ...data, sales_person_id: e.value });
                  }}
                />
                {error.sales_person_id && (
                  <span className="error">{error.sales_person_id}</span>
                )}
                <br />
              </div> */}
              <div className="col-12 md:col-4 ">
                <label className="font block mb-2">
                  First Name<code className="text-red">*</code>
                </label>
                <InputText
                  id="inputtext"
                  placeholder=""
                  name="first_name"
                  className="border-round-md "
                  value={data.first_name}
                  onChange={(e) => handleChange(e)}
                />
                {error.first_name && (
                  <span className="error">{error.first_name}</span>
                )}
              </div>
              <div className=" col-12 md:col-4">
                <label className="font block mb-2"> Middle Name </label>
                <InputText
                  id="inputtext"
                  placeholder=""
                  name="middle_name"
                  className="border-round-md "
                  value={data.middle_name}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="col-12 md:col-4 ">
                <label className="font block mb-2">
                  Last Name<code className="text-red">*</code>
                </label>
                <InputText
                  id="inputtext"
                  placeholder=""
                  className="border-round-md "
                  name="last_name"
                  value={data.last_name}
                  onChange={(e) => handleChange(e)}
                />
                {error.last_name && (
                  <span className="error">{error.last_name}</span>
                )}
              </div>
              <div className="col-12 md:col-4 ">
                <label className="font block mb-2">
                  Email<code className="text-red">*</code>
                </label>
                <InputText
                  id="inputtext"
                  placeholder=""
                  name="email"
                  className="border-round-md "
                  value={data.email}
                  onChange={(e) => handleChange(e)}
                />
                {error.email && <span className="error">{error.email}</span>}
              </div>
              <div className="col-12 md:col-4 ">
                <label className="font block mb-2">
                  Carrier<code className="text-red">*</code>
                </label>
                <Dropdown className="border-round-md " placeholder="" name="carrier" options={carrier} optionLabel="carrier_name" value={selectedCarrier} onChange={(e) => handleCarrier(e)} />
                {error.carrier && (
                  <span className="error">{error.carrier}</span>
                )}
              </div>
              <div className=" col-12 md:col-4">
                <label className="font block mb-2">
                  Phone No.<code className="text-red">*</code>
                </label>
                <InputNumber
                  id="inputnumber"
                  placeholder=""
                  className="border-round-md "
                  name="mobile"
                  inputClassName="border-round-md"
                  useGrouping={false}
                  maxLength={10}
                  value={data.mobile}
                  onValueChange={(e) => {
                    setError({
                      ...error,
                      mobile: null,
                    });
                    setData({ ...data, mobile: e.value });
                  }}
                />
                {error.mobile && <span className="error">{error.mobile}</span>}
              </div>
              <div className="col-12 md:col-4 ">
                <label className="font block mb-2">
                  DOB<code className="text-red">*</code>
                </label>
                <Calendar
                  className="border-round-md calender_picker "
                  inputClassName="calendar p-2 border-round-md "
                  name="dob"
                  placeholder=""
                  value={data.dob}
                  onChange={(e) => {
                    setError({
                      ...error,
                      dob: null,
                    });
                    setData({ ...data, dob: e.value });
                  }}
                />
                {error.dob && <span className="error">{error.dob}</span>}
              </div>
              <div className="col-12 md:col-4 ">
                <label className="font block mb-2">
                  Address 1<code className="text-red">*</code>
                </label>
                <InputText
                  id="inputtext"
                  placeholder=""
                  name="address_1"
                  className="border-round-md "
                  value={data.address_1}
                  onChange={(e) => handleChange(e)}
                />
                {error.address_1 && (
                  <span className="error">{error.address_1}</span>
                )}
              </div>
              <div className=" col-12 md:col-4">
                <label className="font block mb-2"> Address 2 </label>
                <InputText
                  id="inputtext"
                  placeholder=""
                  className="border-round-md "
                  name="address_2"
                  value={data.address_2}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="col-12 md:col-4 ">
                <label className="font block mb-2">
                  City<code className="text-red">*</code>
                </label>
                <InputText
                  id="inputtext"
                  placeholder=""
                  className="border-round-md "
                  name="city"
                  value={data.city}
                  onChange={(e) => handleChange(e)}
                />
                {error.city && <span className="error">{error.city}</span>}
              </div>
              <div className="col-12 md:col-4 ">
                <label className="font block mb-2">
                  State<code className="text-red">*</code>
                </label>
                <InputText
                  id="inputtext"
                  placeholder=""
                  className="border-round-md "
                  name="state"
                  value={data.state}
                  onChange={(e) => handleChange(e)}
                />
                {error.state && <span className="error">{error.state}</span>}
              </div>
              <div className="col-12 md:col-6 ">
                <label className="font block mb-2">
                  Zip Code<code className="text-red">*</code>
                </label>
                <InputNumber
                  id="inputnumber"
                  placeholder=""
                  className="border-round-md "
                  inputClassName="border-round-md"
                  useGrouping={false}
                  name="zipcode"
                  value={data.zipcode}
                  maxLength={5}
                  onValueChange={(e) => {
                    setError({
                      ...error,
                      zipcode: null,
                    });
                    setData({ ...data, zipcode: e.value });
                  }}
                />
                {error.zipcode && (
                  <span className="error">{error.zipcode}</span>
                )}
              </div>
              <div className="col-12 md:col-6 ">
                <label className="font block mb-2">
                  Member Since<code className="text-red">*</code>
                </label>
                <Calendar
                  className="border-round-md calender_picker "
                  inputClassName="calendar p-2 border-round-md "
                  placeholder=""
                  name="member_since"
                  value={data.member_since}
                  onChange={(e) => {
                    setError({
                      ...error,
                      member_since: null,
                    });
                    setData({ ...data, member_since: e.value });
                  }}
                />
                {error.member_since && (
                  <span className="error">{error.member_since}</span>
                )}
              </div>
              <div className="col-12 md:col-6 ">
                <label className="font block mb-2">
                  Commission<code className="text-red">*</code>
                </label>
                <InputNumber
                  id="inputnumber"
                  placeholder=""
                  className="border-round-md "
                  inputClassName="border-round-md"
                  useGrouping={false}
                  name="commission"
                  value={data.commission}
                  maxLength={4}
                  onValueChange={(e) => {
                    setError({
                      ...error,
                      commission: null,
                    });
                    setData({ ...data, commission: e.value });
                  }}
                />
                {error.commission && <span className="error">{error.commission}</span>}
              </div>
              <div className="col-12 md:col-6">
                <label className="font block mb-2">
                  Discount<code className="text-red">*</code>
                </label>
                <Dropdown
                  className="border-round-md md:col-5"
                  placeholder=""
                  options={discount}
                  optionLabel="discount_code"
                  value={selectedDiscount} onChange={(e) => handleDiscount(e)}
                />
                {error.discount && (
                  <span className="error">{error.discount}</span>
                )}
              </div>
            </div>
            <div className="p-fluid grid mb-4 p-0 m-0">
              <label className="font block mb-2">Upload Files</label>
              <div className="col-12 md:col-12 text-center border-1 border-400 border-round-md border-dashed ">
                <div className="upload_image p-5">
                  <label
                    htmlFor="profile-img"
                    className="bg-primary cursor-pointer w-3 px-5 border-0 p-2 border-round-md"
                  >
                    <i className="pi pi-plus mr-3"></i>
                    <span>Choose a File</span>
                  </label>
                  <input
                    type="file"
                    mode="basic"
                    hidden
                    className="file-upload text-white border-round-lg"
                    id="profile-img"
                    chooseLabel="Choose a file"
                    name="profile-img"
                    accept="pdf/*"
                    maxFileSize={1000000}
                    onChange={(e) => {
                      setError({
                        ...error,
                        files: null,
                      });
                      setData({ ...data, files: e.target.files });
                    }}
                    multiple
                  />
                  <p>Drag your Photo here or Browser</p>
                </div>
              </div>
              {error.files && <span className="error">{error.files}</span>}
            </div>
            <div className="grid  mb-5">
              <div className="md:col-12">
                <Table tableHeading={tableHeading} tableData={tableData} handleDeletePdf={handleDeletePdf} />
              </div>
            </div>
            <div className=" btn flex">
              <div className="text-center">
                <Button
                  label="Save"
                  className="w-full p-3 px-5  border-0 border-round-md bg-main"
                  onClick={handleSubmit}
                />
              </div>
              <div className="text-center">
                <Button
                  label="Cancel"
                  className="w-full surface-500 p-3  ml-5 border-0 border-round-md "
                  onClick={cancelButton}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </ContentArea>
  );
}
