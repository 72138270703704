import React, { useState, useRef, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import ContentArea from "../../../shared/ContentArea";
import Table from "../../../components/TableList/Table";
import FileIcon from "../../../assets/icons/fileicon.png";
import CloseIcon from "../../../assets/icons/close.png";
import { Card } from "primereact/card";
import { useParams } from "react-router-dom";
import { viewSalesPerson, getCarrier, ViewDiscount} from "../../../utils/action";
import { Loading } from "../../../components/Loader/Loading";
import { Dropdown } from "primereact/dropdown";
export default function ViewsalesPerson(props) {
  const toast = useRef(null);
  const [data, setData] = useState();
  const [isloading, setIsLoading] = useState(false);
  const [carrier, setCarrier] = useState("")
  const { id } = useParams();
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [discount, setDiscount] = useState()
  const [tableData, setTableData] = useState();
  const [IsId, setId] = useState({
    sales_person_id: "",
  });
  useState(() => {
    setId({
      sales_person_id: id,
    });
  });
  useEffect(() => {
    async function fetchMyAPI() {
      let response = await getCarrier();
      setCarrier(response.data)
    }
    fetchMyAPI();
  }, [])
  useEffect(() => {
    async function fetchMyAPI() {
      let response = await ViewDiscount();
      setDiscount(response.data)
    }
    fetchMyAPI();
  }, [])


  const onUpload = () => {
    toast.current.show({
      severity: "info",
      summary: "Success",
      detail: "File Uploaded",
    });
  };
  const tableHeading = [
    { field: "fileicon", header: "", body: "fileIconBodyTemplate" },
    { field: "file_name", header: "File Name" },
    { field: "file_size", header: "File size" },
    { field: "created_at", header: "Date modified" },
  ];
  const SubscriptionPlanTableHeading = [
    { field: "Date", header: "Date" },
    { field: "Clients", header: "Clients" },
    { field: "NoofClients", header: "No. of Clients" },
    { field: "TotalRevenue", header: "Total Revenue" },
    { field: "Payout", header: "Payout" },

    { field: "Total", header: "Total" },
  ];

  const SubscriptionPlanTableData = [
    {
      Date: "Mar-02-23",
      Clients: "Austin",
      NoofClients: "20",
      TotalRevenue: "$250",
      Payout: "$50",
      Total: "$300",
    },

    {
      Date: "Mar-02-23",
      Clients: "Austin",
      NoofClients: "15",
      TotalRevenue: "$250",
      Payout: "$50",
      Total: "$300",
    },

    {
      Date: "Mar-02-23",
      Clients: "Austin",
      NoofClients: "34",
      TotalRevenue: "$250",
      Payout: "$50",
      Total: "$300",
    },

    {
      Date: "Mar-02-23",
      Clients: "Austin",
      NoofClients: "04",
      TotalRevenue: "$250",
      Payout: "$50",
      Total: "$300",
    },

    // {
    //   Date: "Grand Total",
    //   Total: "$1200",
    // },
  ];
  const handleSalesPerson = async () => {
    setIsLoading(true);
    try {
      let response = await viewSalesPerson({ payload: IsId });
      setIsLoading(false);
      response.data[0].dob = new Date(response.data[0].dob.slice(0, 10));
      response.data[0].member_since = new Date(
        response.data[0].member_since.slice(0, 10)
      );
     
      let payload 
      let array =[]
      response.data[0].pdf_sales_person = response.data[0].pdf_sales_person?.map((item)=>{
        let date = new Date(item.created_at)
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();
      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
      let dob =   dt + '/' +month +'/'+year
       payload ={
          id:item.id,file_name:item.file_name,
          file_size:item.file_size,
          is_deleted:item.is_deleted,
          user_id:item.user_id,
          created_at:dob,
          updated_at:item.updated_at
        }
        array.push(payload)
      })
   
      setTableData(array)
      setData(response.data[0]);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    handleSalesPerson();
  }, []);
  const handleDiscount = () => { 
    discount?.length>0&& discount?.map((item) => {
       if (item?.id == data?.discount_id) {
         setSelectedDiscount(item)
       }
     })
   }
   console.log(selectedDiscount,"====selectedDiscount-----")
   useEffect(()=>{
     handleDiscount()
   },[discount,data])
  useEffect(() => {
    carrier?.length > 0 && carrier.map((item) => {
      if (item.id == data?.carrier) {
        setSelectedCarrier(item)
      }
    })
  }, [carrier,data])

  const handleCarrier = (e) => {
    setSelectedCarrier(e.value)
  }
  
  return (
    <ContentArea>
      {isloading ? (
        <Loading />
      ) : (
        <div className="p-3">
          <div className="grid card ml-0 mr-0 px-3 mb-5 align-items-center bg-main-light border-round-lg">
            <h2 className="text-main">
              {props.title ? props.title : " Sales Person"}
            </h2>
          </div>
          <div className="shadow-2 p-5 border-round-lg">
            <div className="p-fluid grid mb-4">
              <div className="col-12 md:col-4">
                <label className="font block mb-2">
                  Salesperson Id<code className="text-red">*</code>
                </label>
                <InputText
                  placeholder=""
                  useGrouping={false}
                  inputClassName="border-round-md disable-input"
                  value={data?.profile_id}
                />
              </div>
              <div className="col-12 md:col-4 ">
                <label className="font block mb-2">
                  First Name<code className="text-red">*</code>
                </label>
                <InputText
                  id="inputtext"
                  placeholder=""
                  className="border-round-md disable-input "
                  value={data?.first_name}
                />
              </div>
              <div className=" col-12 md:col-4">
                <label className="font block mb-2"> Middle Name </label>
                <InputText
                  id="inputtext"
                  placeholder=""
                  className="border-round-md disable-input"
                  value={data?.middle_name}
                />
              </div>
              <div className="col-12 md:col-4 ">
                <label className="font block mb-2">
                  Last Name<code className="text-red">*</code>
                </label>
                <InputText
                  id="inputtext"
                  placeholder=""
                  className="border-round-md disable-input"
                  value={data?.last_name}
                />
              </div>
              <div className="col-12 md:col-4 ">
                <label className="font block mb-2">
                  Email<code className="text-red">*</code>
                </label>
                <InputText
                  id="inputtext"
                  placeholder=""
                  className="border-round-md disable-input"
                  value={data?.email}
                />
              </div>
              <div className="col-12 md:col-4 ">
                <label className="font block mb-2">
                  Carrier<code className="text-red">*</code>
                </label>
                <Dropdown className="border-round-md disable-input" name="carrier" options={carrier} optionLabel="carrier_name" value={selectedCarrier} onChange={(e) => handleCarrier(e)} />
              </div>
              <div className=" col-12 md:col-4">
                <label className="font block mb-2">
                  Phone No.<code className="text-red">*</code>
                </label>
                <InputNumber
                  id="inputnumber"
                  placeholder=""
                  className="border-round-md disable-input"
                  inputClassName="border-round-md disable-input"
                  useGrouping={false}
                  value={data?.mobile}
                />
              </div>
              <div className="col-12 md:col-4 ">
                <label className="font block mb-2">
                  DOB<code className="text-red">*</code>
                </label>
                <Calendar
                  className="border-round-md calender_picker disable-input"
                  inputClassName="calendar p-2 border-round-md"
                  placeholder=""
                  value={data?.dob}
                />
              </div>
              <div className="col-12 md:col-4 ">
                <label className="font block mb-2">
                  Address 1<code className="text-red">*</code>
                </label>
                <InputText
                  id="inputtext"
                  placeholder=""
                  className="border-round-md disable-input"
                  value={data?.address_1}
                />
              </div>

              <div className=" col-12 md:col-4">
                <label className="font block mb-2"> Address 2 </label>
                <InputText
                  id="inputtext"
                  placeholder=""
                  className="border-round-md disable-input"
                  value={data?.address_2}
                />
              </div>
              <div className="col-12 md:col-4 ">
                <label className="font block mb-2">
                  City<code className="text-red">*</code>
                </label>
                <InputText
                  id="inputtext"
                  placeholder=""
                  className="border-round-md disable-input"
                  value={data?.city}
                />
              </div>
              <div className="col-12 md:col-4 ">
                <label className="font block mb-2">
                  State<code className="text-red">*</code>
                </label>
                <InputText
                  id="inputtext"
                  placeholder=""
                  className="border-round-md disable-input"
                  value={data?.state}
                />
              </div>

              <div className="col-12 md:col-6 ">
                <label className="font block mb-2">
                  Zip Code<code className="text-red">*</code>
                </label>
                <InputNumber
                  id="inputnumber"
                  placeholder=""
                  useGrouping={false}
                  className="border-round-md disable-input"
                  inputClassName="border-round-md"
                  value={data?.zipcode}
                />
              </div>
              <div className="col-12 md:col-6 ">
                <label className="font block mb-2">
                  Member Since<code className="text-red">*</code>
                </label>
                <Calendar
                  className="border-round-md calender_picker disable-input"
                  inputClassName="calendar p-2 border-round-md "
                  placeholder=""
                  value={data?.member_since}
                />
              </div>
              <div className="col-12 md:col-6 ">
                <label className="font block mb-2">
                  Commission<code className="text-red">*</code>
                </label>
                <InputText
                  className="border-round-md calender_picker disable-input"
                  inputClassName="calendar p-2 border-round-md "
                  placeholder="%"
                  name="member_since"
                  value={data?.commission}
                />
              </div>
              <div className="col-12 md:col-6 ">
                <label className="font block mb-2">
                  Discount<code className="text-red">*</code>
                </label>
                <Dropdown
                  className="border-round-md md:col-3 disable-input"
                  placeholder="Apply Discount"
                  options={discount}
                  optionLabel="discount_code"
                  value={selectedDiscount} onChange={(e) => handleDiscount(e)}
                />
              </div>
            </div>
            <div className="grid  mb-5">
              <div className="md:col-12">
                <Table tableHeading={tableHeading} tableData={tableData}/>
              </div>
            </div>
            <Card className="shadow-2 text-main border-round-lg mb-5">
              <h2 className="text-main mb-3 p-0 m-0 Karla ">
                Commission Report
              </h2>
              <div className="grid program_table">
                <div className="md:col-12">
                  <Table
                    tableHeading={SubscriptionPlanTableHeading}
                    tableData={SubscriptionPlanTableData}
                  />
                  <div className="grid justify-content-between mt-2">
                    <div className="md:col-2 ">
                      <small className="karla">Grand Total</small>
                    </div>
                    <div className="md:col-3 pl-8"></div>
                    <div className="md:col-2 pl-8">
                    </div>
                    <div className="md:col-2 pl-8">
                      <small>$950</small>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      )}
    </ContentArea>
  );
}
