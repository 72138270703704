import React, { useState, useEffect } from "react";
import ContentArea from "../../../shared/ContentArea";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import Table from "../../../components/TableList/Table";
import { Card } from "primereact/card";
import {
  editClient,
  viewClient,
  getSubscription,
  ViewDiscount,
  getCarrier,
} from "../../../utils/action";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  validatePhoneNumber,
  validateZipcodeNumber,
  validEmail,
} from "../../../shared/Validation";
import { Dropdown } from "primereact/dropdown";
import { Loading } from "../../../components/Loader/Loading";
export default function EditClient() {
  const navigate = useNavigate();
  const [data, setData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    mobile: "",
    dob: "",
    social_security_no: "",
    address_1: "",
    address_2: "",
    state: "",
    city: "",
    zipcode: "",
    carrier: "",
    company_name: "",
    company_mobile: "",
    company_tin_eim: "",
    company_email: "",
    company_alt_mobile: "",
    company_address_1: "",
    company_address_2: "",
    company_city: "",
    company_state: "",
    company_zipcode: "",
    is_active: "",
    program_details: [],
    discount: "",
    company_carrier_1: "",
    company_carrier_2: "",
    clientId: "",
  });
  const [error, setError] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    mobile: "",
    dob: "",
    social_security_no: "",
    address_1: "",
    address_2: "",
    state: "",
    city: "",
    zipcode: "",
    company_name: "",
    company_mobile: "",
    company_tin_eim: "",
    company_email: "",
    company_alt_mobile: "",
    company_address_1: "",
    company_address_2: "",
    company_city: "",
    company_state: "",
    company_zipcode: "",
    company_carrier_1: "",
    company_carrier_2: "",
  });
  const [carrier, setCarrier] = useState("");
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [selectedCompanyCarrier, setSelectedCompanyCarrier] = useState(null);
  const [selectedCompanyCarrier2, setSelectedCompanyCarrier2] = useState(null);
  const [programData, setProgramData] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [discount, setDiscount] = useState();
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [totalClient, setTotalClient] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [countPrice, setCountPrice] = useState(0);
  const [IsId, setId] = useState({
    client_id: "",
  });
  const ProgramHeading = [
    { selectionMode: "multiple" },
    { field: "program_name", header: "Program Name" },
    { field: "price_per_person", header: "Price Per Client" },
    { field: "NoofClients", header: "No. of Clients", body: "tableAddButton" },
    { field: "Total", header: "Total", body: "totalTemp" },
  ];
  const cancelButton = () => {
    navigate("/manage-client");
  };
  const { id } = useParams();
  useState(() => {
    setId({
      client_id: id,
    });
  });
  const getClientData = async () => {
    setIsLoading(true);
    async function fetchMyAPI() {
      let response = await viewClient({ payload: IsId });
      if (response.data[0].is_active) {
        setChecked(true);
      }
      response.data[0].middle_name =
        response.data[0].middle_name === null
          ? ""
          : response.data[0].middle_name;
      response.data[0].dob = new Date(response.data[0].dob.slice(0, 10));

      let preSelectedPrograms = response.data[0].subscription_details.map(
        (data) => {
          let selected = {
            subscription_plans_id: data.subscription_plans_id,
            program_name: data.subscription_plan?.program_name,
            price_per_person: data.subscription_plan?.price_per_person,
            number_of_client: data?.number_of_client,
            total: data.total,
          };

          return selected;
        }
      );
      setSelectedProducts(preSelectedPrograms);
      setIsLoading(false);
      setData(response.data[0]);
    }
    fetchMyAPI();
  };
  const getCarrierData = async () => {
    async function fetchMyAPI() {
      const response = await getCarrier();
      setCarrier(response.data);
    }
    fetchMyAPI();
  };
  const getDiscountData = async () => {
    async function fetchMyAPI() {
      const response = await ViewDiscount();
      setDiscount(response.data);
    }
    fetchMyAPI();
  };
  const getTable = async (selected) => {
    async function fetchMyAPI() {
      let response = await getSubscription();
      let program = response.data.map((item, index) => {
        const payload = {
          subscription_plans_id: item.id,
          program_name: item.program_name,
          price_per_person: item.price_per_person,
          number_of_client: 0,
          total: 0,
        };
        selected?.forEach((data) => {
          if (data.subscription_plans_id === item.id) {
            payload.number_of_client = data.number_of_client;
            payload.total = data.total;
          }
        });
        return payload;
      });
      setProgramData(program);
    }
    fetchMyAPI();
  };

  useEffect(() => {
    carrier.length > 0 &&
      carrier.map((item) => {
        if (item.id == data?.carrier) {
          setSelectedCarrier(item);
        }
        if (item.id == data.company_carrier_1) {
          setSelectedCompanyCarrier(item);
        }
        if (item.id == data.company_carrier_2) {
          setSelectedCompanyCarrier2(item);
        }
      });
  }, [carrier]);
  useEffect(() => {
    discount?.length > 0 &&
      discount?.map((item) => {
        data.subscription_details?.length > 0 &&
          data.subscription_details?.map((managaeDiscount) => {
            if (item.id == managaeDiscount.manage_discounts_id) {
              setSelectedDiscount(item);
            }
          });
      });
  }, [discount]);
  useEffect(() => {
    getClientData();
    getCarrierData();
    getDiscountData();
  }, []);

  useEffect(() => {
    async function fetchMyAPI() {
      getTable(selectedProducts);
    }
    fetchMyAPI();
  }, [selectedProducts]);

  const handelChange = (e) => {
    setData({
      ...data,
      [e?.target?.name]: e?.target?.value,
    });
    setError({
      ...error,
      [e?.target?.name]: e?.value,
    });
  };
  const handleCarrier = (e) => {
    setSelectedCarrier(e.value);
    setData({
      ...data,
      carrier: e.value.id,
    });
  };
  const handleCompanyCarrier = (e) => {
    setSelectedCompanyCarrier(e.value);
    setData({
      ...data,
      company_carrier_1: e.value.id,
    });
  };
  const handleCompanyCarrier2 = (e) => {
    setSelectedCompanyCarrier2(e.value);
    setData({
      ...data,
      company_carrier_2: e.value.id,
    });
  };
  const handleDiscount = (e) => {
    setSelectedDiscount(e.value);
    setData({
      ...data,
      discount: e.value.id,
    });
  };
  useEffect(() => {
    let client = 0;
    let total = 0;
    programData?.map((item) => {
      client += item.number_of_client;
      total += item.total;
    });
    setTotalClient(client);
    setTotalPrice(total);
    let final = (totalPrice * selectedDiscount?.discount_rate_percent) / 100;
    let totalCount = totalPrice - final;
    setCountPrice(totalCount);
    setFinalPrice(final);
  }, [programData, selectedDiscount, totalClient]);
  useEffect(() => {
    setData((prev) => {
      return {
        ...prev,
        program_details: selectedProducts,
      };
    });
    setError((prev) => {
      return {
        ...prev,
        program_details: "",
      };
    });
  }, [selectedProducts]);
  let date = new Date(data.dob);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();
  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }
  let dob = year + "-" + month + "-" + dt;
  const handleSubmit = async (e) => {
    setIsLoading(true);
    try {
      setIsLoading(false);
      e.preventDefault();
      if (
        data.first_name === "" ||
        data.last_name === "" ||
        data.email === "" ||
        data.mobile === null ||
        data.address_1 === "" ||
        data.dob === null ||
        data.city === "" ||
        data.state === "" ||
        data.zipcode === null ||
        data.company_name === "" ||
        data.company_address_1 === "" ||
        data.company_email === "" ||
        data.company_tin_eim === null ||
        data.company_mobile === null ||
        data.company_alt_mobile === null ||
        data.company_state === "" ||
        data.company_city === "" ||
        data.company_zipcode === null ||
        !validatePhoneNumber(data.mobile) ||
        !validatePhoneNumber(data.company_alt_mobile) ||
        !validatePhoneNumber(data.company_mobile) ||
        !validateZipcodeNumber(data.zipcode) ||
        !validateZipcodeNumber(data.company_zipcode) ||
        data.carrier === "" ||
        data.company_carrier_1 === "" ||
        data.company_carrier_2 === null ||
        data.program_details?.length === 0 ||
        !validEmail(data.email)
      ) {
        setError({
          ...error,
          clientId:
            data.clientId === "" ? "Please Enter client Id" : error.clientId,
          carrier: data.carrier === "" ? "Please enter carrier" : error.carrier,
          company_carrier1:
            data.company_carrier_1 === ""
              ? "Please Enter Company_carrier"
              : error.company_carrier_1,
          company_carrier2:
            data.company_carrier_2 === ""
              ? "Please Enter company_carrier"
              : error.company_carrier_2,
          first_name:
            data.first_name === ""
              ? "Please Enter FirstName"
              : error.first_name,
          last_name:
            data.last_name === "" ? "Please Enter LastName" : error?.last_name,
          email:
            data.email === ""
              ? "Please Enter Email"
              : !validEmail(data.email)
              ? "Please Enter Valid Email"
              : error?.email,
          mobile:
            data.mobile === null
              ? "Please Enter Mobile"
              : !validatePhoneNumber(data.mobile)
              ? "Please Enter Valid Mobile Number"
              : error.mobile,
          address_1:
            data.address_1 === "" ? "Please Enter Address" : error.address_1,
          dob: data.dob === null ? "Please Enter DOb" : error.dob,
          city: data.city === "" ? "Please Enter City" : error.city,
          state: data.state === "" ? "Please Enter State" : error.state,
          zipcode:
            data.zipcode === null
              ? "Please Enter ZipCode"
              : !validateZipcodeNumber(data.zipcode)
              ? "Please Enter Valid ZipCode"
              : error.zipcode,
          company_name:
            data.company_name === ""
              ? "Please Enter Company Name"
              : error.company_name,
          company_address_1:
            data.company_address_1 === ""
              ? "Please Enter Company Address"
              : error.company_address_1,
          company_tin_eim:
            data.company_tin_eim === null
              ? "Please Enter Company TIN"
              : error.company_tin_eim,
          company_mobile:
            data.company_mobile === null
              ? "Please Enter Comapny Mobile"
              : !validatePhoneNumber(data.company_mobile)
              ? "Please Enter Valid Mobile Number"
              : error.company_mobile,
          company_alt_mobile:
            data.company_alt_mobile === null
              ? "Please Enter Alternate Mobile Number"
              : !validatePhoneNumber(data.company_alt_mobile)
              ? "Please Enter Valid Mobile Number"
              : error.company_alt_mobile,
          company_state:
            data.company_state === ""
              ? "Please Enter Company State"
              : error.company_state,
          company_city:
            data.company_city === ""
              ? "Please Enter Company City"
              : error.company_city,
          company_email:
            data.company_email === ""
              ? "Please Enter Comapny Email"
              : error.company_email,
          company_carrier_2:
            data.company_carrier_2 === null
              ? "Please Enter Company_carrier"
              : error.company_carrier_2,
          company_zipcode:
            data.company_zipcode === null
              ? "Please Enter Company Zipcode"
              : !validateZipcodeNumber(data.company_zipcode)
              ? "Please Enter Company ZipCode"
              : error.zipcode,
          program_details:
            data.program_details?.length === 0
              ? "Select Program"
              : error.program_details,
        });
        setIsLoading(false);
        return false;
      }
      const formData = new FormData();
      formData.append("first_name", data.first_name);
      formData.append("middle_name", data.middle_name);
      formData.append("last_name", data.last_name);
      formData.append("email", data.email);
      formData.append("client_carrier", data.carrier);
      formData.append("mobile", data.mobile);
      formData.append("dob", dob);
      formData.append("social_security_no", data.social_security_no);
      formData.append("address_1", data.address_1);
      formData.append("address_2", data.address_2);
      formData.append("city", data.city);
      formData.append("state", data.state);
      formData.append("zipcode", data.zipcode);
      formData.append("company_tin_eim", data.company_tin_eim);
      formData.append("company_name", data.company_name);
      formData.append("company_email", data.company_email);
      formData.append("company_mobile", data.company_mobile);
      formData.append("company_alt_mobile", data.company_alt_mobile);
      formData.append("company_address_1", data.company_address_1);
      formData.append("company_address_2", data.company_address_2);
      formData.append("company_city", data.company_city);
      formData.append("company_state", data.company_state);
      formData.append("company_zipcode", data.company_zipcode);
      formData.append("program_details", JSON.stringify(data.program_details));
      formData.append(
        "manage_discounts_id",
        data.discount || data.subscription_details[0].manage_discounts_id
      );
      formData.append("company_carrier_1", data.company_carrier_1);
      formData.append("company_carrier_2", data.company_carrier_2);
      formData.append("is_active", data.is_active);
      formData.append("clientId", data.id);
      async function fetchMyAPI() {
        let response = await editClient({ payload: formData, id: id });
        if (response.status === false) {
          setIsLoading(false);
          toast.error(response.message || "Something went Wrong");
        }
        if (response.status === true) {
          setIsLoading(false);
          toast.success(response?.message || "Client Created Successfully");
          setData({
            first_name: "",
            middle_name: "",
            last_name: "",
            email: "",
            mobile: "",
            dob: "",
            social_security_no: "",
            address_1: "",
            address_2: "",
            state: "",
            city: "",
            zipcode: "",
            company_name: "",
            company_mobile: "",
            company_tin_eim: "",
            company_email: "",
            company_alt_mobile: "",
            company_address_1: "",
            company_address_2: "",
            company_city: "",
            company_state: "",
            company_zipcode: "",
          });
          navigate("/manage-client");
          return true;
        }
      }
      fetchMyAPI();
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err.message ? err.message : "Something Went Wrong during login";
      toast.error(err?.response?.data?.message);
      return false;
    }
  };
  const handleToogle = (e) => {
    setChecked(e.value);
    setData({
      ...data,
      is_active: e.value ? 1 : 0,
    });
  };
  function handleGrandTotal() {
    return (
      <>
        <div className="grid justify-content-between mt-2">
          <div className="md:col-2 pl-8">
            <small className="karla">Grand Total</small>
          </div>
          <div className="md:col-3 pl-8"></div>
          <div className="md:col-2 pl-8">
            <small className="karla">{totalClient}</small>
          </div>
          <div className="md:col-2 pl-7">
            <small>${totalPrice}</small>
          </div>
        </div>
        <div className="grid justify-content-between mt-2 bg-main-light">
          <div className="md:col-2 pl-8">
            <small className="karla">Discount</small>
          </div>
          <div className="md:col-3 pl-8"></div>
          <div className="md:col-2 pl-8">
            <small className="karla"></small>
          </div>
          <div className="md:col-2 pl-7">
            <small>${finalPrice || 0}</small>
          </div>
        </div>
        <div className="grid justify-content-between mt-2">
          <div className="md:col-2 pl-8">
            <small className="karla">Final Total</small>
          </div>
          <div className="md:col-3 pl-8"></div>
          <div className="md:col-2 pl-8">
            <small className="karla"></small>
          </div>
          <div className="md:col-2 pl-7">
            <small>${countPrice || 0}</small>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <ContentArea>
        {isLoading ? (
          <Loading />
        ) : (
          <div className="p-3">
            <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
              <h2 className="text-main">Edit Client</h2>
            </div>
            <div className="shadow-2 p-5 border-round-lg">
              <h2 className="text-main mb-5 p-0 m-0 Karla flex align-items-center justify-content-between">
                Client Details
                <span className="flex text-lg text-700">
                  Active &nbsp;&nbsp;{" "}
                  <InputSwitch
                    checked={checked}
                    onChange={(e) => handleToogle(e)}
                    name="is_active"
                  />
                </span>
              </h2>
              <div className="p-fluid grid mb-3">
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Client Id<code className="text-red">*</code>
                  </label>
                  <InputText
                    id=""
                    className="border-round-md disable-input "
                    name="clientId"
                    value={data?.profile_id}
                    // onChange={(e) => handelChange(e)}
                  />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    First Name<code className="text-red">*</code>
                  </label>
                  <InputText
                    id="inputtext"
                    placeholder=""
                    className="border-round-md "
                    name="first_name"
                    value={data?.first_name}
                    onChange={(e) => handelChange(e)}
                  />
                  {error.first_name && (
                    <span className="error">{error.first_name}</span>
                  )}
                  <br />
                </div>
                <div className=" col-12 md:col-4">
                  <label className="font block mb-2"> Middle Name </label>
                  <InputText
                    id="inputtext"
                    placeholder=""
                    className="border-round-md "
                    name="middle_name"
                    value={data?.middle_name}
                    onChange={(e) => handelChange(e)}
                  />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Last Name<code className="text-red">*</code>
                  </label>
                  <InputText
                    id="inputtext"
                    placeholder=""
                    className="border-round-md "
                    name="last_name"
                    value={data?.last_name}
                    onChange={(e) => handelChange(e)}
                  />
                  {error.last_name && (
                    <span className="error">{error.last_name}</span>
                  )}
                  <br />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Email<code className="text-red">*</code>
                  </label>
                  <InputText
                    id="inputtext"
                    placeholder=""
                    className="border-round-md "
                    name="email"
                    value={data?.email}
                    onChange={(e) => handelChange(e)}
                    disabled
                  />
                  {error.email && <span className="error">{error.email}</span>}
                  <br />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Carrier*<code className="text-red">*</code>
                  </label>
                  <Dropdown
                    className="border-round-md "
                    name="carrier"
                    options={carrier}
                    optionLabel="carrier_name"
                    value={selectedCarrier}
                    onChange={(e) => handleCarrier(e)}
                  />
                </div>
                <div className=" col-12 md:col-4">
                  <label className="font block mb-2">
                    Phone No.<code className="text-red">*</code>
                  </label>
                  <InputNumber
                    id="inputnumber"
                    placeholder=""
                    className="border-round-md "
                    inputClassName="border-round-md"
                    value={data?.mobile}
                    useGrouping={false}
                    maxLength={10}
                    name="mobile"
                    onValueChange={(e) => {
                      setError({
                        ...error,
                        mobile: null,
                      });
                      setData({ ...data, mobile: e.value });
                    }}
                  />
                  {error.mobile && (
                    <span className="error">{error.mobile}</span>
                  )}
                  <br />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    DOB<code className="text-red">*</code>
                  </label>
                  <Calendar
                    className="border-round-md calender_picker "
                    inputClassName="calendar p-2 border-round-md "
                    placeholder=""
                    value={data?.dob}
                    name="dob"
                    onChange={(e) => {
                      setError({
                        ...error,
                        dob: null,
                      });
                      setData({ ...data, dob: e.value });
                    }}
                  />
                </div>
                <div className=" col-12 md:col-4">
                  <label className="font block mb-2">
                    Social Security No.<code className="text-red"></code>
                  </label>
                  <InputText
                    id="inputtext"
                    placeholder=""
                    className="border-round-md "
                    name="social_security_no"
                    value={data?.social_security_no}
                    onChange={(e) => handelChange(e)}
                  />
                  <br />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Address 1<code className="text-red">*</code>
                  </label>
                  <InputText
                    id="inputtext"
                    placeholder=""
                    className="border-round-md "
                    name="address_1"
                    value={data?.address_1}
                    onChange={(e) => handelChange(e)}
                  />
                  {error.address_1 && (
                    <span className="error">{error.address_1}</span>
                  )}
                  <br />
                </div>
                <div className=" col-12 md:col-4">
                  <label className="font block mb-2"> Address 2 </label>
                  <InputText
                    id="inputtext"
                    placeholder=""
                    className="border-round-md "
                    name="address_2"
                    value={data?.address_2}
                    onChange={(e) => handelChange(e)}
                  />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    City<code className="text-red">*</code>
                  </label>
                  <InputText
                    id="inputtext"
                    placeholder=""
                    className="border-round-md "
                    name="city"
                    onChange={(e) => handelChange(e)}
                    value={data?.city}
                  />
                  {error.city && <span className="error">{error.city}</span>}
                  <br />
                </div>
                <div className="col-12 md:col-6 ">
                  <label className="font block mb-2">
                    State<code className="text-red">*</code>
                  </label>
                  <InputText
                    id="inputtext"
                    placeholder=""
                    className="border-round-md "
                    onChange={(e) => handelChange(e)}
                    name="state"
                    value={data?.state}
                  />
                  {error.state && <span className="error">{error.state}</span>}
                  <br />
                </div>
                <div className="col-12 md:col-6 ">
                  <label className="font block mb-2">
                    Zip Code<code className="text-red">*</code>
                  </label>
                  <InputNumber
                    id="inputnumber"
                    placeholder=""
                    className="border-round-md "
                    inputClassName="border-round-md"
                    value={data?.zipcode}
                    useGrouping={false}
                    maxLength={5}
                    name="zipcode"
                    onValueChange={(e) => {
                      setError({
                        ...error,
                        zipcode: null,
                      });
                      setData({ ...data, zipcode: e.value });
                    }}
                  />
                  {error.zipcode && (
                    <span className="error">{error.zipcode}</span>
                  )}
                  <br />
                </div>
              </div>
              <h2 className="text-main mb-5 p-0 m-0 Karla ">Company Details</h2>
              <div className="p-fluid grid mb-3">
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Company Name<code className="text-red">*</code>
                  </label>
                  <InputText
                    id="inputtext"
                    placeholder=""
                    className="border-round-md "
                    name="company_name"
                    onChange={(e) => handelChange(e)}
                    value={data?.company_name}
                  />
                  {error.company_name && (
                    <span className="error">{error.company_name}</span>
                  )}
                  <br />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Company TIN/EIN<code className="text-red">*</code>
                  </label>
                  <InputNumber
                    id="inputtext"
                    placeholder=""
                    className="border-round-md "
                    useGrouping={false}
                    name="company_tin_eim"
                    onValueChange={(e) => {
                      setError({
                        ...error,
                        company_tin_eim: null,
                      });
                      setData({ ...data, company_tin_eim: e.value });
                    }}
                    value={data?.company_tin_eim}
                  />
                  {error.company_tin_eim && (
                    <span className="error">{error.company_tin_eim}</span>
                  )}
                  <br />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Company Email<code className="text-red">*</code>
                  </label>
                  <InputText
                    id="inputtext"
                    placeholder=""
                    className="border-round-md "
                    name="company_email"
                    onChange={(e) => handelChange(e)}
                    value={data?.company_email}
                  />
                  {error.company_email && (
                    <span className="error">{error.company_email}</span>
                  )}
                  <br />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Carrier*<code className="text-red">*</code>
                  </label>
                  <Dropdown
                    className="border-round-md "
                    name="carrier"
                    options={carrier}
                    optionLabel="carrier_name"
                    value={selectedCompanyCarrier}
                    onChange={(e) => handleCompanyCarrier(e)}
                  />
                </div>
                <div className=" col-12 md:col-4">
                  <label className="font block mb-2">
                    Company Contact no.<code className="text-red">*</code>
                  </label>
                  <InputNumber
                    id=""
                    inputClassName="border-round-md"
                    name="company_mobile"
                    value={data?.company_mobile}
                    maxLength={10}
                    useGrouping={false}
                    onValueChange={(e) => {
                      setError({
                        ...error,
                        company_mobile: null,
                      });
                      setData({ ...data, company_mobile: e.value });
                    }}
                  />
                  {error.company_mobile && (
                    <span className="error">{error.company_mobile}</span>
                  )}
                  <br />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Carrier*<code className="text-red">*</code>
                  </label>
                  <Dropdown
                    className="border-round-md "
                    name="carrier"
                    options={carrier}
                    optionLabel="carrier_name"
                    value={selectedCompanyCarrier2}
                    onChange={(e) => handleCompanyCarrier2(e)}
                  />
                  {error.company_carrier_2 && (
                    <span className="error">{error.company_carrier_2}</span>
                  )}
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Phone No.<code className="text-red">*</code>
                  </label>
                  <InputNumber
                    id=""
                    inputClassName="border-round-md"
                    name="company_alt_mobile"
                    value={data?.company_alt_mobile}
                    maxLength={10}
                    useGrouping={false}
                    onValueChange={(e) => {
                      setError({
                        ...error,
                        company_alt_mobile: null,
                      });
                      setData({ ...data, company_alt_mobile: e.value });
                    }}
                  />
                  {error.company_alt_mobile && (
                    <span className="error">{error.company_alt_mobile}</span>
                  )}
                  <br />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Address 1<code className="text-red">*</code>
                  </label>
                  <InputText
                    id="inputtext"
                    placeholder=""
                    className="border-round-md "
                    name="company_address_1"
                    value={data?.company_address_1}
                    onChange={(e) => handelChange(e)}
                  />
                  {error.company_address_2 && (
                    <span className="error">{error.company_address_1}</span>
                  )}
                  <br />
                </div>
                <div className=" col-12 md:col-4">
                  <label className="font block mb-2"> Address 2 </label>
                  <InputText
                    id="inputtext"
                    placeholder=""
                    className="border-round-md "
                    name="company_address_2"
                    value={data?.company_address_2}
                    onChange={(e) => handelChange(e)}
                  />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    City<code className="text-red">*</code>
                  </label>
                  <InputText
                    id="inputtext"
                    placeholder=""
                    className="border-round-md "
                    value={data?.company_city}
                    name="company_city"
                    onChange={(e) => handelChange(e)}
                  />
                  {error.company_city && (
                    <span className="error">{error.company_city}</span>
                  )}
                  <br />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    State<code className="text-red">*</code>
                  </label>
                  <InputText
                    id="inputtext"
                    placeholder=""
                    className="border-round-md "
                    value={data?.company_state}
                    name="company_state"
                    onChange={(e) => handelChange(e)}
                  />
                  {error.company_state && (
                    <span className="error">{error.company_state}</span>
                  )}
                  <br />
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">
                    Zip Code<code className="text-red">*</code>
                  </label>
                  <InputNumber
                    id="inputnumber"
                    placeholder=""
                    className="border-round-md "
                    inputClassName="border-round-md"
                    useGrouping={false}
                    value={data?.company_zipcode}
                    name="company_zipcode"
                    maxLength={5}
                    onValueChange={(e) => {
                      setError({
                        ...error,
                        company_zipcode: null,
                      });
                      setData({ ...data, company_zipcode: e.value });
                    }}
                  />
                  {error.company_zipcode && (
                    <span className="error">{error.company_zipcode}</span>
                  )}
                  <br />
                </div>
              </div>
              <Card className="shadow-2 text-main border-round-lg mb-5">
                <div className="grid justify-content-between align-items-center mb-3">
                  <h2 className="text-main mb-3 p-0 m-0 Karla md:col-3">
                    Program Details
                  </h2>
                  <Dropdown
                    className="border-round-md md:col-3"
                    placeholder="Apply Discount"
                    optionLabel="discount_code"
                    options={discount}
                    value={selectedDiscount}
                    onChange={(e) => handleDiscount(e)}
                  />
                </div>
                <div className="grid program_table">
                  <div className="md:col-12">
                    <Table
                      tableHeading={ProgramHeading}
                      tableData={programData}
                      setProgramData={setProgramData}
                      setSelectedProducts={setSelectedProducts}
                      selectedProducts={selectedProducts}
                      programData={programData}
                      handleGrandTotal={handleGrandTotal()}
                      title="editClient"
                    />
                  </div>
                  {error.program_details && (
                    <span className="error">{error.program_details}</span>
                  )}
                </div>
              </Card>
              <div className=" btn flex">
                <div className="text-center">
                  <Button
                    label="Save"
                    className="w-full p-3 px-5  border-0 border-round-md bg-main"
                    onClick={handleSubmit}
                  />
                </div>
                <div className="text-center">
                  <Button
                    label="Cancel"
                    className="w-full surface-500 p-3  ml-5 border-0 border-round-md "
                    onClick={cancelButton}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </ContentArea>
    </>
  );
}
