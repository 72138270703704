import React,{useEffect} from "react";
import Logo from "../../assets/icons/resmedx-logo-blue.png";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Route, Link } from "react-router-dom";
import { Button } from "primereact/button";
import { NewPasswordChange } from "../../utils/action";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
export default function SetNewPassword() {
  const navigate =  useNavigate()
  const[data,setData] =  useState({
    email:"",
    new_password:"",
    confirmPassword:""
  })
  const[error,setError] =  useState({
    email:"",
    new_password:"",
    confirmPassword:""
  })
  const handleSubmit=async()=>{
    if(data.new_password ==="" ||data.confirmPassword ==="" ||data.new_password!== data.confirmPassword){
      setError({
        ...error,
        new_password:data.new_password ===""?"Please Enter Password":error.new_password,
        confirmPassword:data.confirmPassword === ""?"Please Enter ConfirmPassword":  data.new_password !== data.confirmPassword ?"Password doesn't match":error.confirmPassword
      })
      return false;
    }
    let payload ={
      email: localStorage.getItem('email'),
      new_password:data.new_password
    }
    let response = await NewPasswordChange({payload:payload})
    console.log(response,"===response===")
    if(response.success === false){
      toast.error(response.message||"Something went wrong")
    }
    if(response.success===true){
      toast.success(response.message||"Password Change Successfully")
      navigate("/")
    }
  }
  useEffect(()=>{
    setData({
      email: localStorage.getItem('email'),
      new_password:data.new_password,
      confirmPassword:data.confirmPassword
    })
  }, [])
  
  console.log(data,"==dat===")
  return (
    <>
      <div className="bg-main h-screen">
        <div className="flex h-full justify-content-center align-items-center">
          <div
            className="card bg-white p-3 w-4
          border-round-2xl "
          >
            <div className="grid ">
              <div className="md:col-12 text-center">
                <img src={Logo} width="117px" height="92px" alt="ResMedX" />

                <div className="mb-1 Karla ">
                  <h1 className="font-bold Karla" style={{ color: " #525252" }}>
                    Reset password
                  </h1>
                </div>
              </div>
            </div>

            <div className="grid flex justify-content-center mb-3">
              <div className="md:col-10">
                <label className="mb-2 font-bold text-xs">New Password</label>
                <Password
                  className="w-full mt-1"
                  inputClassName="w-full border-round"
                  toggleMask
                  name = "Password"
                  onChange={(e) => {
                    setError({
                      ...error,
                      new_password: null,
                    });
                    setData({ ...data,new_password: e.target.value });
                  }}
                />
                {error.new_password && <span className="error">{error.new_password}</span>}
              </div>
            </div>

            <div className="grid flex justify-content-center mb-3">
              <div className="md:col-10">
                <label className="mb-2 font-bold text-xs">
                  Confirm Password
                </label>
                <Password
                  className="w-full mt-1"
                  inputClassName="w-full border-round"
                  toggleMask
                  name = "confirmPassword"
                  onChange={(e) => {
                    setError({
                      ...error,
                      confirmPassword: null,
                    });
                    setData({ ...data, confirmPassword: e.target.value });
                  }}
                />
                {error.confirmPassword&& <span className="error">{error.confirmPassword}</span>}
              </div>
            </div>

            <div className="grid flex justify-content-center">
              <div className="md:col-10 mb-5">
                <Link to="" className="link no-underline">
                  <Button
                  onClick={handleSubmit}
                    label="Reset Password"
                    className="p p-3 w-full bg-main border-round"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
