import { React, useEffect, useState } from "react";
import Table from "../../../components/TableList/Table";
import ContentArea from "../../../shared/ContentArea";
import SearchIcon from "../../../assets/icons/search.png";
import EyeIcon from "../../../assets/icons/eyeicon.png";
import { Button } from "primereact/button";
import FilterIcon from "../../../assets/icons/filter.png";
import Index from "../Index";
import ViewReports from "./ViewReports";
import { Calendar } from "primereact/calendar";
import { getCommisionReport } from "../../../utils/action";
import { Loading } from "../../../components/Loader/Loading";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
export default function SubscriptionReport() {
  const [isShowFilter, setIsShowFilter] = useState(false);

  const { isNextPage, setIsNextPage } = Index();
  const [viewButton, setViewButton] = useState(false);
  const [editButton, setEditButton] = useState(false);
  const [tableData, setTableData] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState(null);
  const[totalClient,setTotalClient]= useState(0)
  const[totalRevenue,setTotalRevenue] =  useState(0)
  const [isFiltered, setIsFiltered] = useState(false);
  const[showGrandTotal,setShowGrandTotal] = useState(false)
  const[totalCommission,setTotalCommission] = useState(0)
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const[data,setData] = useState("")
  const [filterData, setFilterData] = useState({
    start_date: "",
    end_date: ""
  })
  const [products, setProducts] = useState([]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    is_active: { value: null, matchMode: FilterMatchMode.EQUALS },
    dob: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
  });
  const tableHeading = [
    { selectionMode: "multiple" },
    { field: "srno", header: "Sr. No.",body: "srnoTemplates" },
    { field: "name", header: "Sales Agent Name"},
    { field: "number_of_client", header: "No. of Clients", body:"reportsSalesTemplate" },
    { field: "TotalRevenue", header: "Total Revenue",body:"reportsRevenueTemplate" },
    { field: "CommissionPercentage", header: "Commission Percentage" },
    { field: "commission", header: "Total Commission" },
    { field: "icons", header: "Action", body: "eyeClientBodyTemplate" },
  ];

  const showCommisionReport = async () => {
    setIsLoading(true)
    let response = await getCommisionReport({payload:data})
    setTableData(response.data.reverse())
    setIsLoading(false)
    setShowGrandTotal(true)
  }
  useEffect(() => {
    showCommisionReport()
  }, [])
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
    setShowGrandTotal(false)
  };
  useEffect(()=>{
    if(globalFilterValue === ''){
      setShowGrandTotal(true)
    }
  },[globalFilterValue])
  useEffect(()=>{
    let clientData = 0;
    let revenueData = 0;
    let commissionData= 0;
    tableData?.length > 0 && tableData?.map((item)=>{
      item.commission_details?.length>0 && item.commission_details?.map((comm)=>{
       clientData +=comm.number_of_client
       revenueData +=comm.revenue
      })
      commissionData +=item.commission
    })
    setTotalClient(clientData)
    setTotalRevenue(revenueData)
    setTotalCommission(commissionData)
  },[tableData])
  const handleChange = (e) => {
    let date = new Date(e.target.value)
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();
    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }
    let dob = year + '-' + month + '-' + dt;
    setFilterData({
      ...filterData,
      [e?.target?.name]: dob,
    });
  };
  const applyfilter = async () => {
    setIsLoading(true)
    setIsShowFilter(!isShowFilter)
    let response = await getCommisionReport({ payload: filterData })
    setIsFiltered(true)
    setIsLoading(false)
    setTableData(response.data.reverse())
  }
  const Clearfilter = async () => {
    setIsLoading(true)
    setIsFiltered(false)
    setIsShowFilter(false)
    let res = await getCommisionReport({ payload: data })
    setIsLoading(false)
    setTableData(res.data.reverse())
  }
  useEffect(() => {
   
    let object 
    let array = []
    tableData?.length>0&& tableData.map((item,index)=>{
      object ={
        SrNo:index+1,
        SalesAgentName:item.name,
        NoofClients :item?.commission_details[0]?.number_of_client,
        TotalRevenue:item?.commission_details[0]?.revenue,
        CommissionPercentage:"",
        TotalCommission:item.commission
      }
      array.push(object)
    })
    setProducts(array)
}, [tableData]); 

const exportExcel = () => {
  import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(products);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
          bookType: 'xlsx',
          type: 'array'
      });

      saveAsExcelFile(excelBuffer, 'products');
  });
};
const saveAsExcelFile = (buffer, fileName) => {
  import('file-saver').then((module) => {
      if (module && module.default) {
          let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          let EXCEL_EXTENSION = '.xlsx';
          const data = new Blob([buffer], {
              type: EXCEL_TYPE
          });

          module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      }
  });
};
function handleGrandTotal(){
  return(
    <div className="grid p-0 flex align-items-center flex ">
    <div className=" md:col-3">
      <p>Grand Total</p>
    </div>
    <div className=" md:col-2">
      <p className="ml-6">${totalClient}</p>
    </div>
    <div className=" md:col-4">
      <p className="ml-2">${totalRevenue}</p>

    </div>
    <div className=" md:col-3">
      <p className="">${totalCommission}</p>

    </div>

  </div>
  )
}
  return (
    <>
      {isLoading === true ? <Loading /> :
        <>
          {!isNextPage & !editButton & !viewButton ? (
            <ContentArea>
              <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-evenly ">
                <div className="md:col-5 ">
                  <h1 className="text-main   Karla" style={{ fontSize: "24px" }}>
                    Commissions Report
                  </h1>
                </div>
                <div className="md:col-4">
                  <div className="search-box w-full ">
                    <img
                      src={SearchIcon}
                      alt="Search Icon"
                      width="15px"
                      height="15px"
                    />
                    <input type="search" placeholder="Search" onChange={onGlobalFilterChange}onClick={()=>setIsShowFilter(false)} />
                  </div>
                </div>
                <div className="md:col-1 relative bg-main px-3 border-round-md w-max ">
                  {
                    isFiltered ?
                      <div onClick={() => Clearfilter()} className="bg-main border-round-md text-white text-center text-base relative karla">
                        Clear &nbsp;
                        <i className="pi pi-filter-slash"></i></div>
                      :
                      <div onClick={() => setIsShowFilter(prev => !prev)} className="border-round-md text-white text-center text-base relative karla">
                        Filter &nbsp;
                        <img src={FilterIcon} alt="" width={15} /></div>
                  }
                  {
                    isShowFilter ?
                      <div className="filter-popup">
                        <label className="font block mb-2">
                          Start Date<code className="text-red"></code>
                        </label>
                        <Calendar
                          className="border-round-md calender_picker "
                          inputClassName="calendar p-2 border-round-md "
                          placeholder="Select Date"
                          name="start_date"
                          onChange={(e) => handleChange(e)}
                        />
                        <label className="font block mb-2 mt-2">
                          End Date<code className="text-red"></code>
                        </label>
                        <Calendar
                          className="border-round-md calender_picker "
                          inputClassName="calendar p-2 border-round-md "
                          placeholder="Select Date"
                          name="end_date"
                          onChange={(e) => handleChange(e)}
                        />
                        <div className="mt-3 flex justify-content-between">
                        <Button onClick={(e) => applyfilter(e)} label="Apply" className="bg-main w-full" />
                      </div>
                      </div>
                      : null
                  }
                </div>
                <div className="md:col-2 w-max">
                <Button type="button" label="Export" className="bg-main karla px-4 border-round-md" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
                </div>
              </div>
              <div className="grid mt-2">
                <div className="md:col-12">
                  <Table
                    tableHeading={tableHeading}
                    tableData={tableData}
                    setViewButton={setViewButton}
                    title="commisionReport"
                    filters={filters}
                    setFilters={setFilters}
                    setSelectedProducts={setSelectedProducts}
                    selectedProducts={selectedProducts}
                    setData={setData}
                    handleGrandTotal ={handleGrandTotal()}
                  />
                </div>
              </div>
             
             
            </ContentArea>
          ) : null}
          {viewButton ? (
            <ViewReports title="Commissions Details" header="Commissions Report" />
          ) : null}
        </>
      }

    </>
  );
}
