import React, { useState } from "react";
import "../assets/sass/components/accordion.scss";
import ArrowDownWhiteIcon from "../assets/icons/arrow-down-white.png";
import ArrowUpWhiteIcon from "../assets/icons/arrow-up-white.png";
import { Link, useLocation } from "react-router-dom";
import Index from "../shared";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
const Accordion = ({
  heading,
  link,
  icon,
  iconWidth,
  iconHeight,
  content,
  changeComponent,
  showNextPage,
  contentData,
  showFullSideBar
}) => {
  const [isActive, setIsActive] = useState(false);
  // const navigate =  useNavigate()
  const location = useLocation();
  const activeMenu = (link) => {
    setIsActive((previous) => !previous);
    changeComponent(link);
    console.log(showNextPage(false));
  };
  const navigate = useNavigate();
  return (
    <>
      <li
        className={link === location.pathname && !content && !contentData ? "active-link accordion-item" :  "accordion-item"}
      >
        <div className="accordion-toggle " onClick={() => activeMenu(link)}>
          <p onClick={() => navigate(link)}>
            <span>
              <img
                src={icon}
                width={iconWidth}
                height={iconHeight}
                style={{ marginRight: "0.8rem" }}
                alt="Menu Icon"
              />
            </span>
            <Link to={link}>{heading}</Link>
          </p>

          {!content ? null : (
            <img
              src={isActive ? ArrowUpWhiteIcon : ArrowDownWhiteIcon}
              width="8px"
              height="4px"
              alt="Down Arrow"
            />
          )}
        </div>
        {!content
          ? null
          : isActive && (
            <div className="accordion-content">
              <ul>
                {content.map((subMenu, key) => {
                  return (
                    <li
                      key={key}
                      onClick={() => {
                        changeComponent(subMenu.link);
                        showNextPage(false);
                      }}
                    >
                      <p>
                        <NavLink to={subMenu.link} >{subMenu.name}</NavLink>
                      </p>
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
        {!contentData ? null : (
          <div className="box-sidebar">
            {contentData.map((subMenu, key) => {
              return (
                <li
                  key={key}
                  onClick={() => {
                    changeComponent(subMenu.link);
                    showNextPage(false);
                  }}
                >
                  <p>
                    <NavLink to={subMenu.link} >{subMenu.name}</NavLink>
                  </p>
                </li>
              )
            })}
          </div>
        )}

      </li>
      {/* {!contentData
          ? null
          : !isActive && (
            <div className="accordion-content z-1">

              {contentData.map((subMenu, key) => {
                return (
                  <div className="show_side_bar">
                    <p>
                      <NavLink to={subMenu.link} >{subMenu.name}</NavLink>
                    </p>
                  </div>
                )
              })}
            </div>
          )} */}
    </>

  );
};

export default Accordion;
