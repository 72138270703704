import { React, useState } from "react";
import Table from "../../../components/TableList/Table";
import ContentArea from "../../../shared/ContentArea";
import SearchIcon from "../../../assets/icons/search.png";
import EyeIcon from "../../../assets/icons/eyeicon.png";
import { Button } from "primereact/button";
import FilterIcon from "../../../assets/icons/filter.png";
import { getSalesReportById } from "../../../utils/action";
import Index from "../Index";
import ViewReports from "./ViewReports";
import { Calendar } from "primereact/calendar";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "../../../components/Loader/Loading";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
export default function ViewSalesReport() {
  const [isShowFilter, setIsShowFilter] = useState(false);
  const { isNextPage, setIsNextPage } = Index();
  const [viewButton, setViewButton] = useState(false);
  const [editButton, setEditButton] = useState(false);
  const [tableData, setTableData] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [isFiltered, setIsFiltered] = useState(false);
  const [filterData, setFilterData] = useState({
    start_date: "",
    end_date: ""
  })
  const[data,setData] = useState("")
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    is_active: { value: null, matchMode: FilterMatchMode.EQUALS },
    dob: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
  });
  const { id } = useParams()
  const getSalesData = async () => {
    setIsLoading(true)
    let array = []
    let response = await getSalesReportById({payload:data,id:id})
    response.data?.length > 0 && response?.data?.map((item) => {
      let date = new Date(item.dob)
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();
      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
      item.dob = year + '-' + month + '-' + dt;
      array.push(item)
    })
    setTableData(array)
    setIsLoading(false)
  }
  useEffect(() => {
    getSalesData()
  }, [])
  const applyfilter = async () => {
    setIsLoading(true)
    setIsShowFilter(!isShowFilter)
    setIsFiltered(true)
    let array = []
    let response = await getSalesReportById({payload:filterData,id:id})
    response.data?.length > 0 && response?.data?.map((item) => {
      let date = new Date(item.dob)
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();
      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
      item.dob = year + '-' + month + '-' + dt;
      array.push(item)
    })
    setTableData(array)
    setIsLoading(false)
  }
  const Clearfilter = async () => {
    setIsFiltered(false)
    setIsShowFilter(false)
    
    let array = []
    let response = await getSalesReportById({payload:data,id:id})
    response.data?.length > 0 && response?.data?.map((item) => {
      let date = new Date(item.dob)
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();
      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
      item.dob = year + '-' + month + '-' + dt;
      array.push(item)
    })
    setTableData(array)
    setIsLoading(false)
  }
  const handleChange = (e) => {
    let date = new Date(e.target.value)
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();
    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }
    let dob = year + '-' + month + '-' + dt;
    setFilterData({
      ...filterData,
      [e?.target?.name]: dob,
    });
  };
  const tableHeading = [
    { field: "srno", header: "Sr. No.", body: "srnoTemplates" },
    { field: "id", header: "Id" },
    { field: "name", header: "Name" },
    {field:"tier",header:"Tier"},
    { field: "classification", header: "Classification" },
    { field: "email", header: "Email" },
    { field: "contact_no", header: "Contact no." },
    { field: "mhn_no", header: "MHN no." },
    { field: "dob", header: "DOB" },
    { field: "status", header: "status" },
    // { field: "", header: "sort by" ,body:"subscriptionBodyTemp"},
  ];
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  return (
    <>
      {isLoading === true ? <Loading /> :
        <>
          {!isNextPage & !editButton & !viewButton ? (
            <ContentArea>
              <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-evenly ">
                <div className="md:col-5 ">
                  <h1 className="text-main   Karla" style={{ fontSize: "24px" }}>
                    View Client Patients
                  </h1>
                </div>
                <div className="md:col-4">
                  <div className="search-box w-full ">
                    <img
                      src={SearchIcon}
                      alt="Search Icon"
                      width="15px"
                      height="15px"
                    />
                    <input type="search" placeholder="Search" onChange={onGlobalFilterChange} onClick={()=>setIsShowFilter(false)}/>
                  </div>
                </div>
                <div className="md:col-1 relative bg-main px-3 border-round-md w-max ">
                  {
                    isFiltered ?
                      <div onClick={() => Clearfilter()} className="bg-main border-round-md text-white text-center text-base relative karla">
                        Clear &nbsp;
                        <i className="pi pi-filter-slash"></i></div>
                      :
                      <div onClick={() => setIsShowFilter(prev => !prev)} className="border-round-md text-white text-center text-base relative karla">
                        Filter &nbsp;
                        <img src={FilterIcon} alt="" width={15} /></div>
                  }
                  {
                    isShowFilter ?
                      <div className="filter-popup">
                        <label className="font block mb-2">
                          Start Date<code className="text-red"></code>
                        </label>
                        <Calendar
                          className="border-round-md calender_picker "
                          inputClassName="calendar p-2 border-round-md "
                          placeholder="Select Date"
                          name="start_date"
                          onChange={(e) => handleChange(e)}
                        />
                        <label className="font block mb-2 mt-2">
                          End Date<code className="text-red"></code>
                        </label>
                        <Calendar
                          className="border-round-md calender_picker "
                          inputClassName="calendar p-2 border-round-md "
                          placeholder="Select Date"
                          name="end_date"
                          onChange={(e) => handleChange(e)}
                        />
                        <div className="mt-3 flex justify-content-between">
                        <Button  onClick={(e) => applyfilter(e)} label="Apply" className="bg-main w-full" />
                      </div>
                      </div>
                      : null
                  }
                </div>
              </div>
              <div className="grid mt-2">
                <div className="md:col-12">
                  <Table
                    tableHeading={tableHeading}
                    tableData={tableData}
                    setViewButton={setViewButton}
                    title="salesReport"
                    filters={filters}
                    setFilters={setFilters}
                    setData={setData}
                  />
                </div>
              </div>
            </ContentArea>
          ) : null}
          {viewButton ? (
            <ViewReports title="Sales Details" header="Sales Report" />
          ) : null}
        </>
      }

    </>
  );
}
