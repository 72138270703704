import React, { useEffect } from "react";
import ContentArea from "../../shared/ContentArea";
import Totalclient from "../../assets/images/totalclient.png";
import Totalrevenge from "../../assets/images/totalrevenge.png";
import LineChart from "../../components/Charts/LineChart/LineChart.jsx";
import RevenueStatus from "../../components/Charts/RevenueStatus/RevenueStatus.jsx";
import BarChart from "../../components/Charts/Charts/BarChart";
import SalesPerson from "../../assets/images/salesperson.png";
import MonthRevenue from "../../assets/images/revenumonth.png";
import { useNavigate } from "react-router-dom";

export default function AdminDashboard() {
  const navigate = useNavigate();

  //   useEffect(()=>{
  //     console.log(localStorage.getItem('token'))
  //     if(localStorage.getItem('token')) navigate('/')
  //  },[])
  return (
    <>
      <ContentArea>
        <div className="grid mb-3 ">
          <div className="col-3">
            <div className="border-round-2xl border-none shadow-none bg-seagreen p-3 h-full ">
              <div className="relative">
                <h3 className="font-normal mb-0 text-heading karla">
                  Total Clients
                </h3>
                <h1 className="text-4xl font-bold mb-0">75</h1>
                <img
                  src={Totalclient}
                  className="absolute right-0 bottom-0"
                  alt=""
                  width={60}
                  height={60}
                />
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="border-round-2xl border-none shadow-none p-3 bg-lightred h-full">
              <div className="relative">
                <h3 className="font-normal mb-0 text-heading karla">
                  Total Sales Person
                </h3>
                <h1 className="text-4xl font-bold mb-0">25</h1>
                <img
                  alt=""
                  src={SalesPerson}
                  className="absolute right-0 bottom-0"
                  width={62.94}
                  height={55}
                />
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="border-round-2xl border-none shadow-none p-3 bg-lightpirple h-full">
              <div className="relative">
                <h3 className="font-normal mb-0 text-heading karla">
                  Current Month Revenue
                </h3>
                <h1 className="text-4xl font-bold mb-0"> $250 </h1>
                <img
                  alt=""
                  src={MonthRevenue}
                  className="absolute right-0 bottom-0"
                  width={45}
                  height={65}
                />
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="border-round-2xl border-none shadow-none p-3 bg-lightorange h-full">
              <div className="relative">
                <h3 className="font-normal mb-0 text-heading karla">
                  Total Revenue
                </h3>
                <h1 className="text-4xl font-bold mb-0">$1,250</h1>
                <img
                  alt=""
                  src={Totalrevenge}
                  className="absolute right-0 bottom-0"
                  width={63.29}
                  height={60}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col-6 mb-3">
            <BarChart title="Client Registration Stats" this="Month" />
          </div>

          <div className="col-6 mb-3">
            <RevenueStatus />
          </div>

          <div className="col-6">
            <BarChart title="Sales Person Registration" this="Tom Hanks" />
          </div>

          <div className="col-6 mb-3">
            <LineChart />
          </div>
        </div>
      </ContentArea>
    </>
  );
}
